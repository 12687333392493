import styled from "styled-components";
import { Dark, Extra, Grey } from "../../../atoms/Colors";

export const SubText = styled.div`
  width: 500px;
  @media (max-width: 1027px) {
    width: 100%;
  }
`;

export const InstructorGrid = styled.div`
  display: flex;
  gap: 40px;
  /* flex-wrap: wrap; */
  margin-top: 30px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  > div {
    flex: 1;
    min-width: 25%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    gap: 20px;
    > div {
      min-width: 82%;
    }
  }
`;

export const SingleInstructor = styled.div`
  width: 100%;
  max-width: 768px;
  margin: auto;
  .row {
    display: flex;
    gap: 24px;
  }
  .details {
    width: 100%;
    padding: 32px 0px;
  }
  .bio {
    max-height: 200px;
    height: auto;
    overflow: hidden;
    position: relative;
  }
  .show {
    max-height: none;
    padding-bottom: 20px;
  }
  .fade {
    position: absolute;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      ${(props) => (props.mode === "light" ? Grey[50] : Dark.bgCard)} 80%
    );
    bottom: 0px;
    color: ${(props) =>
      props.mode === "light" ? Extra["purple"] : Extra["yellow"]};
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    /* justify-content: center; */
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
    .details {
      padding: 0px;
    }
  }
`;
