import styled from "styled-components";
import { Grey, Dark, Success } from "../../../atoms/Colors";

export const TFWrapper = styled.section`
  padding: 0px 0 50px 0;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;
export const TalentCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
  > div {
    width: 25%;
    padding: 20px;
    box-sizing: border-box;
  }
  @media (max-width: 1200px) {
    > div {
      width: 50%;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;
