import React from "react";
import { useState } from "react";
import Typography from "../../../atoms/Typography/Typography";
import Modal from "../../../molecules/modal/Modal";
import { Row } from "../../../../helpers/GlobalStyle";
import img from "../../../../assets/images/sad.gif";
import Button from "../../../atoms/Button/Button";

const Content = () => {
  return (
    <>
      <Row style={{ gap: 40, alignItems: "center" }}>
        <div>
          <img src={img} width="100%" style={{ borderRadius: 20 }} />
        </div>
        <div style={{ flex: 2 }}>
          <Typography variant="h4" weight="bold">
            It will hurt us to see you go
          </Typography>
          <Typography variant="p2">
            Feel free to chat with us before you cancel your subscription so we
            can see how we can be of help.
          </Typography>
          <a
            href="https://api.whatsapp.com/send/?phone=2348127244042&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <Button label="Chat Us On WhatsApp" color="success" block={true} />
          </a>

          <Button
            label="Cancel Anyway"
            color="grey"
            variant="text"
            block={true}
          />
        </div>
      </Row>
    </>
  );
};

const CancelSub = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
        <Typography variant="p3">Request to cancel subscription</Typography>
      </div>

      <Modal
        isOpen={open}
        content={<Content />}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default CancelSub;
