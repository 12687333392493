import ErrorPage from "./../ErrorPage";
import Main from "../components/pages/Main";
import Auth from "../components/pages/Auth";
import ProtectedRoute from "../config/ProtectedRoute";
import Home from "../components/templates/home/Home";
// auth
import Login from "../components/templates/auth/login/Login";
import SignUp from "../components/templates/auth/signup/SignUp";
import ForgotPwd from "../components/templates/auth/forgetpassword/ForgotPwd";
import VerifyEmail from "../components/templates/auth/verify-email/VerifyEmail";
import Onboarding from "../components/templates/auth/onboarding/Onboarding";

import Courses from "../components/templates/courses/Courses";

// User Pages
import Profile from "../components/templates/users/profile/Profile";
import Subscription from "../components/templates/auth/sub/Subscription";
import NewSubscription from "../components/templates/auth/sub/NewSubscription";
import Communities from "../components/templates/communities/Communities";
import Workforce from "../components/templates/workforce/Workforce";
import Search from "../components/templates/courses/search/Search";
import Course from "../components/templates/courses/course/Course";
import CareerPaths from "../components/templates/courses/career-paths/CareerPaths";
import BootcampPage from "../components/templates/courses/bootcamp-page/BootcampPage";

import UserJobs from "../components/templates/users/jobs/UserJobs";
import Talent from "../components/templates/talent/Talent";
import Jobs from "../components/templates/jobs/Jobs";
import SingleJob from "../components/templates/jobs/single-job/SingleJob";
import NotFound from "../components/pages/error/NotFound";
import UserCourses from "../components/templates/users/courses/UserCourses";
import UserCourse from "../components/templates/users/courses/course/UserCourse";
import CourseCertificates from "../components/templates/users/courses/course/CourseCertificates";
import UserPath from "../components/templates/users/courses/course/UserPath";
import PathCertificates from "../components/templates/users/courses/course/PathCertificates";
import UserBootcamp from "../components/templates/users/courses/course/UserBootcamp";
import BootcampCertificates from "../components/templates/users/courses/course/BootcampCertificates";
import Billing from "../components/templates/users/billing/Billing";
import BootcampSubscription from "../components/templates/auth/sub/BootcampSubscription";
import UserCourseMain from "../components/templates/users/courses/course/UserCourseMain";
import SalesPage from "../components/templates/auth/onboarding/SalesPage";

const Index = [
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        element: <Courses />,
        path: "courses",
      },
      {
        element: <Search />,
        path: "courses/search",
      },
      {
        element: <CareerPaths />,
        path: "courses/career-paths/:url",
      },
      {
        element: <BootcampPage />,
        path: "courses/bootcamps/:url",
      },
      {
        path: "courses/:url",
        element: <Course />,
      },
      {
        path: "communities",
        element: <Communities />,
      },
      {
        path: "workforce/employers",
        element: <Workforce />,
      },
      {
        path: "workforce/talents",
        element: <Talent />,
      },
      {
        path: "/jobs",
        element: <Jobs />,
      },
      {
        path: "/jobs/:url",
        element: <SingleJob />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/profile/courses",
            element: <UserCourses />,
          },
          {
            path: "/profile/billing",
            element: <Billing />,
          },
          {
            path: "/profile/courses/:url",
            element: (
              <UserCourseMain>
                <UserCourse />
              </UserCourseMain>
            ),
          },
          {
            path: "/profile/career-paths/:url",
            element: (
              <UserCourseMain>
                <UserPath />
              </UserCourseMain>
            ),
          },
          {
            path: "/profile/bootcamps/:url",
            element: (
              <UserCourseMain>
                <UserBootcamp />
              </UserCourseMain>
            ),
          },
          {
            path: "/profile/courses/:id/certificate",
            element: <CourseCertificates />,
          },
          {
            path: "/profile/career-paths/:id/certificate",
            element: <PathCertificates />,
          },
          {
            path: "/profile/bootcamps/:id/certificate",
            element: <BootcampCertificates />,
          },
          {
            path: "/profile/jobs",
            element: <UserJobs />,
          },
        ],
      },
    ],
  },
  {
    element: <Auth />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "create-account",
        element: <SignUp />,
      },
      {
        path: "forgot-password",
        element: <ForgotPwd />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "verify-email",
            element: <VerifyEmail />,
          },
          {
            path: "onboarding",
            element: <Onboarding />,
          },
          {
            path: "sales",
            element: <SalesPage />,
          },
          {
            path: "subscription",
            element: <NewSubscription />,
            // element: <Subscription />,
          },
          {
            path: "subscription-bootcamp/:url",
            element: <NewSubscription />,
            // element: <BootcampSubscription />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default Index;
