import Typography from "../../atoms/Typography/Typography";
import { Wrapper, Desc, Avatar } from "./ComingSoonStyle";
import Button from "../../atoms/Button/Button";
import { Container } from "../../../helpers/GlobalStyle";

import avatar1 from "./../../../assets/images/comming-soon/avatar1.jpg";
import avatar2 from "./../../../assets/images/comming-soon/avatar2.jpg";
import avatar3 from "./../../../assets/images/comming-soon/avatar3.jpg";
import avatar4 from "./../../../assets/images/comming-soon/avatar4.jpg";
import avatar5 from "./../../../assets/images/comming-soon/avatar5.jpg";
import avatar6 from "./../../../assets/images/comming-soon/avatar6.jpg";

const CommingSoon = ({ page, desc }) => {
  return (
    <Wrapper>
      <Container>
        <Avatar bg={avatar1} className="first" />
        <Avatar bg={avatar2} className="second" />
        <Avatar bg={avatar3} className="third" />
        <Avatar bg={avatar4} className="four" />
        <Avatar bg={avatar5} className="five" />
        <Avatar bg={avatar6} className="six" />
        <Typography variant="p1" weight="semi-bold" align="center">
          {page}
        </Typography>
        <Typography variant="h1" align="center">
          coming soon
        </Typography>
        <Desc>
          <Typography variant="p3" align="center">
            {desc}
          </Typography>
        </Desc>

        <center>
          <a href="https://bit.ly/3oTepDH" target="_blank">
            <Button label="Join Our Community WhatsApp" />
          </a>
        </center>
      </Container>
    </Wrapper>
  );
};

export default CommingSoon;
