import styled from "styled-components";
import { Dark, Extra, Grey } from "../../atoms/Colors";

export const TestCardWrapper = styled.div`
  margin-top: 50px;
  .owl-item {
    opacity: 0.3;
  }
  .owl-stage > .center {
    opacity: 1;
  }
`;

export const TestCard = styled.div`
  width: 100%;
  height: auto;
  padding: 50px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  background: ${Extra.purple};
  box-sizing: border-box;
  gap: 32px;
  p {
    color: #fff !important;
  }
  .profile {
    min-width: 200px;
    height: 200px;
    background: #ccc;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
  }

  .main {
    width: 100%;
  }
  h6 {
    margin: 0px;
    margin-top: 50px;
    color: #fff;
  }
  @media (max-width: 920px) {
    flex-direction: column;
    padding: 20px;
    gap: 0px;
    > div {
      width: 100%;
    }
    .profile {
      max-width: 70px;
      min-width: 70px;
      height: 70px;
    }
    h6 {
      margin-top: 28px;
    }
  }
`;

export const TestPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  /* margin: auto; */
  width: 85%;
  max-width: 450px;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bgCard)};
  padding: 16px;
  border-radius: 12px;
  color: ${Dark.text};
  display: ${(props) => (props.show ? "block" : "none")};
  box-sizing: border-box;
  z-index: 1000;
  .top {
    display: flex;
    gap: 8px;
  }
  .profile {
    width: 40px;
    height: 40px;
    background: #ccc;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
    display: none;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    font-size: 16px;
  }
  @media (max-width: 920px) {
    /* left: 0px; */
  }
`;

export const ReviewGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
  margin-top: 50px;

  @media (max-width: 768px) {
    grid-template-columns: auto auto;
  }
`;
