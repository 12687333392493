import styled from "styled-components";
import { Grey, Primary, Success } from "../../../atoms/Colors";

export const Wrapper = styled.div`
  padding: 100px 0px;
  h3 {
    max-width: 500px;
    margin: auto;
  }
`;

export const BillingTitle = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
  font-weight: 600;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[300])};
  > div:nth-child(1) {
    color: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  }
  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

export const BillingWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 32px;
  margin-top: 80px;

  div:nth-child(1) {
    flex: 2;
  }
  div:nth-child(2) {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BillingCard = styled.div`
  display: flex;
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.mode === "light" ? "#e5e7eb" : Grey[600])};
  color: ${Grey[900]};
  font-weight: 500;
  padding: 32px;

  h3 {
    margin: 16px 0px;
  }
  .duration {
    font-size: 14px;
    color: ${Grey[300]};
    font-weight: 500;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 20px;
    gap: 0px;
  }
`;

export const PaymentCard = styled.div`
  border-radius: 10px;
  border: 3px solid ${Primary[500]};
  padding: 32px;
`;

export const ItemList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.total {
    border-top: 1px solid ${Grey[100]};
  }
`;

export const BillingCardHeading = styled.div`
  padding-bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid
    ${(props) => (props.mode === "light" ? "#e5e7eb" : Grey[600])};
  p {
    color: ${(props) => (props.mode === "light" ? Grey[600] : Grey[50])};
    margin: 0px;
  }
  h3 {
    margin: 16px 0px;
  }
  .duration {
    font-size: 14px;
    color: ${Grey[300]};
    font-weight: 600;
  }
`;

export const SubTitleHighlight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    color: ${(props) => (props.mode === "light" ? Grey[300] : Grey[50])};
    font-size: 16px;
    &.check {
      color: #22c55e;
    }
  }
`;

export const BillingCardBody = styled.div`
  padding: 20px;
  color: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  > ul {
    list-style: none;
    text-align: center;
    margin: 0px;
    padding: 0px;
    text-transform: capitalize;
  }

  > ul > li {
    padding: 10px 0px;

    &.cancel {
      text-decoration: line-through;
      color: ${Grey[300]};
    }
  }

  > h1 {
    font-size: 46px;
    margin-bottom: 0px;
  }
`;
