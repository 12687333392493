import styled from "styled-components";
import { Grey, Info, Error, Success } from "../../atoms/Colors";
import { useSelector } from "react-redux";

const FullProgress = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background: ${(props) => (props.mode === "light" ? Grey[100] : Grey[600])};
  .progress {
    position: absolute;
    width: ${(props) => props.progress};
    height: 100%;
    border-radius: 10px;
    background: ${(props) =>
      props.variant === "info"
        ? Info[500]
        : props.variant === "error"
        ? Error[500]
        : Success[500]};
  }
`;

const ProgressBar = ({ progress, variant }) => {
  const mode = useSelector((state) => state.mode);

  return (
    <>
      <FullProgress progress={progress} variant={variant} mode={mode}>
        <div className="progress"></div>
      </FullProgress>
    </>
  );
};

export default ProgressBar;
