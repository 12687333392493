import styled from "styled-components";
import { Grey } from "../../../atoms/Colors";

export const SingleJobWrapper = styled.div``;

export const SingleJobHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }

  .header2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    row-gap: 20px;
    @media (max-width: 768px) {
      row-gap: 7px;
      align-items: flex-start;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
`;

export const JobHeaderIcon = styled.span`
  background: ${Grey[50]};
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const JobLevel = styled.div`
  padding: 40px 0;
  display: flex;
  column-gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

export const SimilarJob = styled.section`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .similarjobs {
    display: flex;
    flex: 100%;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    @media (max-width: 480px) {
      flex: 100%;
    }
  }
`;
