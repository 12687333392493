import React from "react";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { Extra } from "../../../atoms/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ReviewGrid } from "../../../organisms/testimonials/TestimonialStyle";

const data = [
  {
    name: "paul felix",
    testimony:
      "Hi 👋 I saw what you guys did with the bootcamp thing, I love it. Thank you for having lazy people like us in mind. It's truly a life changer.",
    img: "",
  },
  {
    name: "Zainab Ali",
    testimony:
      "The lessons are very insightful and it shows Nerd Eye has prioritize helping newbies in tech.",
    img: "",
  },
  {
    name: "Olamide",
    testimony:
      "The videos in each session are super cool, easy to follow and super amazing. However, I had a little challenge with my internet connection but I'm definitely preparing for a good network provider.",
    img: "",
  },
  {
    name: "Adibari",
    testimony:
      "I'm lucky to have found out about Nerdy Eye, I have been learning on my own before but I wasn't understanding but since I joined the community, it has been easy for me .",
    img: "",
  },
  {
    name: "Bimpe",
    testimony:
      "I have seen other plaforms, they are static and unfriendly but Nerdy Eye, you guys blew my mind. I have already given up but you guys gave me hope.",
    img: "",
  },
  {
    name: "Anthony",
    testimony:
      "I've been struggling with the whole self paced thing. Got a course on Udemy on cloud computing but I lost interest in it. Ever since I started learning with the community here, it has been amazing as I no longer feel alone.",
    img: "",
  },
  {
    name: "Pizzy Dedon",
    testimony:
      "😁 Help me tell the Frontend facilitator that I'm enjoying her lessons especially that small shakara she use to do at the end of her videos lol!",
    img: "",
  },
  {
    name: "Benny",
    testimony:
      "The classes have been on point, every topics and lessons stood out, I really appreciate Nerdy Eye, The experience has been awesome 🕺💃",
    img: "",
  },
];
const Review = () => {
  return (
    <div>
      <SectionWrapper>
        <Container>
          <Typography
            variant="p2"
            align="center"
            style={{ color: Extra.yellow }}
          >
            Reviews
          </Typography>
          <Typography variant="h3" align="center">
            What Coaches & Leaners are saying
          </Typography>

          <ReviewGrid>
            {data.map((val, key) => (
              <div>
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {val.name}
                </Typography>
                <div style={{ color: "gold" }}>
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>

                <Typography variant="p2">{val.testimony}</Typography>
              </div>
            ))}
          </ReviewGrid>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Review;
