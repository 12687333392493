import styled from "styled-components";
import { keyframes } from "styled-components";
import { Extra } from "../../../atoms/Colors";

const swap = keyframes`
    from{
        color: ${Extra.purple};
    }

    to{
        color: ${Extra.yellow} ;
    }
`;

export const StepsWrapper = styled.div`
  span {
    animation: ${swap} 3s linear infinite alternate;
  }
  .list-con {
    padding: 0px 100px;
    padding-top: 44px;
  }
  .list-two {
    display: flex;
    justify-content: flex-end;
  }
  .arrone,
  .arrtwo {
    margin: 30px 0px 30px 20%;
    width: 70%;
  }
  .arrtwo {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  @media (max-width: 768px) {
    .list-con {
      padding: 0px;
      padding-top: 24px;
    }
    .list-two {
      justify-content: flex-start;
      margin: 20px 0px;
    }
    .arrone,
    .arrtwo {
      display: none;
    }
  }
  @media (max-width: 380px) {
    .text {
      height: 75px;
      width: 68%;
      margin: auto;
    }
  }
`;

export const StepList = styled.div`
  width: 320px;
  display: flex;
  gap: 20px;
  .num {
    min-width: 40px;
    height: 40px;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--color-primary-color-primary-color-500, #d23431);
    box-shadow: 0px 10px 30px 0px rgba(210, 52, 50, 0.69);
  }
  .details {
  }
  &.two > .num {
    background: var(--color-extra-purple, #7b61ff);
    box-shadow: 0px 10px 30px 0px rgba(151, 71, 255, 0.69);
  }

  &.three > .num {
    background: var(--color-extra-yellow, #f8bf3e);
    box-shadow: 0px 10px 30px 0px rgba(248, 191, 62, 0.69);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
