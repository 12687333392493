import {
  Wrapper,
  Overlay,
  DetailsWrapper,
  BackgroundImg,
  DescWrapper,
  TopWrapper,
  Badge,
  PlayIcon,
} from "./CourseCardStyle";
import Typography from "./../../atoms/Typography/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const CourseCard = ({ bgImg, type, title, intructor, desc, masterClass }) => {
  return (
    <Wrapper>
      <TopWrapper>
        {/* <Badge className={`${masterClass && "active"}`}>{type}</Badge> */}
        {type && <Badge className={`${masterClass && "active"}`}>{type}</Badge>}

        <PlayIcon>
          <FontAwesomeIcon icon={faPlay} />
        </PlayIcon>
      </TopWrapper>
      <BackgroundImg bgImg={bgImg} className="bg" />
      <Overlay>
        <DetailsWrapper>
          <div>
            <div className="course-title">{title}</div>
            <Typography variant="small1">{intructor}</Typography>
          </div>
        </DetailsWrapper>
        <DescWrapper className="desc">{desc}</DescWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default CourseCard;
