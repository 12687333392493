const authReducer = (state = false, action) => {
  switch (action.type) {
    case "login":
      return {
        login: action.login,
        token: action.token,
        user: action.user,
      };
    case "logout":
      return false;
    default:
      return state;
  }
};

export default authReducer;
