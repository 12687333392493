import { Container } from "../../../../../helpers/GlobalStyle";
import {
  Wrapper,
  InfoList,
  InfoWrapper,
  CountDownWrapper,
} from "./CourseHeroStyle";
import Badge from "../../../../atoms/Badge/Badge";
import Typography from "../../../../atoms/Typography/Typography";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Button from "../../../../atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import Countdown from "react-countdown";
import { ButtonGroup } from "../../../../atoms/Button/ButtonStyle";
import ModalVideo from "react-modal-video";
import "../../../../../../node_modules/react-modal-video/css/modal-video.css";
import { useState } from "react";
import useFetch from "../../../../../api/useFetch";
import { useSelector, useDispatch } from "react-redux";
import CardSkeleton from "../../../../organisms/skeleton/CardSkeleton";
import { Link, useNavigate } from "react-router-dom";
import PageModal from "../../../../molecules/modal/Modal";
import { CircularProgress } from "@mui/material";
import useToast from "../../../../../customhooks/useToast";
import { create } from "../../../../../api/api";
import { courses, careerPath } from "../../../../../actions/dataPersist";
import ErrorApiPage from "../../../../pages/ErrorApiPage";
import moment from "moment/moment";
import { Extra } from "../../../../atoms/Colors";
import { useParams } from "react-router-dom";

const TrailerBtn = ({ trailer }) => {
  const [playMovie, setPlayMovie] = useState(false);

  //get youtube id from url
  var rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const youTubeID = trailer.match(rx);

  return (
    <>
      <Button
        label="Watch Trailer"
        variant="outline"
        className="cta"
        mode="dark"
        color="grey"
        icon={<FontAwesomeIcon icon={faPlayCircle} />}
        showIcon="right"
        onClick={() => setPlayMovie(true)}
      />

      {/* trailer modal */}
      <ModalVideo
        channel="youtube"
        autoplay={true}
        isOpen={playMovie}
        videoId={youTubeID && youTubeID[1]}
        onClose={() => setPlayMovie(false)}
      />
    </>
  );
};

const UserHeroBtn = ({ token, id, trailer, type, slug, batches }) => {
  let navigate = useNavigate();
  const { showToast } = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let { url } = useParams();

  const { data, loading, error } = useFetch(
    `/enrollment/check-enrollment?type=${type}&enrollment_id=${id}`,
    token
  );
  if (loading) {
    return (
      <div>
        <CardSkeleton style={{ width: "150px", height: "50px" }} />
      </div>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  const HandleEnrollment = async () => {
    let query;
    if (type === "bootcamps") {
      query = { enrollment_id: id, batch_id: batches._id };
    } else {
      query = { enrollment_id: id };
    }

    const res = await create(`/enrollment?type=${type}`, token, query);

    if (res.status === "success") {
      showToast("success", "You have successfully enrolled");
      //if type is bootcamp redirect to bootcamp subscription page
      type === "bootcamps"
        ? navigate(`/subscription?bid=${id}`)
        : navigate(`/profile/courses?type=${type}`);
    } else {
      // console.log(res.message);
      showToast("error", "please try again");
    }
  };

  return (
    <div>
      {data.enrollment_status === false ? (
        <ButtonGroup>
          <Button
            label="Start Now"
            className="cta"
            icon={<FeatherIcon icon="arrow-right" size={16} />}
            showIcon="right"
            onClick={HandleEnrollment}
            isLoading={isLoading}
            // onClick={() => setModalOpen(true)}
          />

          <TrailerBtn trailer={trailer} />
        </ButtonGroup>
      ) : (
        <Link to={`/profile/${type}/${slug}`}>
          <Button
            label="Continue"
            // label={
            //   type === "career-paths" ? `Go To Career Path` : "Go To Course"
            // }
            className="cta"
            icon={<FeatherIcon icon="arrow-right" size={16} />}
            showIcon="right"
          />
        </Link>
      )}

      <PageModal
        isOpen={modalOpen}
        // onClose={() => setModalOpen(false)}
        content={
          <div>
            <Typography variant="p1" align="center">
              <CircularProgress color="inherit" size={14} /> We're enrolling you
            </Typography>
          </div>
        }
        maxWidth="320px"
      />
    </div>
  );
};

const CourseBtn = ({ auth, trailer, id, type, slug }) => {
  const { showToast } = useToast();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleEnrollment = () => {
    dispatch(courses(id));
    showToast("info", "login or sign up to enroll");
    navigate(`/login`);
  };

  return (
    <>
      {auth ? (
        <UserHeroBtn
          token={auth.token}
          id={id}
          trailer={trailer}
          type={type}
          slug={slug}
        />
      ) : (
        <ButtonGroup>
          <Button
            label="Start Now"
            className="cta"
            icon={<FeatherIcon icon="arrow-right" size={16} />}
            showIcon="right"
            onClick={handleEnrollment}
          />

          <TrailerBtn trailer={trailer} />
        </ButtonGroup>
      )}
    </>
  );
};

const CareerPathBtn = ({ auth, trailer, id, type, slug }) => {
  const { showToast } = useToast();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleEnrollment = () => {
    dispatch(careerPath(id));
    showToast("info", "login or sign up to enroll");
    navigate("/login");
  };

  return (
    <>
      {auth ? (
        <UserHeroBtn
          token={auth.token}
          id={id}
          trailer={trailer}
          type={type}
          slug={slug}
        />
      ) : (
        // <>user btn</>
        <ButtonGroup>
          <Button
            label="Start Now"
            className="cta"
            icon={<FeatherIcon icon="arrow-right" size={16} />}
            showIcon="right"
            onClick={handleEnrollment}
          />

          <TrailerBtn trailer={trailer} />
        </ButtonGroup>
      )}
    </>
  );
};

const BootcampBtn = ({ auth, trailer, id, type, slug, batches }) => {
  const { showToast } = useToast();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleEnrollment = () => {
    dispatch(careerPath(id));
    showToast("info", "login or sign up to enroll");
    navigate(`/create-account?type=${type}&url=${slug}`);
  };

  return (
    <>
      {auth ? (
        <UserHeroBtn
          token={auth.token}
          id={id}
          trailer={trailer}
          type={type}
          slug={slug}
          batches={batches}
        />
      ) : (
        // <>user btn</>
        <ButtonGroup>
          <Button
            label="Start Now"
            className="cta"
            icon={<FeatherIcon icon="arrow-right" size={16} />}
            showIcon="right"
            onClick={handleEnrollment}
          />

          <TrailerBtn trailer={trailer} />
        </ButtonGroup>
      )}
    </>
  );
};

const CourseHero = ({
  type,
  title,
  content,
  badge,
  badgeText,
  time,
  certificate,
  bg,
  trailer,
  id,
  slug,
  batches,
}) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Typography variant="p2" weight="semi-bold" align="center">
          Enroll now to join the to ongoing bootcamp
        </Typography>
      );
    } else {
      // Render a countdown
      return (
        <div className="holder">
          <div className="count">
            <div className="num">{days}</div>
            <div className="title">Days</div>
          </div>
          :
          <div className="count">
            <div className="num">{hours}</div>
            <div className="title">Hrs</div>
          </div>
          :
          <div className="count">
            <div className="num">{minutes}</div>
            <div className="title">Min</div>
          </div>
          :
          <div className="count">
            <div className="num">{seconds}</div>
            <div className="title">Sec</div>
          </div>
        </div>
      );
    }
  };

  const auth = useSelector((state) => state.auth);

  return (
    <div>
      <Wrapper bg={bg}>
        <div className={`overlay ${type}`}>
          <Container>
            <Badge variant={badge} style={{ textTransform: "capitalize" }}>
              {badgeText}
            </Badge>
            <Typography variant="h2" weight="bold" className="title">
              {title}
            </Typography>
            <InfoWrapper>
              {/* <InfoList>
                <FeatherIcon icon="layers" size={20} />
                <Typography variant="p2">{content}</Typography>
              </InfoList> */}
              {/* <InfoList>
                <FeatherIcon icon="clock" size={20} />
                <Typography variant="p2">
                  {time !== 0 && time.replace(".", ":")} to complete
                </Typography>
              </InfoList> */}
            </InfoWrapper>
            <InfoWrapper>
              <InfoList>
                <FeatherIcon icon="rss" size={20} />
                <Typography variant="p2">
                  {type === "bootcamps"
                    ? "Be mentored & learn with a family"
                    : "Online at your own pace"}
                </Typography>
              </InfoList>
              {certificate && (
                <InfoList>
                  <FeatherIcon icon="award" size={20} />
                  <Typography variant="p2">
                    Certificate of completion
                  </Typography>
                </InfoList>
              )}
            </InfoWrapper>
            {/* hero btn */}
            {type === "courses" && (
              <CourseBtn
                auth={auth}
                trailer={trailer}
                id={id}
                type={type}
                slug={slug}
              />
            )}
            {type === "career-paths" && (
              <CareerPathBtn
                auth={auth}
                trailer={trailer}
                id={id}
                type={type}
                slug={slug}
              />
            )}
            {type === "bootcamps" && (
              <BootcampBtn
                auth={auth}
                trailer={trailer}
                id={id}
                type={type}
                slug={slug}
                batches={batches}
              />
            )}
          </Container>
        </div>
      </Wrapper>

      {type === "bootcamps" && batches && (
        <CountDownWrapper>
          <Container>
            <Typography variant="p2" weight="semi-bold" align="center">
              {batches.status === "not-started"
                ? "STARTS IN: "
                : batches.status === "in-progress"
                ? `Started: ${moment(batches.start_date).format(
                    "Do MMM, YYYY"
                  )}`
                : ""}
            </Typography>
            <Countdown
              date={moment(batches.start_date).format("YYYY-MM-DD")}
              renderer={renderer}
            />
          </Container>
        </CountDownWrapper>
      )}
    </div>
  );
};

export default CourseHero;
