import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCalCourseDuration,
  getCalProgressDuration,
} from "../../../../../helpers/getDuration";
import { allData } from "../../../../../api/api";

const useUserCourseData = (url) => {
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const progressPercentage = (courseLessons, progressLessons) => {
    const courseDuration = getCalCourseDuration(courseLessons);
    const progressDuration = getCalProgressDuration(progressLessons);
    let res = (progressDuration / courseDuration) * 100;
    if (res > 100) res = 100;

    return res.toFixed(0);
  };

  const getLessonStatus = (progressLessons, id) => {
    //1) check found
    let found = false;
    let index;
    for (let i = 0; i < progressLessons.length; i++) {
      if (progressLessons[i].lesson_id === id) {
        found = true;
        index = i;
        break;
      }
    }
    //2) return status
    if (found) {
      return progressLessons[index].status;
    } else {
      return "not started";
    }
  };

  const getLessonProgress = (progressLessons, id) => {
    //1) check found
    let found = false;
    let index;
    for (let i = 0; i < progressLessons.length; i++) {
      if (progressLessons[i].lesson_id === id) {
        found = true;
        index = i;
        break;
      }
    }
    //2) return status
    if (found) {
      return progressLessons[index].progress;
    } else {
      return 0;
    }
  };

  const getLastLesson = (lessons) => {
    let index = 0;
    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].status === "in progress") {
        index = i;
        break;
      }
    }
    return index;
  };

  const getDetails = async () => {
    //get courses details
    const res = await allData(auth.token, `enrollment/courses/${url}`); //course data

    if (res.status === "success") {
      const result = await allData(auth.token, `progress/${res.data._id}`); //lesson progress data

      if (result.status === "success") {
        //check progress data is not null
        if (result.data) {
          for (let i = 0; i < res.data.lessons.length; i++) {
            //get lesson watch status
            res.data.lessons[i].status = getLessonStatus(
              result.data.lessons,
              res.data.lessons[i]._id
            );
            //get lesson watch progress

            res.data.lessons[i].progress = getLessonProgress(
              result.data.lessons,
              res.data.lessons[i]._id
            );
          }
          // add lessons status
          res.data.lessons_status = result.data.status;
          //
          res.data.course_reviewed = result.data.reviewed;
          // add progress percent
          res.data.percent = progressPercentage(
            res.data.lessons,
            result.data.lessons
          );
        }
        //set last lesson seen
        res.data.lastSeenLessonIndex = getLastLesson(res.data.lessons);
        setData(res.data);
      } else {
        setError(true);
      }
    } else {
      setError(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDetails();
  }, [url]);

  return { data, isLoading, error };
};

export default useUserCourseData;
