import styled from "styled-components";
import { Dark, Grey, Success } from "../../../../atoms/Colors";

export const RoadmapWrapper = styled.div`
  margin-bottom: 20px;
  opacity: ${(props) => (props.locked ? 0.3 : 1)};
`;

export const RoadmapHead = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bgCard)};
  box-shadow: 0px 2px 36px
    ${(props) => (props.mode === "light" ? "#e8e6e6" : Dark.bg)};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const RoadmapTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const RoadmapTitle = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0px;
  }
`;

export const Step = styled.div`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${Grey[100]};
  color: ${Grey[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  &.active {
    background: ${Success[500]};
    color: #fff;
  }
`;

export const RoadmapBody = styled.div`
  padding: 30px;
  display: ${(props) => (props.expand ? "block" : "none")};
  box-sizing: border-box;
`;
