import styled from "styled-components";
import { Extra, Grey, Primary } from "../../atoms/Colors";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  border-radius: 30px;
  background-color: #ccc;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  .desc {
    height: 0px;
    transition: 0.5s;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  &:hover {
    .desc {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      transition: 0.5s;
      height: 50px;
      text-overflow: ellipsis;
    }
    .bg {
      transform: scale(1.5);
    }
  }
`;

export const BackgroundImg = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #222;
  background-image: ${(props) => `url(${props.bgImg})`};
  background-size: cover;
  transition: 0.5s;
  background-position: top center;
`;

export const Overlay = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 30px;
  box-sizing: border-box;
  p {
    color: #fff;
    text-transform: capitalize;
    margin: 0px;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  .course-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
    text-transform: capitalize;
  }
`;

export const DescWrapper = styled.div`
  color: #fff;
  width: 100%;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  padding: 30px;
  box-sizing: border-box;
`;

export const Badge = styled.div`
  background: ${Grey[50]};
  padding: 4px 8px;
  border-radius: 20px;
  height: 16px;
  font-size: 12px;
  color: ${Grey[900]};
  &.active {
    background: ${Primary[500]};
    color: #fff;
  }
`;

export const Badge1 = styled.div`
  background: ${Extra["yellow"]};
  width: 64px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 20px;
  height: 16px;
  font-size: 12px;
  color: ${Grey[900]};
  &.active {
    background: ${Extra["purple"]};
    color: #fff;
  }
`;

export const PlayIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${Grey[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Grey[900]};
`;
