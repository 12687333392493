import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import { Row } from "../../templates/users/courses/course/UserCourseStyle";
import CardSkeleton from "../../organisms/skeleton/CardSkeleton";

const UserCourseLoader = () => {
  return (
    <div>
      <Container>
        <Row>
          <div>
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 250 }}
            />
            <CardSkeleton height="400px" style={{ marginBottom: 20 }} />
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 50 }}
            />
            <CardSkeleton
              height="20px"
              style={{ marginBottom: 20, width: "80%" }}
            />
            <CardSkeleton height="8px" style={{ marginBottom: 10 }} />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "90%" }}
            />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "80%" }}
            />
          </div>
          <div>
            <CardSkeleton height="15px" style={{ marginBottom: 20 }} />

            <CardSkeleton
              height="5px"
              style={{ marginBottom: 5, width: "100px" }}
            />

            <CardSkeleton height="5px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default UserCourseLoader;
