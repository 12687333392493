import { useState } from "react";
import { FormWrapper, BtnWrapper } from "./ProfileFormStyle";
import { Password } from "./../../../atoms/inputs/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../atoms/Button/Button";
import axios from "./../../../../axios/axios";
import Toast from "../../../molecules/toast/Toast";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../../actions/auth";

const PwdForm = ({ user }) => {
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");
  const dispatch = useDispatch();

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await axios.patch("/auth/update-password", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      handleNotify("Success! Log in with new password", "success");
      dispatch(logout());
    } catch (error) {
      handleNotify(error.response.data.message, "error");
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().min(8).required("Required"),
      newPassword: Yup.string().min(8).required("Required"),
    }),
    onSubmit: (values) => {
      //form submittion
      handleSubmit(values);
    },
  });

  return (
    <>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <div>
            <Password
              label="Current Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              state={
                formik.touched.password && formik.errors.password && "error"
              }
              desc={
                formik.touched.password &&
                formik.errors.password &&
                formik.errors.password
              }
            />
          </div>
          <div>
            <Password
              label="New Password"
              name="newPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
              state={
                formik.touched.newPassword &&
                formik.errors.newPassword &&
                "error"
              }
              desc={
                formik.touched.newPassword &&
                formik.errors.newPassword &&
                formik.errors.newPassword
              }
            />
          </div>
        </FormWrapper>

        <BtnWrapper>
          <Button
            label="Save Changes"
            type="submit"
            isLoading={isLoading}
            style={{ margin: "20px" }}
          />
        </BtnWrapper>
      </form>
    </>
  );
};

export default PwdForm;
