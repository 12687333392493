import styled from "styled-components";
import { Grey, Info } from "../../../atoms/Colors";

export const OtpWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  input {
    width: 70px !important;
    height: 70px;
    margin: 20px;
    border-radius: 10px;
    border: 0px;
    font-size: 24px;
    background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    &:focus {
      outline: 1px solid ${Info[300]};
    }
  }
  @media (max-width: 768px) {
    input {
      width: 50px !important;
      height: 50px;
      margin: 10px;
    }
  }

  @media (max-width: 300px) {
    input {
      width: 42px !important;
      height: 42px;
      margin: 5px;
    }
  }
`;
