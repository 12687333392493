import { GlobalStyle } from "./helpers/GlobalStyle";
import AnimatedCursor from "react-animated-cursor";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import { useSelector } from "react-redux";
import Toast from "./components/molecules/toast/Toast";

const router = createBrowserRouter(routes);

const App = () => {
  const mode = useSelector((state) => state.mode);
  const toast = useSelector((state) => state.toast);

  return (
    <>
      <GlobalStyle mode={mode} />
      {/* <AnimatedCursor outerSize={30} trailingSpeed={12} outerScale="2" /> */}
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
