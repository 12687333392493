import { useState } from "react";
import {
  Wrapper,
  InputFieldStyle,
  Label,
  Desc,
  DiscountWrapper,
  PwdWrapper,
  PwdInput,
  CheckboxWrapper,
  PhoneWrapper,
  SelectWrapper,
  TextAreaField,
} from "./InputStyle";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Dark, Grey } from "../Colors";
import Button from "../Button/Button";
import Badge from "../Badge/Badge";

export const Input = ({ label, state, desc, ...props }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <Wrapper>
      <Label mode={mode}>{label} </Label>
      <InputFieldStyle state={state} {...props} mode={mode} />
      <Desc state={state}>{desc}</Desc>
    </Wrapper>
  );
};

export const DiscountInput = ({
  label,
  state,
  saved,
  value,
  desc,
  onSubmit,
  isLoading,
  ...props
}) => {
  const mode = useSelector((state) => state.mode);
  return (
    <Wrapper>
      <Label mode={mode}>{label} </Label>
      <DiscountWrapper mode={mode}>
        <InputFieldStyle
          {...props}
          mode={mode}
          placeholder="Enter Discount Code"
          className="input"
          disabled={state === "firstUser" ? true : false}
          value={state === "firstUser" ? "WELCOME-GIFT" : value}
        />
        {state !== "firstUser" && (
          <Button
            label={state === "success" ? "Remove" : "Apply"}
            size="md"
            color="grey"
            showIcon="left"
            isLoading={isLoading}
            icon={
              <FeatherIcon
                icon={state === "success" ? "x" : "check"}
                size={16}
              />
            }
            onClick={onSubmit}
          />
        )}
      </DiscountWrapper>
      <Desc state={state}>{desc}</Desc>
      {(state === "success" || state === "firstUser") && (
        <Badge variant="success">{saved}% Saved</Badge>
      )}
    </Wrapper>
  );
};

export const Password = ({ label, state, desc, ...props }) => {
  const [showPwd, setShowPwd] = useState(false);
  const mode = useSelector((state) => state.mode);

  return (
    <Wrapper>
      <Label mode={mode}>{label} </Label>
      <PwdWrapper state={state} mode={mode}>
        <PwdInput type={showPwd ? "text" : "password"} mode={mode} {...props} />
        <FeatherIcon
          icon={showPwd ? "eye" : "eye-off"}
          onClick={() => setShowPwd(!showPwd)}
        />
      </PwdWrapper>
      <Desc state={state}>{desc}</Desc>
    </Wrapper>
  );
};

export const CustomPhoneInput = ({ label, state, desc, ...props }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <Wrapper>
      <Label mode={mode}>{label} </Label>
      <PhoneWrapper state={state} mode={mode}>
        <PhoneInput state={state} {...props} mode={mode} />
      </PhoneWrapper>
      <Desc state={state}>{desc}</Desc>
    </Wrapper>
  );
};

export const CheckboxInput = ({ onClick, checked, ...props }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <CheckboxWrapper mode={mode} onClick={onClick}>
      <FormControlLabel
        control={<Checkbox checked={checked} color="default" />}
        color="secondary"
        {...props}
      />
    </CheckboxWrapper>
  );
};

export const CustomSelect = ({ state, desc, label, list, ...props }) => {
  const mode = useSelector((state) => state.mode);
  const [open, setOpen] = useState(false);
  return (
    <SelectWrapper state={state} mode={mode}>
      <Label mode={mode}>{label} </Label>
      <Select
        {...props}
        variant="standard"
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              boxShadow: "0px 37px 90px 6px rgba(87, 87, 87, 0.12)",
            },
            "& .MuiList-root": {
              boxShadow: "none",
              textTransform: "capitalize",
              background: mode === "light" ? "#fff" : Dark.bg,
              color: mode === "light" ? Grey[500] : Dark.text,
            },
            "& .MuiMenuItem-root": {
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "14px",
            },
            "& .Mui-selected": {
              // backgroundColor: "primary.main",
              // color: "text.white",
            },
          },
        }}
        IconComponent={(expanded) =>
          open ? (
            <FeatherIcon icon="chevron-up" />
          ) : (
            <FeatherIcon icon="chevron-down" />
          )
        }
        open={open}
        onClick={() => setOpen(!open)}
      >
        {list.map((val, i) =>
          typeof val === "object" && val !== null ? (
            <MenuItem value={val.value} key={i}>
              {val.label}
            </MenuItem>
          ) : (
            <MenuItem value={val} key={i}>
              {val}
            </MenuItem>
          )
        )}
      </Select>
      <Desc state={state}>{desc}</Desc>
    </SelectWrapper>
  );
};

export const TextArea = ({ label, state, desc, children, ...props }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <Wrapper>
      <Label mode={mode}>{label} </Label>
      <TextAreaField state={state} {...props} mode={mode}>
        {children}
      </TextAreaField>
      <Desc state={state}>{desc}</Desc>
    </Wrapper>
  );
};
