import styled from "styled-components";
import { Grey, Primary } from "../../../atoms/Colors";
import defaultBg from "./../../../../assets/images/icons/avatar.svg";

export const AvatarHolder = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[600])};
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-image: url(${(props) => (props.bg === "" ? defaultBg : props.bg)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
`;

export const Progress = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 50px;
  > span {
    flex: 1;
    height: 5px;
    border-radius: 5px;
    background: ${(props) => (props.mode === "light" ? Grey[100] : Grey[600])};
  }
  .completed {
    background-color: ${(props) =>
      props.mode === "light" ? Grey[900] : Grey[50]};
  }
  @media (max-width: 768px) {
    gap: 14px;
  }
`;

export const SinglePicker = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid ${Grey[100]};
  border-radius: 50px;
  color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
  text-align: center;
  box-sizing: border-box;
  margin: 20px 0px;
  cursor: pointer;

  &.selected {
    background: ${Primary[500]};
    color: #fff;
    border: 0px;
    font-weight: 600;
  }

  &:hover {
    background-color: ${Primary[700]};
    color: #fff;
    border: 0px;
    font-weight: 600;
  }
`;

export const InterestWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  margin: 40px 0px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const InterestBadge = styled.span`
  padding: 16px 24px;
  text-transform: capitalize;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  &.selected {
    background: ${Primary[500]};
    color: #fff;
  }
  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 10px;
  }
`;
