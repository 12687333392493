import AuthStructure from "../../../organisms/auth/AuthStructure";
import { useNavigate, Link, Navigate } from "react-router-dom";
import img from "./../../../../assets/images/auth/login.jpg";
import { Back } from "../../../organisms/auth/AuthStructureStyle";
import FeatherIcon from "feather-icons-react";
import Typography from "./../../../atoms/Typography/Typography";
import { useSelector } from "react-redux";
import GoogleBtn from "../../../atoms/Button/GoogleBtn";

import SignUpForm from "../../../organisms/forms/signup/SignUpForm";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";

const MainContent = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (auth.login) {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      <TestimonyPopup />

      <Back onClick={() => navigate(-1)}>
        <FeatherIcon icon="arrow-left" />
      </Back>

      <Typography
        variant="h3"
        align="center"
        weight="bold"
        style={{ marginTop: 50 }}
      >
        Let’s get you started
      </Typography>
      <Typography variant="p3" align="center">
        Create your account
      </Typography>
      {/* form */}
      <SignUpForm />
      {/* <Typography variant="p3" align="center" weight="semi-bold">
        OR
      </Typography>

      <GoogleBtn /> */}

      <Typography
        variant="p3"
        align="center"
        weight="semi-bold"
        style={{ marginTop: 20 }}
      >
        Don't have an account? <Link to="/login">Log In</Link>
      </Typography>
    </>
  );
};
const SignUp = () => {
  return <AuthStructure main={<MainContent />} bg={img} />;
};

export default SignUp;
