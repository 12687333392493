import Accordion from "../../../../organisms/accordion/Accordion";
import { Container, SectionWrapper } from "../../../../../helpers/GlobalStyle";
import Typography from "../../../../atoms/Typography/Typography";
import { Grey, Dark } from "../../../../atoms/Colors";

const Faq = ({ mode }) => {
  const data = [
    {
      label: "questions",
      title: "Can I ask questions while taking the course?",
      desc: "Unlike most online platforms where you can not ask questions because the course is online, with us you can ask questions and get a reply immediately. You can ask questions via live chat, via the support group or by scheduling a call with your instructor.",
    },
    {
      label: "fee",
      title: "Fees & Payments",
      desc: "You will not be paying any extra fees apart from your monthly subscription. For bootcamps, you will be required to pay an extra N2,500 monthly for each bootcamp you enroll for.",
    },
    {
      label: "pm",
      title: "Payment Methods",
      desc: "Payments methods are shown in the billing or subscription pages.",
    },
    {
      label: "duration",
      title: "What is the duration?",
      desc: "Courses and Career Paths are self paced but Live Bootcamps runs to the specified end dates.",
    },
    {
      label: "mentor",
      title: "How can I get a mentor?",
      desc: "Once you enroll for our Live Bootcamps, you will be assign a mentor",
    },
    {
      label: "internship",
      title: "Will I get a Job or Internship?",
      desc: "We only assist our students during their applications and don't neccessarily place them ourselves. We assist by sending resume templates and reviewing your resumes, we send you job openings you can apply to. If you enrolled for the Live Bootcamps, your mentor will work with you to ensure you have an amazing portfolio, well drafted resume and ensure you are applying to the right jobs. We have over 90% success rate of our students who get jobs after our live bootcamps.",
    },
    {
      label: "certs",
      title: "Will I be issued a certificate?",
      desc: "Yes, you will be issued a certificate of completion by us. You can add it to your certification on LinkedIn, resume or any use case that need you to present a certficate of your new career or profession.",
    },
    {
      label: "certs_duration",
      title: "How long will it take to get the certificate?",
      desc: "Immediately you finished the course or bootcamp you enrolled for, you will be able to print and download your certificate.",
    },
  ];
  return (
    <SectionWrapper
      style={{ background: mode === "light" ? Grey[50] : Dark.bgCard }}
    >
      <Container>
        <Typography
          variant="h3"
          weight="bold"
          align="center"
          style={{ marginBottom: "50px" }}
        >
          F.A.Q
        </Typography>
        <Accordion data={data} />
      </Container>
    </SectionWrapper>
  );
};

export default Faq;
