import {
  FooterWrapper,
  FooterMenu,
  SocialIconWrapper,
  SocialIcon,
  CopyRights,
} from "./FooterStyle";
import { Container, Row } from "../../../helpers/GlobalStyle";
import logo from "../../../assets/images/logo.png";
import logo2 from "../../../assets/images/logo2.png";
import Typography from "../../atoms/Typography/Typography";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";

const Footer = () => {
  const mode = useSelector((state) => state.mode);
  return (
    <FooterWrapper mode={mode}>
      <Container>
        <Row>
          <div>
            <img
              src={mode === "light" ? logo : logo2}
              alt="Nerdy Eye Logo"
              width="70"
            />
          </div>
          <FooterMenu>
            <Typography variant="h5" weight="semi-bold">
              Products
            </Typography>
            <ul>
              <li>
                <Link to="/">communities</Link>
              </li>
              <li>
                <Link to="/">courses</Link>
              </li>
              <li>
                <Link to="/">Workforce</Link>
              </li>
            </ul>
          </FooterMenu>
          <FooterMenu>
            <Typography variant="h5" weight="semi-bold">
              Legal
            </Typography>
            <ul>
              <li>
                <Link to="/">Privacy</Link>
              </li>
              <li>
                <Link to="/">Terms</Link>
              </li>
            </ul>
          </FooterMenu>

          <FooterMenu>
            <Typography variant="h5" weight="semi-bold">
              Contact
            </Typography>
            <ul>
              <li>+234 812 724 4042</li>
              <li>hello@nerdyeye.com</li>
            </ul>
            <SocialIconWrapper>
              <SocialIcon className="instagram">
                <a href="https://instagram.com/nerdy_eye" target="_blank">
                  <FeatherIcon icon="instagram" size="18" />
                </a>
              </SocialIcon>

              <SocialIcon className="twitter">
                <a href="https://twitter.com/nerdyeyeng" target="_blank">
                  <FeatherIcon icon="twitter" size="18" />
                </a>
              </SocialIcon>

              <SocialIcon className="linkedin">
                <a
                  href="https://www.linkedin.com/company/19029069"
                  target="_blank"
                >
                  <FeatherIcon icon="linkedin" size="18" />
                </a>
              </SocialIcon>

              <SocialIcon className="youtube">
                <a href="https://www.youtube.com/c/NerdyEye" target="_blank">
                  <FeatherIcon icon="youtube" size="18" />
                </a>
              </SocialIcon>
            </SocialIconWrapper>
          </FooterMenu>
        </Row>

        <CopyRights>
          © 2023 Nerdy Eye All Rights Reserved. Powered By Dungada Tech Ltd
        </CopyRights>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
