const Interest = [
  "community management",
  "communities",
  "project management",
  "startup",
  "content creation",
  "business & finance",
  "product design",
  "mobile app dev",
  "data science",
  "digital marketing",
  "graphics design",
  "web development",
  "growth hacking",
  "job opportunities",
  "internships",
  "networking",
  "talent recruiting",
  "NFT",
  "crypto currencies",
  "web 3.0",
  "portfolio building",
  "up-skilling",
  "brand building",
  "Entrepreneur",
  "tech events",
];

export default Interest;
