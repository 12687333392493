import { useEffect, useState } from "react";
import { allData } from "../../../../../api/api";
import { Container } from "../../../../../helpers/GlobalStyle";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MainLearningArea from "./temp/MainLearningArea";
import Badge from "../../../../atoms/Badge/Badge";
import Typography from "../../../../atoms/Typography/Typography";
import moment from "moment/moment";
//
import { AccordionWrapper } from "../../../../organisms/accordion/AccordionStyle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
//
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Grey, Dark, Extra, Success } from "../../../../atoms/Colors";
import BootcampRelated from "../../../courses/bootcamp/BootcampRelated";
import UserCourseLoader from "../../../../molecules/loaders/UserCourseLoader";
// import Notification from "../../../../molecules/notifications/Notification";
import SubscriptionNotification from "../../../../molecules/notifications/SubscriptionNotification";
import { singleRecord } from "../../../../../api/api";
import Advisor from "../../../courses/course/advisor/Advisor";
import Button from "../../../../atoms/Button/Button";
import TestimonyPopup from "../../../../organisms/testimonials/TestimonyPopup";

const UserBootcamp = () => {
  const mode = useSelector((state) => state.mode);
  const { url } = useParams();
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState();
  const [regBatch, setRegBatch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [subStatus, setSubStatus] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [searchParams, setSeachParams] = useSearchParams();

  let navigate = useNavigate();

  const getBootcampSub = async (id) => {
    const res = await singleRecord(auth.token, `/sub/bootcamp-access/${id}`);
    setSubStatus(res.sub_status);
  };

  const getData = async () => {
    const res = await allData(auth.token, `enrollment/bootcamps/${url}`);
    if (res.status === "success") {
      setData(res.data);
      getBootcampSub(res.data.bootcamp_id._id);
      const batch = res.data.bootcamp_id.batches.find(
        (batch) => batch._id === res.data.batch_id
      );
      setRegBatch(batch);
    } else {
      navigate("/courses/search?type=bootcamps");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <UserCourseLoader />;
  }

  if (subStatus !== "active" && subStatus !== "") {
    return (
      <Container style={{ marginTop: "20px" }}>
        <TestimonyPopup />
        <SubscriptionNotification
          status={subStatus}
          url={`/subscription?bid=${data.bootcamp_id._id}`}
          desc="You don't have an active subscription. Click the button below to do so now."
        />
      </Container>
    );
  }

  if (regBatch) {
    if (regBatch.status === "not-started") {
      return (
        <Container style={{ marginTop: "20px" }}>
          <SubscriptionNotification
            status="Not Started Yet"
            type="batch"
            group={regBatch.details.community_link}
            desc={`Start Date: ${moment(regBatch.start_date).format(
              "MMM Do, YYYY"
            )}`}
          />
        </Container>
      );
    }
  }

  return (
    <div>
      {searchParams.get("new") && (
        <Advisor
          padding={0}
          title="one more thing!"
          desc="Click the button below to join the Private Support Group & check the Bootcamp Batch Details for time, day & meeting link for the live classes."
          CTA={
            <a href={regBatch.details.community_link} target="_blank">
              <Button
                style={{
                  background: Extra.yellow,
                  color: "#000",
                }}
                label="Join Support Group"
              />
            </a>
          }
        />
      )}

      <Container style={{ marginTop: "20px", textTransform: "capitalize" }}>
        <strong>
          Status:{" "}
          <span
            style={{
              color:
                regBatch.status === "completed" ? Success[500] : Extra.yellow,
            }}
          >
            {regBatch.status}
          </span>
        </strong>
      </Container>

      {/* main lessons area */}
      <MainLearningArea
        courses={data.bootcamp_id.career_path_id.course_Ids}
        type="bootcamps"
        genData={data}
        groupLink={regBatch.details.community_link}
        regBatch={regBatch}
      />

      {/* batch details */}
      {/* <Container>
        <AccordionWrapper mode={mode}>
          <Accordion
            disableGutters
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
              borderBottom: `1px solid ${
                mode === "light" ? Grey[50] : Grey[600]
              }`,
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded ? (
                  <FeatherIcon icon="minus" size="16" />
                ) : (
                  <FeatherIcon icon="plus" size="16" />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="p2"
                className="title"
                weight="bold"
                style={{ textTransform: "capitalize" }}
              >
                Bootcamp Batch Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                color: mode === "light" ? Grey[500] : Dark.text,
              }}
            >
              <div>
                <Typography variant="p2">
                  {regBatch.details.description}
                </Typography>
                <Typography variant="p2">
                  <strong>Name: </strong> {regBatch.name}
                </Typography>
                <Typography variant="p2">
                  <strong>Start Date: </strong>{" "}
                  {moment(regBatch.start_date).format("MMM Do, YYYY")}
                </Typography>
                <Typography variant="p2">
                  <strong>End Date : </strong>
                  {moment(regBatch.end_date).format("MMM Do, YYYY")}
                </Typography>
                <Typography variant="p2">
                  <strong>Total Duration: </strong> {regBatch.details.timeframe}
                </Typography>
                <Typography variant="p2">
                  <strong>Support Group: </strong>
                  <a href={regBatch.details.community_link} target="_blank">
                    {regBatch.details.community_link}
                  </a>
                </Typography>

                <Typography variant="p2">
                  <strong>Meeting Intervals: </strong>{" "}
                  {regBatch.details.intervals}
                </Typography>

                <Typography variant="p2">
                  <strong>Time: </strong> {regBatch.details.time}
                </Typography>
                <Typography variant="p2">
                  <strong>Location: </strong> {regBatch.details.location}
                </Typography>

                <Typography variant="p2">
                  <strong>Live Class Meeting Link: </strong>
                  <a href={regBatch.details.meeting_link} target="_blank">
                    {regBatch.details.meeting_link}
                  </a>
                </Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        </AccordionWrapper>
      </Container> */}

      {/* <BootcampRelated
        title="Related Bootcamps"
        desc="Other people who took his bootcamp also took the following bootcamps"
        url={`/bootcamps/related-bootcamps/${data.bootcamp_id._id}?limit=3`}
      /> */}
    </div>
  );
};

export default UserBootcamp;
