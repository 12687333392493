import Typography from "../../../atoms/Typography/Typography";
import { Container } from "../../../../helpers/GlobalStyle";
import { SrcWrapper, SrcInput, SrcInputWrapper } from "./SearchStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { CourseList } from "../hero/HeroStyle";
import { useSearchParams } from "react-router-dom";
import SearchLayout from "./SearchLayout";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const Search = () => {
  const mode = useSelector((state) => state.mode);
  const [src, setSrc] = useState("");
  const [searchParams, setSeachParams] = useSearchParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setSrc(searchParams.get("q"));
  }, []);

  return (
    <>
      <Helmet>
        <title> Courses Search - Nerdy Eye</title>
        <meta
          name="description"
          content="Search for Free and paid online courses, training and bootcamps. Get access to project based courses and learn by doing"
        />
      </Helmet>
      <SrcWrapper mode={mode}>
        <Container>
          <Typography variant="h3" weight="bold" align="center">
            What will you enjoy learning?
          </Typography>
          <SrcInputWrapper mode={mode}>
            <SrcInput mode={mode}>
              <FeatherIcon icon="search" />
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  name="q"
                  onChange={(e) => setSrc(e.target.value)}
                  value={src}
                />
              </form>
            </SrcInput>
            <div className="filter-btn" onClick={toggleSidebar}>
              <FeatherIcon icon="filter" />
            </div>
          </SrcInputWrapper>
        </Container>
      </SrcWrapper>

      <CourseList mode={mode} style={{ marginTop: "50px" }}>
        <span>Might be Interested in:</span>
        <ul>
          {/* <li>
            <a href="/courses/search?type=bootcamps&mode=physical">
              Physical Bootcamp
            </a>
          </li> */}
          <li>
            <a href="/courses/search?type=bootcamps&mode=online">
              Online Bootcamp
            </a>
          </li>
          <li>
            <a href="/courses/search?type=careerpath">Career Path</a>
          </li>
          <li>
            <a href="/courses/search?type=courses">Courses</a>
          </li>
        </ul>
      </CourseList>

      <SearchLayout
        mode={mode}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  );
};

export default Search;
