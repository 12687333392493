import CourseHero from "../course/hero/CourseHero";
import Overview from "../course/details/Overview";
import Gain from "../course/gain/Gain";
import PathInstructors from "../instructor/PathInstructors";
import { Grey, Dark, Extra } from "../../../atoms/Colors";
// import Testimonials from "../../../organisms/testimonials/Testimonials";
import Faq from "../course/faq/Faq";
import { useSelector } from "react-redux";
import useFetch from "../../../../api/useFetch";
import { useParams } from "react-router-dom";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import { getCareerPathDuration } from "../../../../helpers/getDuration";
import { useState } from "react";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
// import BootcampRelated from "../bootcamp/BootcampRelated";
import WhyLearnWithUs from "../course/why/WhyLearnWithUs";
import GoodVibes from "../course/goodvibes/GoodVibes";
import Typography from "../../../atoms/Typography/Typography";
import Advisor from "../course/advisor/Advisor";
import VideoTestimonials from "../course/videotestimonials/VideoTestimonials";
import { Helmet } from "react-helmet";
import Button from "../../../atoms/Button/Button";
// import Button from "../../../../atoms/Button/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";

const BootcampPage = () => {
  const mode = useSelector((state) => state.mode);
  let { url } = useParams();
  const { data, loading, error } = useFetch(`bootcamps/${url}`);
  const [batches, setBatches] = useState(null);
  const [isBatchReady, setIsBatchReady] = useState(false);
  const [isInstructorReady, setIsInstructorReady] = useState(false);
  const [instructorData, setInstructorData] = useState(null);

  const getActiveBadge = (batches) => {
    const lastIndex = batches.length - 1;
    setBatches(batches[lastIndex]);
    // console.log(batches[lastIndex]);
    // setBatches(batches[i])
    // for (let i = 0; i < batches.length; i++) {
    //   if (batches[i].status === "not-started") {
    //     setBatches(batches[i]);
    //     break;
    //   }
    // }
  };

  if (loading) {
    return (
      <div>
        <CardSkeleton
          style={{ width: "100%", borderRadius: "0px", height: "500px" }}
          mode={mode}
        />
        <Container>
          <CardSkeleton
            variant="text"
            style={{
              width: "200px",
              borderRadius: "10px",
              marginTop: "56px",
              height: "50px",
            }}
            mode={mode}
          />

          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
              marginBottom: "56px",
            }}
            mode={mode}
          />
        </Container>
      </div>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  if (data && !isBatchReady) {
    getActiveBadge(data.data.batches);
    setIsBatchReady(true);
  }

  if (data && !isInstructorReady) {
    let prepData = [];
    data.data.career_path_id.course_Ids.map((course) =>
      prepData.push({
        name: course.instructorID.name,
        profession: course.instructorID.profession,
        avatar: course.instructorID.avatar.url,
        bio: course.instructorID.bio,
      })
    );
    setInstructorData(prepData);
    setIsInstructorReady(true);
  }

  return (
    <div>
      <TestimonyPopup />
      <Helmet>
        <title> {data.data.title} Bootcamp - Nerdy Eye</title>
        <meta
          name="description"
          content={`Transition into tech with a structured ${data.data.title}  bootcamps. Learn, Get Mentored & Get a Job`}
        />
        <meta name="image" content={data.data.avatar.url} />
      </Helmet>
      <CourseHero
        type="bootcamps"
        title={data.data.title}
        content={`${data.data.career_path_id.course_Ids.length} Courses`}
        badge="purple"
        badgeText={`${data.data.type} Bootcamp`}
        time={getCareerPathDuration(data.data.career_path_id.course_Ids)}
        certificate={true}
        bg={data.data.avatar.url}
        batches={batches}
        trailer={data.data.trailer_url}
        id={data.data._id}
        slug={data.data.slug}
      />

      <Overview
        title="Bootcamp"
        desc={data.data.description}
        content={`${data.data.career_path_id.course_Ids.length} Courses`}
        type="bootcamp"
        batches={batches ? batches : null}
        mode={mode}
        lessons={
          data.data.career_path_id.course_Ids
            ? data.data.career_path_id.course_Ids
            : []
        }
      />

      <Gain
        desc={data.data.career_path_id.why_desc}
        list={data.data.career_path_id.why_list}
      />

      <WhyLearnWithUs />
      <GoodVibes />

      {/* <SectionWrapper>
        <Container>
          <Typography variant="h3" weight="bold" align="center">
            Bootcamp Pricing
          </Typography>
          <Typography variant="p1" weight="bold" align="center">
            You just need to have an active monthly subscription
          </Typography>
          <Typography variant="h2" weight="bold" align="center">
            +&#x20a6;2,500/month
          </Typography>
        </Container>
      </SectionWrapper> */}

      <div
        style={{
          background: mode === "light" ? Grey[50] : Dark.bgCard,
          // marginTop: "100px",
        }}
      >
        <PathInstructors
          subHeading="INSTRUCTORS"
          title="Learn with the best"
          align="center"
          data={instructorData}
        />
      </div>

      {/* <Testimonials
        title="Reviews from learners"
        desc="What other students are saying about this course"
      /> */}

      <VideoTestimonials />

      <Faq mode={mode} />

      <Advisor
        title="Not Sure On What To Learn?"
        desc="If you are not sure if this course or bootcamp is right for you, feel free to book a call with a learning advisor now. We are always here to help 👇"
        CTA={
          <a
            href="https://calendly.com/nerdyeye/30min?month=2023-05"
            target="_blank"
          >
            <Button
              label="Book Now"
              style={{
                background: Extra.yellow,
                color: "#000",
              }}
              showIcon="right"
              icon={<FeatherIcon icon="chevron-right" size={18} />}
            />
          </a>
        }
      />

      {/* <BootcampRelated
        title="Related Bootcamps"
        desc="Other people took the following bootcamps"
        url={`/bootcamps/related-bootcamps/${data.data._id}?limit=3`}
      /> */}
    </div>
  );
};

export default BootcampPage;
