import { Container } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { HeroWrapper, Wrapper, FloatingBadge, VideoPlayer } from "./HeroStyle";
import Button from "../../../atoms/Button/Button";
import AnimatedText from "react-animated-text-content";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { ButtonGroup } from "../../../atoms/Button/ButtonStyle";
import { Link } from "react-router-dom";
import icons from "../../../../assets/icons";
import bg from "./../../../../assets/images/hero-img.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import ModalVideo from "react-modal-video";
import { useState } from "react";

const Hero = () => {
  const [playMovie, setPlayMovie] = useState(false);
  return (
    <HeroWrapper>
      <Container>
        <Wrapper>
          {/* <FloatingBadge className="one">learn</FloatingBadge>
          <FloatingBadge
            type="connect"
            style={{ left: "45%", transform: "rotate(14.64deg)" }}
            className="two"
          >
            connect
          </FloatingBadge>
          <FloatingBadge type="grow" className="three">
            grow
          </FloatingBadge> */}
          <Typography
            variant="h1"
            weight="bold"
            align="center"
            style={{ textTransform: "none" }}
          >
            <AnimatedText
              type="words" // animate words or chars
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.5,
                ease: "ease-in-out",
              }}
              animationType="blocks"
              interval={0.06}
              duration={0.5}
              tag="span"
              className="animated-paragraph"
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              Host & Sell Online Courses To Your Community
            </AnimatedText>
          </Typography>
          <Typography variant="p1" align="center">
            Create your learning trybe, host & sell online courses or cohort
            based program to your trybe
            {/* Creators or coaches can build their learning Trybe, create & sell
            courses while students join learning trybes and learn in peers,
            groups & communities. */}
          </Typography>
          <ButtonGroup style={{ justifyContent: "center" }}>
            <a href="https://forms.gle/1t3SNagtDwXprxrT6" target="_blank">
              <Button
                label="For Creators"
                icon={<FeatherIcon icon="arrow-right" size={16} />}
                showIcon="right"
                className="btn"
              />
            </a>
            <Link to="/courses/search">
              <Button label="For Learners" variant="text" className="btn" />
            </Link>
          </ButtonGroup>
          <icons.CurveLines className="curve-lines" />
          <VideoPlayer bg={bg} onClick={() => setPlayMovie(true)}>
            <div className="play">
              <FontAwesomeIcon icon={faPlay} />
            </div>
          </VideoPlayer>
        </Wrapper>
      </Container>

      {/* trailer modal */}
      <ModalVideo
        channel="youtube"
        autoplay={true}
        isOpen={playMovie}
        videoId="EefRrbXknqg"
        onClose={() => setPlayMovie(false)}
      />
    </HeroWrapper>
  );
};

export default Hero;
