import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "../Typography/Typography";
import styled from "styled-components";
import { Dark, Grey, Primary } from "../Colors";

export default function ControlledRadioButtonsGroup({
  title,
  list,
  mode,
  ...props
}) {
  return (
    <div style={{ color: mode === "light" ? Grey[500] : Dark.text }}>
      <FormControl>
        <Typography variant="h6" weight="semi-bold" style={{ margin: 0 }}>
          {title}
        </Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          {...props}
        >
          {list.map((item, i) => (
            <FormControlLabel
              value={item.value}
              control={
                <Radio
                  sx={{
                    color: mode === "light" ? Grey[500] : Dark.text,
                    "&.Mui-checked": {
                      color: Primary[500],
                    },
                  }}
                />
              }
              label={item.label}
              key={i}
              disableRipple={true}
              sx={{ textTransform: "capitalize" }}
              textColor="inherit"
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
