import Typography from "../../atoms/Typography/Typography";
import { TestPopup } from "./TestimonialStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faClose } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Button from "../../atoms/Button/Button";
import { useSelector } from "react-redux";

const data = [
  {
    name: "paul felix",
    testimony:
      "Hi 👋 I saw what you guys did with the bootcamp thing, I love it. Thank you for having lazy people like us in mind. It's truly a life changer.",
    img: "",
  },
  {
    name: "Zainab Ali",
    testimony:
      "The lessons are very insightful and it shows Nerd Eye has prioritize helping newbies in tech.",
    img: "",
  },
  {
    name: "Olamide",
    testimony:
      "The videos in each session are super cool, easy to follow and super amazing. However, I had a little challenge with my internet connection but I'm definitely preparing for a good network provider.",
    img: "",
  },
  {
    name: "Adibari",
    testimony:
      "I'm lucky to have found out about Nerdy Eye, I have been learning on my own before but I wasn't understanding but since I joined the community, it has been easy for me .",
    img: "",
  },
  {
    name: "Bimpe",
    testimony:
      "I have seen other plaforms, they are static and unfriendly but Nerdy Eye, you guys blew my mind. I have already given up but you guys gave me hope.",
    img: "",
  },
  {
    name: "Anthony",
    testimony:
      "I've been struggling with the whole self paced thing. Got a course on Udemy on cloud computing but I lost interest in it. Ever since I started learning with the community here, it has been amazing as I no longer feel alone.",
    img: "",
  },
  {
    name: "Pizzy Dedon",
    testimony:
      "😁 Help me tell the Frontend facilitator that I'm enjoying her lessons especially that small shakara she use to do at the end of her videos lol!",
    img: "",
  },
  {
    name: "Benny",
    testimony:
      "The classes have been on point, every topics and lessons stood out, I really appreciate Nerdy Eye, The experience has been awesome 🕺💃",
    img: "",
  },
];

const TestimonyPopup = () => {
  const [show, setShow] = useState(false);
  const [testimony, setTestimony] = useState(null);
  const mode = useSelector((state) => state.mode);

  const getStar = (star) => {
    var indents = [];
    for (var i = 0; i < star; i++) {
      indents.push(<FontAwesomeIcon icon={faStar} />);
    }
    return indents;
  };

  const getTestimony = () => {
    let item = data[Math.floor(Math.random() * data.length)];
    setTestimony(item);
  };

  const handleClose = () => {
    setShow(false);

    getTestimony();
    setTimeout(() => {
      setShow(true);
    }, 30000);
  };

  const handleStart = () => {
    getTestimony();
    setTimeout(() => {
      setShow(true);
    }, 3000);
  };

  useEffect(() => {
    handleStart();
  }, []);

  return (
    <>
      <TestPopup show={show} mode={mode}>
        <div className="top">
          <div className="profile"></div>
          <div>
            <Typography
              variant="h6"
              weight="semi-bold"
              style={{ textTransform: "capitalize" }}
            >
              {testimony && testimony.name}
            </Typography>
            <div style={{ color: "gold" }}>{getStar(5)}</div>
          </div>
        </div>
        <FontAwesomeIcon
          icon={faClose}
          className="close"
          onClick={handleClose}
        />
        <Typography variant="p3">{testimony && testimony.testimony}</Typography>
        <Button variant="text" size="md" onClick={getTestimony} label="Next" />
      </TestPopup>
    </>
  );
};

export default TestimonyPopup;
