export const Primary = {
  50: "#FBEBEA",
  100: "#F1C0BF",
  200: "#EAA2A0",
  300: "#E17775",
  400: "#DB5D5A",
  500: "#D23431",
  600: "#BF2F2D",
  700: "#952523",
  800: "#741D1B",
  900: "#581615",
};

export const Secondary = {
  50: "#fef4ec",
  100: "#feeee3",
  200: "#fddcc4",
  300: "#f78f42",
  400: "#de813b",
  500: "#c67235",
  600: "#b96b32",
  700: "#945628",
  800: "#6f401e",
  900: "#563217",
};

export const Grey = {
  50: "#F5F5F5",
  100: "#D4D4D4",
  200: "#C0C0C0",
  300: "#A3A3A3",
  400: "#919191",
  500: "#757575",
  600: "#454444",
  700: "#313131",
  800: "#191A1D",
  900: "#121316",
};

export const Success = {
  50: "#E6F3F2",
  100: "#B0DAD6",
  200: "#8AC9C2",
  300: "#54B0A7",
  400: "#33A195",
  500: "#00897B",
  600: "#007D70",
  700: "#006157",
  800: "#004B44",
  900: "#003A34",
};

export const Error = {
  50: "#FEECEB",
  100: "#FCC5C1",
  200: "#FAA9A3",
  300: "#F88178",
  400: "#F6695E",
  500: "#F44336",
  600: "#DE3D31",
  700: "#AD3026",
  800: "#86251E",
  900: "#661C17",
};

export const Warning = {
  50: "#FFF4E6",
  100: "#FEDBB0",
  200: "#FDCA8A",
  300: "#FCB254",
  400: "#FCA333",
  500: "#FB8C00",
  600: "#E47F00",
  700: "#B26300",
  800: "#8A4D00",
  900: "#693B00",
};

export const Info = {
  50: "#E6F5FC",
  100: "#B1E0F7",
  200: "#8CD1F3",
  300: "#57BCEE",
  400: "#36AFEA",
  500: "#049BE5",
  600: "#048DD0",
  700: "#036EA3",
  800: "#02557E",
  900: "#024160",
};

export const Extra = {
  yellow: "#F8BF3E",
  purple: "#7B61FF",
};

export const Dark = {
  bg: Grey[900],
  bgCard: "#26272D",
  heading: Grey[50],
  text: Grey[200],
};
