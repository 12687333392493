// const isDark = window.matchMedia("(prefers-color-scheme:dark)").matches;

const modeReducer = (state = "dark", action) => {
  switch (action.type) {
    case "light":
      return "light";
    case "dark":
      return "dark";
    default:
      return state;
  }
};

export default modeReducer;
