import styled from "styled-components";
import { Grey, Dark } from "../Colors";

const regular = 400;
const semiBold = 500;
const bold = 700;

const fontWeight = (props) => {
  return props.weight === "semi-bold"
    ? semiBold
    : props.weight === "bold"
    ? bold
    : 400;
};

export const HeadingOne = styled.h1`
  font-size: 96px;
  ${(props) => props.style}
  /* font-weight: ${fontWeight}; */
  font-weight: 400;
  font-family: "Exo 2";
  text-transform: uppercase;
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
  @media (max-width: 920px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeadingTwo = styled.h2`
  font-size: 48px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  text-transform: capitalize;
  font-family: "Exo 2";
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
  margin: 0px;
  @media (max-width: 480px) {
    font-size: 36px;
  }
`;

export const HeadingThree = styled.h3`
  font-size: 32px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  font-family: "Exo 2";
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
  margin: 0px;
  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

export const HeadingFour = styled.h4`
  font-size: 26px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  font-family: "Exo 2";
  margin: 0px;
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
`;

export const HeadingFive = styled.h5`
  font-size: 20px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  font-family: "Exo 2";
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
`;

export const HeadingSix = styled.h6`
  font-size: 18px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  font-family: "Exo 2";
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[900])};
`;

export const ParagraphOne = styled.p`
  font-size: 18px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.text : Grey[500])};
`;

export const ParagraphTwo = styled.p`
  font-size: 16px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.text : Grey[500])};
`;

export const ParagraphThree = styled.p`
  font-size: 14px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.text : Grey[500])};
`;

export const SmallOne = styled.p`
  font-size: 12px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.text : Grey[500])};
`;

export const SmallTwo = styled.p`
  font-size: 10px;
  ${(props) => props.style}
  font-weight: ${(props) => fontWeight(props)};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  color: ${(props) => (props.mode === "dark" ? Dark.text : Grey[500])};
`;
