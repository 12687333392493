import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { Grey } from "../../atoms/Colors";
import Typography from "../../atoms/Typography/Typography";
import { BreadcrumbsWrapper } from "./BreadcrumbsStyle";

const Breadcrumbs = ({ label, preLabel, preLabelUrl }) => {
  if (preLabel !== undefined) {
    return (
      <BreadcrumbsWrapper>
        <Link to={preLabelUrl}>{preLabel}</Link>
        <FeatherIcon icon="chevron-right" stroke={Grey[300]} />
        <Typography variant="small1">{label}</Typography>
      </BreadcrumbsWrapper>
    );
  }
  return <BreadcrumbsWrapper>{label}</BreadcrumbsWrapper>;
};

export default Breadcrumbs;
