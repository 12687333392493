import { useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import {
  Input,
  Password,
  CustomPhoneInput,
  CheckboxInput,
} from "./../../../atoms/inputs/Input";
import { FormExtraAction } from "./../../../templates/auth/signup/SignUpStyle";
import Button from "../../../atoms/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

import axios from "./../../../../axios/axios";
import { useDispatch } from "react-redux";
import { login } from "../../../../actions/auth";
import Notification from "../../../molecules/notifications/Notification";

const SignUpForm = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [phone, setPhone] = useState();
  const [phoneErr, setPhoneErr] = useState("");
  const [apiErr, setApiErr] = useState("");
  const dispatch = useDispatch();

  const signUp = async (values) => {
    setIsLoading(true);

    try {
      let ipAddress = "127.0.0.1";
      const phoneNum = {
        code: parsePhoneNumber(phone).countryCallingCode,
        number: formatPhoneNumber(phone),
      };
      await axios
        .get(`https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`)
        .then((res) => {
          ipAddress = res.data.ip;
        })
        .catch((err) => console.log(err));

      const body = {
        ...values,
        phone: phoneNum,
        ipAddress,
        ref: searchParams.get("ref") ? searchParams.get("ref") : "",
        custom_ref: searchParams.get("custom_ref")
          ? searchParams.get("custom_ref")
          : "",
      };
      const res = await axios.post("/auth", body);
      // console.log(res);
      dispatch(login(true, res.data.token, res.data.data));
      //redirect to bootcamp enrollment if user was coming from bootcamp enrollment page
      searchParams.get("type") &&
      searchParams.get("url") &&
      searchParams.get("type") === "bootcamps"
        ? navigate(`/courses/bootcamps/${searchParams.get("url")}`)
        : navigate("/sales");
    } catch (error) {
      error.response.data && setApiErr(error.response.data.message);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("invalid email address").required("Required"),
      username: Yup.string().required("Required"),
      password: Yup.string().min(8).required("Required"),
    }),
    onSubmit: (values) => {
      if (!phone) {
        setPhoneErr("Required");
      } else {
        //form submittion
        signUp(values);
      }
    },
  });

  return (
    <div>
      <form action="" onSubmit={formik.handleSubmit}>
        <Input
          label="Email Address"
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          state={formik.touched.email && formik.errors.email && "error"}
          desc={
            formik.touched.email && formik.errors.email && formik.errors.email
          }
        />
        <Input
          label="Username"
          type="text"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          state={formik.touched.username && formik.errors.username && "error"}
          desc={
            formik.touched.username &&
            formik.errors.username &&
            formik.errors.username
          }
        />
        <CustomPhoneInput
          label="Phone Number"
          international
          placeholder="Enter phone number"
          countryCallingCodeEditable={false}
          defaultCountry="NG"
          onBlur={() => phone && setPhoneErr("")}
          value={phone}
          onChange={setPhone}
          state={phoneErr !== "" && "error"}
          desc={phoneErr}
        />
        <Password
          label="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />

        {searchParams.get("ref") && (
          <Input
            label="Referral"
            type="text"
            name="ref"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={searchParams.get("ref")}
            state={formik.touched.ref && formik.errors.ref && "error"}
            desc={formik.touched.ref && formik.errors.ref && formik.errors.ref}
            disabled
          />
        )}

        {apiErr !== "" && (
          <Notification
            showClose={false}
            desc={apiErr}
            severity="error"
            variant="outlined"
          />
        )}

        <Button
          label="Create Account"
          color="grey"
          block={true}
          type="submit"
          isLoading={isLoading}
          style={{ margin: "30px 0px" }}
        />
      </form>
    </div>
  );
};

export default SignUpForm;
