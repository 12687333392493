import { useState } from "react";
import { Password } from "./../../../atoms/inputs/Input";
import Button from "../../../atoms/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "./../../../../axios/axios";
import Toast from "../../../molecules/toast/Toast";
import Typography from "../../../atoms/Typography/Typography";
import { useSelector, useDispatch } from "react-redux";

const ResetPwdForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyType, setNotifyType] = useState("");
  const [notifyMsg, setNotifyMsg] = useState("");
  const getfPwd = useSelector((state) => state.fPwd);

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axios.patch("/auth/reset-password", {
        otp: getfPwd.data,
        password: values.password,
      });

      handleNotify("You have successfully reset your password", "success");
      setSuccess(true);
    } catch (error) {
      handleNotify(
        "OTP incorrect or has expired, go back and generate a new one",
        "error"
      );
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      cPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().min(8).required("Required"),
      cPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: (values) => {
      //form submittion
      handleSubmit(values);
    },
  });
  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      {!isSuccess ? (
        <form onSubmit={formik.handleSubmit}>
          <Password
            label="Current Password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            state={formik.touched.password && formik.errors.password && "error"}
            desc={
              formik.touched.password &&
              formik.errors.password &&
              formik.errors.password
            }
          />

          <Password
            label="Confirm Password"
            name="cPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cPassword}
            state={
              formik.touched.cPassword && formik.errors.cPassword && "error"
            }
            desc={
              formik.touched.cPassword &&
              formik.errors.cPassword &&
              formik.errors.cPassword
            }
          />
          <Button
            label="Continue"
            color="grey"
            block={true}
            isLoading={isLoading}
            style={{ margin: "20px 0px" }}
          />
        </form>
      ) : (
        <>
          <Typography variant="p3" align="center">
            Your password have been reset, click the button below to enroll
          </Typography>

          <a href="/login">
            <Button
              label="Log in now"
              block={true}
              style={{ margin: "20px 0px" }}
            />
          </a>
        </>
      )}
    </div>
  );
};

export default ResetPwdForm;
