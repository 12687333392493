import styled from "styled-components";
import {
  Primary,
  Success,
  Info,
  Warning,
  Error,
  Extra,
} from "../../atoms/Colors";

export const Wrapper = styled.div`
  .MuiAlert-root {
    font-family: "Montserrat", sans-serif;
  }
  .MuiAlertTitle-root {
    font-family: "Montserrat", sans-serif;
    margin: 0px;
  }

  .MuiAlert-standardSuccess {
    background: ${Success[600]};
    color: #fff;
  }

  .MuiAlert-standardInfo {
    background: ${Info[600]};
    color: #fff;
  }

  .MuiAlert-standardError {
    background: ${Error[600]};
    color: #fff;
  }

  .MuiAlert-standardWarning {
    background: ${Warning[600]};
    color: #fff;
  }

  /* outline */

  .MuiAlert-outlined {
  }

  .MuiAlert-outlinedSuccess {
    background: ${Success[50]};
  }
  .MuiAlert-outlinedError {
    background: ${Error[50]};
    /* color: ${Error[600]}; */
  }
  .MuiAlert-outlinedInfo {
    background: ${Info[50]};
  }
  .MuiAlert-outlinedWarning {
    background: ${Warning[50]};
  }
`;

export const SubNotificationWrapper = styled.div`
  background: ${(props) =>
    props.variant === "general" ? Extra.purple : Extra.yellow};
  color: ${(props) => (props.variant === "general" ? "#fff" : "#000")};
  padding: 12px 0px;

  .layout {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
  }
  p {
    text-align: center;
    width: 100%;
  }

  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    p {
      text-align: left;
      width: 100%;
    }
  }
`;

export const SubWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 112px 0px;
  flex-direction: column;
  align-items: center;
`;
