import styled from "styled-components";
import { Extra } from "../../../atoms/Colors";

export const WSWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  padding: 80px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
export const WSHeader = styled.div`
  text-align: center;
`;
export const WSCard = styled.div`
  border: 5px solid #000000;
  filter: drop-shadow(-20px -20px 0px #000000);
  border-radius: 30px;
  width: 100%;
  height: 500px;
  background: #ccc;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    width: 90%;
    height: 400px;
  }
`;
export const WSText = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  column-gap: 30px;
  .colorText {
    color: ${Extra[`purple`]};
    font-weight: 700;
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
