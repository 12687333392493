import { ReactComponent as CursorOne } from "./cursor1.svg";
import { ReactComponent as CursorOneDark } from "./cursor1dark.svg";
import { ReactComponent as CursorThree } from "./cursor3.svg";
import { ReactComponent as Blob } from "./blob.svg";
import { ReactComponent as Blob2 } from "./blob2.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Avatar } from "./nerdy_eye_avatar.svg";
import { ReactComponent as CurveLines } from "./../svg/curve-lines.svg";
import { ReactComponent as StepArrow } from "./../svg/step-arrow.svg";

export default {
  CursorOne,
  CursorOneDark,
  CursorThree,
  Blob,
  Blob2,
  Copy,
  Avatar,
  CurveLines,
  StepArrow,
};
