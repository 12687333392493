import { getCalCourseDuration, getCalProgressDuration } from "./getDuration";

export const progressPercentage = (courseLessons, progressLessons) => {
  const courseDuration = getCalCourseDuration(courseLessons);
  const progressDuration = getCalProgressDuration(progressLessons);
  let res = (progressDuration / courseDuration) * 100;
  if (res > 100) res = 100;

  return res.toFixed(0);
};

export const getLessonStatus = (progressLessons, id) => {
  //1) check found
  let found = false;
  let index;
  for (let i = 0; i < progressLessons.length; i++) {
    if (progressLessons[i].lesson_id === id) {
      found = true;
      index = i;
      break;
    }
  }
  //2) return status
  if (found) {
    return progressLessons[index].status;
  } else {
    return "not started";
  }
};

export const getLessonProgress = (progressLessons, id) => {
  //1) check found
  let found = false;
  let index;
  for (let i = 0; i < progressLessons.length; i++) {
    if (progressLessons[i].lesson_id === id) {
      found = true;
      index = i;
      break;
    }
  }
  //2) return status
  if (found) {
    return progressLessons[index].progress;
  } else {
    return 0;
  }
};

export const getLastLesson = (lessons) => {
  let index = 0;
  for (let i = 0; i < lessons.length; i++) {
    if (lessons[i].status === "in progress") {
      index = i;
      break;
    }
  }
  return index;
};

export const getLastCourseIndex = (courses) => {
  let index = 0;
  for (let i = 0; i < courses.length; i++) {
    if (courses[i].lessons_status === "in progress") {
      index = i;
      break;
    }
  }
  return index;
};

export const pathProgress = (courses) => {
  let sum = 0;

  courses.map((course, i) => {
    if (course.percent) {
      sum += Number(course.percent);
    }
  });
  let avg = Number(sum) / courses.length;
  return avg.toFixed(0);
};

export const checkAllCourseCompleted = (courses) => {
  const found = courses.some(
    (course) => course.lessons_status === "in progress"
  );
  // false: means all course in path is completed
  return found;
};
