import styled from "styled-components";
import bg from "../../../../../assets/images/courses/img10.jpg";

export const ImgWrapper = styled.div`
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: #ccc;
  position: relative;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  .blob1 {
    margin-right: -200px;
  }
  @media (max-width: 920px) {
    display: none;
  }
`;
