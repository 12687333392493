import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const Auth = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Auth;
