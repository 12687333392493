import styled from "styled-components";
import { Grey, Primary } from "../../atoms/Colors";

export const BellWrapper = styled.div`
  position: relative;
  padding-top: 5px;
  svg {
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
  }
  > .badge {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: ${Primary[500]};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1px;
    right: -3px;
  }
`;
