import Typography from "./../../../atoms/Typography/Typography";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import CareerCard from "../../../molecules/cards/CareerCard";
import { CareerGrid } from "./CareerStyle";
import { Link } from "react-router-dom";
import useFetch from "../../../../api/useFetch";
import { Slide } from "react-awesome-reveal";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import { HeadingTextWrapper } from "../bootcamp/BootcampStyle";

const Career = ({ title, desc, url }) => {
  const { data, loading, error } = useFetch(url);

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  return (
    <SectionWrapper>
      <Container>
        <Slide direction="up">
          <Typography variant="h3" weight="bold">
            {title}
          </Typography>
          <HeadingTextWrapper>
            <Typography variant="p2" weight="semiBold">
              {desc}
            </Typography>
            <Link to="/courses/search?q=&type=careerpath">Browse all</Link>
          </HeadingTextWrapper>
        </Slide>

        <CareerGrid>
          {loading ? (
            <>
              <CardSkeleton height="380px" />
              <CardSkeleton height="380px" />
              <CardSkeleton height="380px" />
            </>
          ) : (
            data.data.map((val, key) => (
              <div key={key}>
                <Link to={`/courses/career-paths/${val.slug}`}>
                  <CareerCard
                    bgImg={val.avatar.url}
                    title={val.title}
                    tutor={val.course_Ids.length}
                    courses={val.course_Ids.length}
                    desc={val.description.substr(0, 150)}
                  />
                </Link>
              </div>
            ))
          )}
          {/* {careerData.map((val, key) => (
            <div key={key}>
              <Link to="/courses/career-paths/frontend">
                <CareerCard
                  bgImg={val.bgImg}
                  title={val.title}
                  tutor={val.tutor}
                  courses={val.courses}
                  desc={val.desc}
                />
              </Link>
            </div>
          ))} */}
        </CareerGrid>
      </Container>
    </SectionWrapper>
  );
};

export default Career;
