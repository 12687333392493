import { useState } from "react";
import { Input } from "./../../../atoms/inputs/Input";
import Button from "../../../atoms/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "./../../../../axios/axios";
import Toast from "../../../molecules/toast/Toast";
import { useDispatch } from "react-redux";
import { next } from "../../../../actions/forgortpwd";

const ForgotPwdForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyType, setNotifyType] = useState("");
  const [notifyMsg, setNotifyMsg] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await axios.post("/auth/forgot-password", values);
      handleNotify("Reset OTP sent to your email", "success");
      dispatch(next({ page: 2, data: values.email }));
    } catch (error) {
      handleNotify(
        "Oops! Encountering difficulties sending you an OTP, please try again",
        "error"
      );
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      //form submittion
      handleSubmit(values);
    },
  });

  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          state={formik.touched.email && formik.errors.email && "error"}
          desc={
            formik.touched.email && formik.errors.email && formik.errors.email
          }
        />

        <Button
          label="Continue"
          color="grey"
          block={true}
          isLoading={isLoading}
          style={{ margin: "20px 0px" }}
        />
      </form>
    </div>
  );
};

export default ForgotPwdForm;
