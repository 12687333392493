import { useState } from "react";
import Button from "../../../atoms/Button/Button";
import Toast from "../../../molecules/toast/Toast";
import { useSelector, useDispatch } from "react-redux";
import { next } from "../../../../actions/forgortpwd";
import OtpInput from "react-otp-input";
import { OtpWrapper } from "../../../templates/auth/verify-email/VerifyEmailStyle";

const ConfirmOtpForm = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const mode = useSelector((state) => state.mode);

  const handleNotify = (msg) => {
    showNotify(true);
    setNotifyMsg(msg);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleSubmit = async () => {
    otp.length < 4
      ? handleNotify("Otp must be up to 4")
      : dispatch(next({ page: 3, data: otp }));
  };

  return (
    <div>
      <Toast show={notify} type="error" msg={notifyMsg} />

      <OtpWrapper mode={mode}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          shouldAutoFocus={true}
          isInputNum={true}
        />
      </OtpWrapper>

      <Button
        label="Continue"
        color="grey"
        block={true}
        style={{ margin: "20px 0px" }}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ConfirmOtpForm;
