import styled from "styled-components";
import { Extra, Grey, Success, Dark } from "../../atoms/Colors";

export const JobsCard = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: 100%;
  column-gap: 40px;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  justify-content: flex-start;
  align-items: ${(props) => (props.vertical ? "flex-start" : "center")};
  padding: 20px 20px;
  background: ${(props) =>
    props.variant === "featured"
      ? Extra[`yellow`]
      : props.variant !== "featured" && props.mode === "dark"
      ? Grey[800]
      : "#fff"};
  color: ${(props) =>
    props.variant === "featured"
      ? "#000"
      : props.variant !== "featured" && props.mode === "dark"
      ? "#fff"
      : "#000"};
  width: 100%;
  /* content block */
  border: 1px solid
    ${(props) =>
      props.variant === "featured" && props.mode === "dark"
        ? Grey[50]
        : props.variant === "featured" && props.mode === "light"
        ? "#000"
        : props.variant !== "featured" && props.mode === "dark"
        ? Grey[600]
        : Grey[100]};
  row-gap: ${(props) => (props.vertical ? "10px" : "15px")};
  border-radius: 20px;
  cursor: pointer;
  :hover {
    background: ${(props) =>
      props.variant === "featured"
        ? Extra[`yellow`]
        : props.variant !== "featured" && props.mode === "dark"
        ? Grey[700]
        : "#fff"};
    border: none;
    box-shadow: ${(props) =>
      props.mode === "dark"
        ? `0px 2px 10px ${Grey[900]}`
        : "0px 2px 36px #e8e6e6 "};
  }
  h5 {
    color: ${(props) =>
      props.variant === "featured"
        ? "#000"
        : props.variant !== "featured" && props.mode === "dark"
        ? Dark[`heading`]
        : Grey[900]};
  }
  p {
    color: ${(props) =>
      props.variant === "featured"
        ? "#000"
        : props.variant !== "featured" && props.mode === "dark"
        ? Dark[`text`]
        : Grey[500]};
  }

  @media (max-width: 768px) {
    row-gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TalentDp = styled.div`
  background: ${Grey[300]};
  border-radius: 100px;
  width: 100%;
  height: 110px;
  max-width: 110px;
  margin-bottom: 20px;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
`;
export const JobContent = styled.div`
  flex: 70%;
  @media (max-width: 768px) {
    flex: 100%;
  }
`;

export const NewJobTag = styled.span`
  color: ${Success[500]};
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
`;
export const TimeTag = styled.div`
  /* flex: 10%; */
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  justify-content: ${(props) =>
    props.vertical ? "flex-start" : "space-between"};
  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const CompanyDetail = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
`;
