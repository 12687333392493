import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../organisms/navbar/Navbar";
import Footer from "../organisms/footer/Footer";
import ScrollToTop from "../../helpers/ScrollToTop";
import GenSubAlert from "../molecules/notifications/GenSubAlert";
import useApiErrorHandler from "../../customhooks/useApiErrorHandler";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import useToast from "../../customhooks/useToast";

const Main = () => {
  let dispatch = useDispatch();
  let { showToast } = useToast();
  const apiError = useApiErrorHandler();
  // console.log(apiError);

  if (apiError) {
    // Render a fallback UI or error message for API errors
    if (apiError.response.data.data.name === "TokenExpiredError") {
      dispatch(logout());
      showToast("error", "Session expired, please login again");
      <Navigate to="/login" />;
    }
  }

  return (
    <div>
      <ScrollToTop />
      <GenSubAlert />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Main;
