import { Helmet } from "react-helmet";
import CommingSoon from "../coming-soon/ComingSoon";
import WorkForceHero from "./hero/WorkForceHero";
import WorkforceHiring from "./hiring-steps/WorkforceHiring";
import WorkforceNextHire from "./next-hire/WorkforceNextHire";
import WorkforcePrice from "./pricing/WorkforcePrice";
import WorkforceServices from "./services/WorkforceServices";
import WorkforceSteps from "./steps/WorkforceSteps";

const Workforce = () => {
  return (
    <div>
      <Helmet>
        <title> Nerdy Workforce - Nerdy Eye</title>
        <meta
          name="description"
          content="Post job openings for free and make your hiring process faster through our talored solutions. Access top-tier Tech Jobs"
        />
      </Helmet>
      <CommingSoon
        page="For Employers"
        desc="Employers can hire pre-vetted talents, list job for free or featured."
      />
      {/* <WorkForceHero />
      <WorkforceSteps />
      <WorkforceServices />
      <WorkforceHiring />
      <WorkforcePrice />
      <WorkforceNextHire /> */}
    </div>
  );
};

export default Workforce;
