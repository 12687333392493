import styled from "styled-components";
   


export const ProfileJobWrapper= styled.div`


`;


export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
 

`;


