import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import { Link } from "react-router-dom";
import Typography from "./../../../atoms/Typography/Typography";
import { Slide } from "react-awesome-reveal";

import React from "react";
import {
  BootcampGrid,
  HeadingTextWrapper,
  BootcampGridWrapper,
} from "./BootcampStyle";
import Tabs from "../../../organisms/tabs/Tabs";
import BootcampCard from "../../../molecules/cards/Bootcamp";
import { useSelector } from "react-redux";
import useFetch from "../../../../api/useFetch";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";

const OnlineCourse = () => {
  const { data, loading, error } = useFetch(
    "bootcamps/random-bootcamps?type=online&limit=3"
  );

  if (loading) {
    return (
      <>
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
      </>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  return (
    <BootcampGrid>
      {data.data.map((val, key) => (
        <div key={key}>
          <Link to={`/courses/bootcamps/${val.slug}`}>
            <BootcampCard
              bgImg={val.avatar.url}
              type={val.type}
              title={val.title}
              desc={val.description.substr(0, 150)}
            />
          </Link>
        </div>
      ))}
    </BootcampGrid>
  );
};

const PhysicalCourse = () => {
  const { data, loading, error } = useFetch(
    "bootcamps/random-bootcamps?type=physical&limit=3"
  );

  if (loading) {
    return (
      <>
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
      </>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  return (
    <BootcampGrid>
      {data.data.map((val, key) => (
        <div key={key}>
          <Link to={`/courses/bootcamps/${val.slug}`}>
            <BootcampCard
              bgImg={val.avatar.url}
              type={val.type}
              title={val.title}
              desc={val.description.substr(0, 150)}
            />
          </Link>
        </div>
      ))}
    </BootcampGrid>
  );
};

const Bootcamp = () => {
  const mode = useSelector((state) => state.mode);
  return (
    <BootcampGridWrapper mode={mode}>
      <Container>
        <Slide direction="up">
          <Typography variant="h3" weight="bold">
            Live Bootcamp
          </Typography>
          <HeadingTextWrapper>
            <Typography variant="p2" weight="semiBold">
              Kickstart your Tech Career with a live immersive Bootcamp. Join a
              micro class and Learn-By-Doing live.
            </Typography>
            <Link to="/courses/search?q=&type=bootcamps">Browse all</Link>
          </HeadingTextWrapper>
        </Slide>

        <Tabs
          data={[
            {
              label: "Online",
              component: <OnlineCourse />,
            },
            {
              label: "Physical",
              component: <PhysicalCourse />,
            },
          ]}
          mode={mode}
        />
      </Container>
    </BootcampGridWrapper>
  );
};

export default Bootcamp;
