import styled from "styled-components";
import { keyframes } from "styled-components";
import { Extra, Grey, Primary } from "../../../atoms/Colors";

export const HeroWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 56px 0px;
  /* overflow: hidden; */
  .curve-lines {
    position: absolute;
    width: 50px;
    margin-left: 50px;
  }
  @media (max-width: 520px) {
    .btn {
      width: 100%;
    }
    .curve-lines {
      margin-left: 0px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin: 56px 0px; */
  overflow: hidden;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const FormWrapper = styled.div`
  display: flex;
  input {
    height: 50px;
    border: none;
    width: 350px;
    border-bottom: 1px solid
      ${(props) => (props.mode === "light" ? Grey[50] : Grey[500])};
    background: none;
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    &:focus {
      outline: 0px;
    }
  }
  button {
    height: 50px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    input {
      margin-bottom: 24px;
    }
    button {
      /* width: 100%; */
    }
  }
`;

export const FloatingBadge = styled.div`
  position: absolute;
  top: 30px;
  padding: 5px 10px;
  background: ${(props) =>
    props.type === "connect"
      ? Extra.purple
      : props.type === "grow"
      ? Primary[500]
      : Extra.yellow};
  color: ${(props) =>
    props.type === "connect"
      ? "#fff"
      : props.type === "grow"
      ? "#fff"
      : "#000"};
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 30px;
  border: 2px solid #000;
  transform: rotate(-33.71deg);
  box-shadow: -4px -4px 0px #000000;
  &.one {
    left: 5px;
    top: 80px;
  }
  &.three {
    right: 10px;
    transform: rotate(41.73deg);
    top: 200px;
  }
  @media (max-width: 768px) {
    font-size: 10px;

    &.one {
      top: 20px;
    }
    &.three {
      /* display: none; */
      top: 20px;
    }
  }
`;

const zoom = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`;

export const VideoPlayer = styled.div`
  width: 85%;
  margin: auto;
  height: 450px;
  background: #ccc;
  border: 6px solid #000;
  box-shadow: 12px -12px #000;
  border-radius: 50px;
  margin-top: 56px;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-blend-mode: luminosity;

  .play {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${Extra.purple};
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    animation: ${zoom} 1s linear infinite alternate;
  }
`;
