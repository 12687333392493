import styled from "styled-components";
import { Primary } from "../../../../atoms/Colors";

export const VTWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 40px;
  margin-top: 32px;
  @media (max-width: 768px) {
    grid-template-columns: auto auto;
    grid-gap: 20px;
  }
`;

export const VTCard = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 12px;
  background: #ccc;
  position: relative;
  overflow: hidden;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  margin-bottom: 12px;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover > .play {
      transform: scale(1.5);
      transition: 0.5s;
    }
  }
  .play {
    color: #fff;
    background: ${Primary[500]};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }
  @media (max-width: 768px) {
    height: 250px;
  }
`;
