import { ButtonGroup } from "../../../../../atoms/Button/ButtonStyle";
import Button from "../../../../../atoms/Button/Button";
import { checkAllCourseCompleted } from "../../../../../../helpers/PlayLibrary";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useToast from "../../../../../../customhooks/useToast";
import Modal from "../../../../../molecules/modal/Modal";
import Typography from "../../../../../atoms/Typography/Typography";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Icons from "../../../../../../assets/icons";
import ReviewStars from "../../../../../molecules/review-stars/ReviewStars";
import { TextArea } from "../../../../../atoms/inputs/Input";
import { update } from "../../../../../../api/api";

const BootcampCertAndReview = ({ type, data, bootcamp, genData }) => {
  const [openReview, setOpenReview] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [star, setStar] = useState(0);
  const { showToast } = useToast();
  const [isLoadingCompletion, setIsLoadingCompletion] = useState(false);
  const [review, setReview] = useState("");
  const [reviewErr, setReviewErr] = useState("");
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  let navigate = useNavigate();
  // console.log(genData.career_path_id._id);

  const handleSubmitReview = async () => {
    // e.preventDefault();
    setIsLoadingReview(true);
    if (star < 1) {
      showToast("error", "You haven't selected a star yet");
    } else if (review === "") {
      setReviewErr("Please say something nice before submitting your review");
    } else {
      const res = await update(
        auth.token,
        `${type}/${
          type === "bootcamps" ? bootcamp._id : genData.career_path_id._id
        }/review`,
        {
          star: star,
          comments: review,
        }
      );
      if (res.status === 204) {
        showToast("success", "Review submitted successfully");
        // update state
        genData.reviewed = true;
        setOpenReview(false);
        setStar(0);
        setReview("");
      } else {
        //alert error
        showToast(
          "info",
          "We encountered an error submitting your review, please try again."
        );
      }
    }
    setIsLoadingReview(false);
  };

  //review and cert
  const handleDwnCert = () => {
    let isNotComplete = checkAllCourseCompleted(data);
    !isNotComplete && genData.reviewed
      ? navigate(
          `/profile/${type}/${
            type === "bootcamps" ? bootcamp._id : genData.career_path_id._id
          }/certificate`
        )
      : showToast(
          "info",
          "You will have to complete the career path & write a review first."
        );
  };

  const leaveReview = () => {
    let isNotComplete = checkAllCourseCompleted(data);
    const found = data.some((course) => course.course_reviewed === false);
    //check if all courses is complete and reviewed
    if (!isNotComplete && !found) {
      //open review modal
      setOpenReview(true);
    } else {
      showToast(
        "info",
        "You will have to complete and review all the courses before you can review the career path."
      );
    }
  };

  return (
    <div>
      <ButtonGroup style={{ gap: "8px" }}>
        {/* check if all courses are completed & review submited */}

        <Button
          label="Download Certificate"
          size="md"
          color="grey"
          onClick={handleDwnCert}
          block
        />

        <Button
          label="Leave Review"
          size="md"
          // onClick={() => setOpenReview(!openReview)}
          onClick={leaveReview}
          block
        />
      </ButtonGroup>

      {/* modal for completing course */}
      {isLoadingCompletion && (
        <Modal
          open={true}
          content={
            <div>
              <Typography variant="h6" weight="bold" align="center">
                <CircularProgress color="inherit" size={14} /> Please Wait...
              </Typography>
              <Typography variant="p2" align="center">
                We are checking if you have completed your course
              </Typography>
            </div>
          }
        />
      )}

      {/* modal for path review */}
      <Modal
        open={openReview}
        content={
          <>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                float: "right",
              }}
              onClick={() => setOpenReview(!openReview)}
            />
            {/* <CourseReviewForm /> */}
            <div>
              <center>
                <Icons.Avatar style={{ width: "300px" }} />
              </center>
              <Typography variant="h6" weight="bold" align="center">
                Leave a Review
              </Typography>
              <Typography variant="p2" align="center">
                How would you rate your overall learning experience in this
                Bootcamp?
              </Typography>
              <ReviewStars
                star={star}
                changeOne={() => setStar(1)}
                changeTwo={() => setStar(2)}
                changeThree={() => setStar(3)}
                changeFour={() => setStar(4)}
                changeFive={() => setStar(5)}
              />
              <TextArea
                placeholder="Say something nice here..."
                state={reviewErr ? "error" : ""}
                desc={reviewErr}
                onChange={(e) => {
                  setReview(e.target.value);
                  setReviewErr("");
                }}
              >
                {review}
              </TextArea>
              <Button
                label="Submit Review"
                isLoading={isLoadingReview}
                onClick={handleSubmitReview}
                block
              />
            </div>
          </>
        }
        onClose={() => setOpenReview(!openReview)}
      />
    </div>
  );
};

export default BootcampCertAndReview;
