import { useState, useRef } from "react";
import {
  Nav,
  NavMenu,
  NavBtnWrapper,
  MobileMenuBtn,
  MobileWrapper,
  MobileContentWrapper,
  MobileMenu,
  MobileCTA,
  NavIcons,
  MenuDropdown,
} from "./NavbarStyle";
import { Container } from "../../../helpers/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../../../assets/images/logo-main.png";
import logo2 from "./../../../assets/images/logo2.png";
import Button from "../../atoms/Button/Button";
import FeatherIcon from "feather-icons-react";
import UserMenu from "./UserMenu";
import Notification from "./Notification";
import { useSelector } from "react-redux";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import ModeSwitch from "../../atoms/mode/ModeSwitch";

function DropMenu({ mode }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <MenuDropdown mode={mode}>
      <ButtonGroup ref={anchorRef} onClick={handleToggle} className="group">
        <button>Workforce</button>
        <button>
          <FeatherIcon
            icon={open ? "chevron-up" : "chevron-down"}
            size="20"
            style={{ paddingTop: 10 }}
          />
        </button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
            className="popper"
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <Link to="/workforce/employers">
                    <div className="item" mode={mode}>
                      <FeatherIcon icon="user-plus" size={20} />
                      For Employers
                    </div>
                  </Link>

                  <Link to="/workforce/talents">
                    <div className="item">
                      <FeatherIcon icon="user-check" size={20} />
                      For Talents
                    </div>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </MenuDropdown>
  );
}

const Navbar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleMobileClick = (path) => {
    navigate(path);
    setShowMobileNav(false);
  };

  return (
    <Nav mode={mode}>
      <Container>
        <Link to="/">
          <img
            src={mode === "light" ? logo : logo2}
            width="70"
            alt="Nerdy Eye Logo"
          />
        </Link>

        <NavMenu mode={mode}>
          <ul>
            <li>
              <Link to="/courses"> Courses</Link>
            </li>
            <li>
              <Link to="/jobs"> Jobs</Link>
            </li>
            <li>
              <DropMenu mode={mode} />
            </li>
          </ul>
        </NavMenu>

        {!auth && (
          <NavBtnWrapper>
            {/* <ModeSwitch style={{ marginRight: "-10px", marginTop: "6px" }} /> */}
            <Link to="/login">
              <Button label="Sign In" variant="text" />
            </Link>
            <Link to="/create-account">
              <Button label="Sign Up" />
            </Link>
          </NavBtnWrapper>
        )}

        {!auth && (
          <MobileMenuBtn mode={mode}>
            <ModeSwitch />
            <FeatherIcon
              icon="menu"
              size={32}
              onClick={() => setShowMobileNav(!showMobileNav)}
            />
          </MobileMenuBtn>
        )}

        {auth && (
          <NavIcons>
            {/* <Notification count={10} /> */}
            <ModeSwitch />
            <UserMenu />
            <MobileMenuBtn mode={mode}>
              <FeatherIcon
                icon="menu"
                size={32}
                onClick={() => setShowMobileNav(!showMobileNav)}
              />
            </MobileMenuBtn>
          </NavIcons>
        )}
      </Container>

      <MobileWrapper
        mode={mode}
        style={{ display: `${showMobileNav ? "block" : "none"}` }}
      >
        <MobileContentWrapper>
          <FeatherIcon
            onClick={() => setShowMobileNav(!showMobileNav)}
            icon="x"
            size={32}
          />

          <MobileMenu>
            <ul>
              <li onClick={() => handleMobileClick("/courses")}>Courses</li>
              <li onClick={() => handleMobileClick("/jobs")}>Jobs</li>
              <li onClick={() => handleMobileClick("/workforce/employers")}>
                For Employers
              </li>
              <li onClick={() => handleMobileClick("/workforce/talents")}>
                For Talents
              </li>
              {/* <li>
                <DropMenu mode={mode} />
              </li> */}
            </ul>
          </MobileMenu>

          {!auth.login && (
            <MobileCTA>
              <Link to="/login">
                <Button label="Sign In" variant="text" block={true} />
              </Link>
              <Link to="/create-account">
                <Button label="Create Account" block={true} />
              </Link>
            </MobileCTA>
          )}
        </MobileContentWrapper>
      </MobileWrapper>
    </Nav>
  );
};

export default Navbar;
