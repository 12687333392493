import Typography from "../../../../atoms/Typography/Typography";
import {
  RoadmapWrapper,
  RoadmapHead,
  RoadmapTitle,
  RoadmapTitleWrapper,
  Step,
  RoadmapBody,
} from "./RoadmapStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Badge from "../../../../atoms/Badge/Badge";
import { useSelector } from "react-redux";
import { useState } from "react";
import useToast from "../../../../../customhooks/useToast";

const RoadmapList = ({ step, title, desc, active, locked, body, ...props }) => {
  const mode = useSelector((state) => state.mode);
  const [expand, setExpand] = useState(false);
  const { showToast } = useToast();

  const handleExpand = () => {
    locked
      ? showToast("info", "Complete previous roadmap to unlock")
      : setExpand(!expand);
  };

  return (
    <div>
      <RoadmapWrapper locked={locked} {...props}>
        <RoadmapHead mode={mode} onClick={handleExpand}>
          <RoadmapTitleWrapper>
            <Step className={active && "active"}>{step}</Step>
            <RoadmapTitle>
              {locked && (
                <Badge style={{ width: "60px", display: "flex", gap: 4 }}>
                  <FeatherIcon icon="lock" size={14} />
                  locked
                </Badge>
              )}

              <Typography variant="h6" weight="bold">
                {title}
              </Typography>
              <Typography variant="p3">{desc}</Typography>
            </RoadmapTitle>
          </RoadmapTitleWrapper>

          <FeatherIcon icon={`chevron-${expand ? "up" : "down"}`} />
        </RoadmapHead>

        <RoadmapBody expand={expand}>{body}</RoadmapBody>
      </RoadmapWrapper>
    </div>
  );
};

export default RoadmapList;
