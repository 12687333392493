import { useSelector } from "react-redux";
import { Label, Desc } from "../../atoms/inputs/InputStyle";
import { FileField, FileName } from "./FileDropZoneStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Typography from "../../atoms/Typography/Typography";

const FileDropZone = ({ label, desc, state, filename, children, ...props }) => {
  const mode = useSelector((state) => state.mode);
  return (
    <div>
      <Label mode={mode}>{label} </Label>
      <FileField mode={mode} state={state} {...props}>
        {children}
        <FeatherIcon icon="upload" size="39" />
        {filename !== "" ? (
          <FileName>{filename}</FileName>
        ) : (
          <>
            <Typography variant="p3" weight="semi-bold">
              Drag & drop here
            </Typography>
            <Typography variant="p3" weight="semi-bold">
              or
            </Typography>
            <Typography variant="p3" weight="semi-bold">
              Browse file
            </Typography>
          </>
        )}
      </FileField>
      <Desc state={state}>{desc}</Desc>
    </div>
  );
};

export default FileDropZone;
