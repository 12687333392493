import { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { TableWrapper } from "./TableStyle";
// import { Checkbox } from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Grey } from "../../atoms/Colors";
import { useSelector } from "react-redux";

createTheme("custom", {
  text: {
    primary: Grey[600],
    secondary: Grey[500],
  },
  background: {
    default: "#ffffff",
    borderRadius: "30px",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: Grey[50],
  },
  button: {
    default: Grey[600],
    hover: "rgba(0,0,0,.08)",
    focus: "rgba(255,255,255,.12)",
    disabled: "rgba(255, 255, 255, .34)",
  },
  sortFocus: {
    default: Grey[600],
  },
});

const sortIcon = <FeatherIcon icon="chevron-down" />;
// const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

const Table = ({ data, columns, ...props }) => {
  const mode = useSelector((state) => state.mode);

  let dataKeys = Object.keys(data[0]);
  let filterKey = dataKeys[0];

  const [filterText, setFilterText] = useState("");
  const filteredItems = data.filter(
    (item) =>
      item[filterKey] &&
      item[filterKey].toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <TableWrapper mode={mode}>
      <input
        placeholder="search"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
        className="src"
      />

      <DataTable
        data={filteredItems}
        columns={columns}
        sortIcon={sortIcon}
        pagination
        // selectableRowsComponent={Checkbox}
        // selectableRowsComponentProps={selectProps}
        // selectableRows
        responsive
        theme="custom"
        style={{ borderRadius: "50px" }}
        {...props}
      />
    </TableWrapper>
  );
};

export default Table;
