import AuthStructure from "../../../organisms/auth/AuthStructure";
import { useNavigate, Link, Navigate } from "react-router-dom";
import img from "./../../../../assets/images/auth/login.jpg";
import { Back } from "../../../organisms/auth/AuthStructureStyle";
import FeatherIcon from "feather-icons-react";
import Typography from "./../../../atoms/Typography/Typography";
import { useSelector } from "react-redux";
import ForgotPwdForm from "../../../organisms/forms/forgotpwd/ForgotPwdForm";
import ConfirmOtpForm from "../../../organisms/forms/forgotpwd/ConfirmOtpForm";
import ResetPwdForm from "../../../organisms/forms/forgotpwd/ResetPwdForm";

const MainContent = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const getfPwd = useSelector((state) => state.fPwd);

  const GetOtp = () => {
    return (
      <div>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: 50 }}
        >
          Forgot Password
        </Typography>
        <Typography variant="p3" align="center">
          Enter email address, an OTP code will be sent to your email.
        </Typography>

        <ForgotPwdForm />

        <Typography
          variant="p3"
          align="center"
          weight="semi-bold"
          style={{ marginTop: 20 }}
        >
          Know your password? <Link to="/login">Log in</Link>
        </Typography>
      </div>
    );
  };

  const ConfirmOtp = () => {
    return (
      <div>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: 50 }}
        >
          Enter OTP Code
        </Typography>
        <Typography variant="p3" align="center">
          Enter 4 digits code sent to your email.
        </Typography>

        <ConfirmOtpForm />

        <Typography
          variant="p3"
          align="center"
          weight="semi-bold"
          style={{ marginTop: 20 }}
        >
          Didn't recieve code? <Link to="/login">Resend it</Link>
        </Typography>
      </div>
    );
  };

  const ResetPwd = () => {
    return (
      <div>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: 50 }}
        >
          Reset Password
        </Typography>
        <Typography variant="p3" align="center">
          Enter new password
        </Typography>

        <ResetPwdForm />
      </div>
    );
  };

  if (auth) {
    return <Navigate to="/profile" />;
  }
  return (
    <>
      <Back onClick={() => navigate(-1)}>
        <FeatherIcon icon="arrow-left" />
      </Back>

      {getfPwd.page === 1 ? (
        <GetOtp />
      ) : getfPwd.page === 2 ? (
        <ConfirmOtp />
      ) : (
        <ResetPwd />
      )}
    </>
  );
};

const ForgotPwd = () => {
  return <AuthStructure main={<MainContent />} bg={img} />;
};

export default ForgotPwd;
