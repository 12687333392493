import styled from "styled-components";
import { Dark, Grey } from "../../../atoms/Colors";

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  @media (max-width: 768px) {
    > div {
      width: 100%;
    }
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InterestWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  /* justify-content: center; */
  /* margin: 40px 0px; */
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const FormGroup = styled.div`
  padding: 16px;
  .rti--container {
    margin-top: 10px;
    border: 0px;
    background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
    min-height: 35px;
    border-radius: 25px;
    padding: 15px 15px;
  }
  .rti--input {
    background: none;
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    ::placeholder {
      color: ${Grey[500]};
    }
  }
  .rti--tag {
    background: ${Grey[100]};
    color: ${Grey[900]};
    > button {
      color: ${Grey[900]};
    }
  }
`;

export const PrevResume = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 5px;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
  svg {
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
  }
`;
