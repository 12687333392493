import React from "react";
import { Container, SectionWrapper } from "../../../../../helpers/GlobalStyle";
import styled from "styled-components";
import { Extra } from "../../../../atoms/Colors";
import Typography from "../../../../atoms/Typography/Typography";

const AdvisorWrapper = styled.div`
  background: ${Extra.purple};
  padding: 50px;
  border-radius: 30px;
`;

const Advisor = ({ padding, title, desc, CTA }) => {
  return (
    <div>
      <SectionWrapper style={{ padding: padding }}>
        <Container>
          <AdvisorWrapper>
            <Typography
              variant="h3"
              align="center"
              weight="bold"
              style={{ color: "#fff", textTransform: "capitalize" }}
            >
              {title}
            </Typography>
            <Typography
              variant="p1"
              align="center"
              weight="semi-bold"
              style={{ color: "#fff" }}
            >
              {desc}
            </Typography>

            <center>{CTA}</center>
          </AdvisorWrapper>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Advisor;
