import styled from "styled-components";
import { Extra } from "../../../../atoms/Colors";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* height: 600px; */
  background: #ccc;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  overflow: hidden;
  .overlay {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(
      196.52deg,
      rgba(58, 58, 58, 0) 11.44%,
      #000000 89.79%
    );

    top: 0px;
    padding: 180px 0px;
    /* padding-top: 180px; */
  }
  .bootcamps {
    background-image: linear-gradient(
      196.52deg,
      rgba(58, 58, 58, 0) 11.44%,
      #100351 89.79%
    );
  }
  h2 {
    color: #fff;
    margin-bottom: 20px;
  }
  .title {
    width: 100%;
    max-width: 728px;
  }
  .cta {
    margin-right: 20px;
    margin-bottom: 10px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InfoList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  p {
    color: #fff;
    margin: 0px;
  }
`;

export const CountDownWrapper = styled.div`
  background: ${Extra.purple};
  color: #fff;
  padding: 32px 0px;
  p {
    color: #fff;
  }
  .holder {
    max-width: 480px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .count {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .num {
    font-size: 32px;
    font-weight: 700;
  }
`;
