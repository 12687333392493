const dataPersistReducer = (state = false, action) => {
  switch (action.type) {
    case "courses":
      return {
        courses: { status: true, data: action.payload },
      };
    case "career-path":
      return {
        career_path: { status: true, data: action.payload },
      };
    default:
      return state;
  }
};

export default dataPersistReducer;
