import React from "react";
import Typography from "../../../atoms/Typography/Typography";
import { Container } from "../../../../helpers/GlobalStyle";
import Button from "../../../atoms/Button/Button";

import { TitleWrapper, ProfileJobWrapper } from "./JobsStyles";
import ProfileJobCard from "../../../molecules/cards/ProfileJobCard";

const jobCardData = [
  {
    title: "Graphics Design Instructor",
    details1: "34 candidates",
    details2: "10 Read",
    details3: "24 Unread",
    details4: "2 Shortlisted",
    details5: "2 Hired",
    expert: "Expert",
    remote: "Remote",
    time: "1d ago",
  },
  {
    title: "Graphics Design Instructor",
    details1: "34 candidates",
    details2: "10 Read",
    details3: "24 Unread",
    details4: "2 Shortlisted",
    details5: "2 Hired",
    expert: "Expert",
    remote: "Remote",
    time: "1d ago",
  },
  {
    title: "Graphics Design Instructor",
    details1: "34 candidates",
    details2: "10 Read",
    details3: "24 Unread",
    details4: "2 Shortlisted",
    details5: "2 Hired",
    expert: "Expert",
    remote: "Remote",
    time: "1d ago",
  },
  {
    title: "Graphics Design Instructor",
    details1: "34 candidates",
    details2: "10 Read",
    details3: "24 Unread",
    details4: "2 Shortlisted",
    details5: "2 Hired",
    expert: "Expert",
    remote: "Remote",
    time: "1d ago",
  },
  {
    title: "Graphics Design Instructor",
    details1: "34 candidates",
    details2: "10 Read",
    details3: "24 Unread",
    details4: "2 Shortlisted",
    details5: "2 Hired",
    expert: "Expert",
    remote: "Remote",
    time: "1d ago",
  },
];

const UserJobs = () => {
  return (
    <ProfileJobWrapper>
      <Container>
        <TitleWrapper>
          <Typography variant="h3" weight="bold">
            Jobs
          </Typography>
          <Button label="Post Jobs" className="btn_title" />
        </TitleWrapper>

        {jobCardData.map((val, key) => {
          return (
            <ProfileJobCard
              title={val.title}
              details1={val.details1}
              details2={val.details2}
              details3={val.details3}
              details4={val.details4}
              details5={val.details5}
              expert={val.expert}
              remote={val.remote}
              time={val.time}
            />
          );
        })}
      </Container>
    </ProfileJobWrapper>
  );
};

export default UserJobs;
