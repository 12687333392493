import styled from "styled-components";
import { Dark, Grey } from "../../../atoms/Colors";

export const BootcampGridWrapper = styled.div`
  width: 100%;
  /* background: ${(props) =>
    props.mode === "light" ? Grey[50] : Dark.bgCard}; */
  padding: 46px 0px;
`;

export const BootcampWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 30px;
  background-color: #ccc;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  .desc {
    height: 0px;
    transition: 0.5s;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  &:hover {
    .desc {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      transition: 0.5s;
      height: 50px;
      text-overflow: ellipsis;
    }
    .bg {
      transform: scale(1.5);
    }
  }
`;

export const BootcampGrid = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    gap: 16px;
    > div {
      min-width: 85%;
    }
  }
`;

export const BootcampSpan = styled.span`
  font-size: 14px;
  font-weight: 200;
`;

export const HeadingTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 50px;
  margin-bottom: -30px;
  a {
    font-weight: 500;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;
