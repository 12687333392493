import { useState } from "react";
import { useSelector } from "react-redux";
import LearningTabs from "../../courses/LearningTabs";
import RoadmapList from "./RoadmapList";

const Roadmap = () => {
  return (
    <>
      <RoadmapList
        step="1"
        title="Learn"
        desc="First thing to a successful career is to learn"
        active={true}
        body={<LearningTabs />}
      />

      <RoadmapList
        step="2"
        title="Portfolio Projects"
        desc="Project ideas for your portfolio"
        locked={true}
        body="portfolio"
      />

      <RoadmapList
        step="3"
        title="Job Application"
        desc="Resume/CV templates & jobs you have applied for"
        locked={true}
        body="job application"
      />
    </>
  );
};

export default Roadmap;
