import Typography from "../../../atoms/Typography/Typography";
import { Extra, Success } from "../../../atoms/Colors";
// import { Slide } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  SectionWrapper,
  Row,
} from "../../../../helpers/GlobalStyle";
import { ImgHolder } from "./InstructorsStyle";
import img from "./../../../../assets/images/students.jpg";
import Button from "../../../atoms/Button/Button";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const list = [
  "Learn with a community & not alone",
  "Schedule clarity with your teacher",
  "Get accountability or learning partner",
  "Build strong rapport & make new friends",
  "Certificate on completion",
];

const Students = () => {
  return (
    <SectionWrapper>
      <Container>
        <Row className="reverse" style={{ alignItems: "center", gap: 40 }}>
          <Slide direction="left">
            <div>
              <Typography variant="p2" style={{ color: Extra.yellow }}>
                For Students or Trybe Members
              </Typography>
              <Typography variant="h3" style={{ textTransform: "capitalize" }}>
                best place to learn in a{" "}
                <span style={{ color: Extra.purple }}>Community</span> from
                creators, educators or coaches
              </Typography>
              <Typography variant="p2">
                Learning a new skill online alone suck, join a Learning Trybe to
                learn & grow with a family.
              </Typography>
              {list.map((val, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color={Success[500]} />
                  <Typography variant="p2" style={{ margin: 10 }}>
                    {val}
                  </Typography>
                </div>
              ))}
              <Link to="create-account">
                <Button
                  label="Get Started For Free"
                  style={{ marginTop: 20 }}
                />
              </Link>
            </div>
          </Slide>

          <div style={{ width: "100%" }}>
            <ImgHolder bg={img} />
          </div>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default Students;
