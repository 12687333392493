import React from "react";
import { Link } from "react-router-dom";
import FrameImg1 from "../../../../assets/images/courses/img5.png";
import {
  CircleDash,
  Content,
  CourseList,
  CursorCard,
  FormWrapper,
  Frame1,
  Frame2,
  HeroWrapper,
  OvalDash,
  Wrapper,
} from "./HeroStyle";
import { Container } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { Slide } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import {
  ParagraphThree,
  SmallOne,
} from "../../../atoms/Typography/TypographyStyle";
import Icons from "../../../../assets/icons";
import sec from "../../../../assets/icons/cursor3.svg";
import secDark from "../../../../assets/icons/cursor3dark.svg";

const Hero = () => {
  const mode = useSelector((state) => state.mode);

  return (
    <HeroWrapper>
      <Container>
        <Wrapper>
          <CircleDash />

          {mode === "light" ? (
            <Icons.CursorOne className="cursor-one" />
          ) : (
            <Icons.CursorOneDark className="cursor-one" />
          )}

          <Content>
            <Slide direction="up">
              <Typography variant="h2" weight="bold" align="center">
                {/* learn from industry experts & professionals */}
                start your career in tech & be on the fast track to a high
                paying tech job
              </Typography>
              <Typography variant="p2" weight="semi-bold" align="center">
                {/* Project based Courses & Bootcamps taught by practicing */}
                {/* professionals */}
                Learn, get mentored, build your portfolio & get a job
              </Typography>
            </Slide>
            <FormWrapper mode={mode}>
              <form action="courses/search">
                <input
                  id="q"
                  aria-label="Search Courses"
                  placeholder="Search Courses"
                  type="search"
                  name="q"
                />
              </form>
            </FormWrapper>
            <CourseList mode={mode}>
              <span>Might be Interested in:</span>
              <ul>
                {/* <li>
                  <Link to="/courses/search?type=bootcamps&mode=physical">
                    Physical Bootcamp
                  </Link>
                </li> */}
                <li>
                  <Link to="/courses/search?type=bootcamps&mode=online">
                    Online Bootcamp
                  </Link>
                </li>
                <li>
                  <Link to="/courses/search?type=careerpath">Career Path</Link>
                </li>
                <li>
                  <Link to="/courses/search?type=courses">Courses</Link>
                </li>
              </ul>
            </CourseList>
          </Content>
          {mode === "light" ? (
            <img src={sec} className="cursor-two" />
          ) : (
            <img src={secDark} className="cursor-two" />
          )}

          <OvalDash />
        </Wrapper>
      </Container>
    </HeroWrapper>
  );
};

export default Hero;
