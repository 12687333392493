import AuthStructure from "../../../organisms/auth/AuthStructure";
import { useNavigate } from "react-router-dom";
import img from "./../../../../assets/images/auth/login.jpg";
import Typography from "./../../../atoms/Typography/Typography";
import Button from "../../../atoms/Button/Button";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  AvatarHolder,
  Progress,
  SinglePicker,
  InterestWrapper,
  InterestBadge,
} from "./OnboardingStyle";
import { Back } from "../../../organisms/auth/AuthStructureStyle";
import FeatherIcon from "feather-icons-react";
import axios from "./../../../../axios/axios";
import Toast from "../../../molecules/toast/Toast";

const profList = [
  "Developer",
  "Content Creator",
  "Product Designer",
  "Community Manager",
  "Graphics Designer",
  "Digital Marketer",
  "Founder/Entrepreneur",
  "Writer/Copywriter",
  "Tech Enthusiast",
];

const interestList = [
  "community management",
  "communities",
  "project management",
  "startup",
  "content creation",
  "business & finance",
  "product design",
  "mobile app dev",
  "data science",
  "digital marketing",
  "graphics design",
  "web development",
  "growth hacking",
  "job opportunities",
  "internships",
  "networking",
  "talent recruiting",
  "NFT",
  "crypto currencies",
  "web 3.0",
  "portfolio building",
  "up-skilling",
  "brand building",
  "Entrepreneur",
  "tech events",
];

const MainContent = () => {
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [progress, setProgress] = useState(1);
  const [userProf, setUserProf] = useState("");
  const [userGoal, setUserGoal] = useState("");
  const [userInterest, setUserInterest] = useState([]);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");

  const handleNotify = (msg) => {
    showNotify(true);
    setNotifyMsg(msg);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const UploadProfile = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [imagePreview, setImagePreview] = useState("");
    const [img, setimg] = useState(null);

    const filePicekerRef = useRef(null);

    function previewFile(e) {
      // Reading New File (open file Picker Box)
      const reader = new FileReader();
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        reader.readAsDataURL(selectedFile);
      }
      // As the File loaded then set the stage as per the file type
      reader.onload = (readerEvent) => {
        setImagePreview(readerEvent.target.result);
      };
      setimg(e.target.files[0]);
    }

    const handleSubmit = async () => {
      if (imagePreview === "") {
        handleNotify("Select profile image first");
      } else {
        setIsUploading(true);
        try {
          const formData = new FormData();
          formData.append("avatar", img);
          const res = await axios.patch("/users/upload-avatar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.token}`,
            },
          });
          setProgress(2);
        } catch (err) {
          // console.log(err);
          if (err === undefined) {
            setProgress(2);
          } else {
            console.log(err);
            handleNotify(
              "Oops! encountered error while uploading, please try again"
            );
          }
        }
        setIsUploading(false);
      }
    };

    return (
      <>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: "50px" }}
        >
          Upload a profile picture
        </Typography>
        <Typography variant="p3" align="center">
          Have a favourite selfie? Click the avater below to upload it now
        </Typography>

        <AvatarHolder
          onClick={() => filePicekerRef.current.click()}
          bg={imagePreview}
          mode={mode}
        ></AvatarHolder>

        <input
          ref={filePicekerRef}
          accept="image/*, video/*"
          onChange={previewFile}
          type="file"
          hidden
        />

        <Button
          label={isUploading ? "Uploading..." : "Next"}
          color="grey"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={handleSubmit}
          isLoading={isUploading}
        />
        <Button
          label="Skip"
          color="grey"
          variant="text"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={() => setProgress(2)}
        />
      </>
    );
  };

  const Profession = () => {
    const handleProfession = () => {
      if (userProf === "") {
        handleNotify("Select your profession first");
      } else {
        setProgress(3);
      }
    };

    return (
      <>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: "50px" }}
        >
          What do you do?
        </Typography>
        <Typography variant="p3" align="center">
          What best describe your profession or the career path you will love to
          pursue
        </Typography>

        {profList.map((val, key) => (
          <SinglePicker
            key={key}
            className={userProf === val && "selected"}
            onClick={() => setUserProf(val)}
            mode={mode}
          >
            I am a {val}
          </SinglePicker>
        ))}
        <SinglePicker
          className={userProf === "others" && "selected"}
          onClick={() => setUserProf("others")}
          mode={mode}
        >
          Others
        </SinglePicker>

        <Button
          label="Next"
          color="grey"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={handleProfession}
        />
        <Button
          label="Skip"
          color="grey"
          variant="text"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={() => setProgress(3)}
        />
      </>
    );
  };

  const Goal = () => {
    const handleGoal = () => {
      if (userGoal === "") {
        handleNotify("Select your goal first");
      } else {
        setProgress(4);
      }
    };

    return (
      <>
        <Back onClick={() => setProgress(2)}>
          <FeatherIcon icon="arrow-left" />
        </Back>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: "50px" }}
        >
          Your goals
        </Typography>
        <Typography variant="p3" align="center">
          Which of the following best describes what you will love to achieve
          here 😁
        </Typography>

        <SinglePicker
          className={userGoal === "join community" && "selected"}
          onClick={() => setUserGoal("join community")}
          mode={mode}
        >
          Find & join a community
        </SinglePicker>
        <SinglePicker
          className={userGoal === "learn" && "selected"}
          onClick={() => setUserGoal("learn")}
          mode={mode}
        >
          Learn a tech skill
        </SinglePicker>
        <SinglePicker
          className={userGoal === "create community" && "selected"}
          onClick={() => setUserGoal("create community")}
          mode={mode}
        >
          Create/Host my community
        </SinglePicker>
        <SinglePicker
          className={userGoal === "job" && "selected"}
          onClick={() => setUserGoal("job")}
          mode={mode}
        >
          Get a job/internship
        </SinglePicker>
        <SinglePicker
          className={userGoal === "others" && "selected"}
          onClick={() => setUserGoal("others")}
          mode={mode}
        >
          Others
        </SinglePicker>

        <Button
          label="Next"
          color="grey"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={handleGoal}
        />
        <Button
          label="Skip"
          color="grey"
          variant="text"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={() => setProgress(4)}
        />
      </>
    );
  };

  const Interest = () => {
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleInterestCLick = (e, val) => {
      e.target.classList.toggle("selected");

      const picked = userInterest.includes(val);
      if (picked) {
        //remove
        setCount(count - 1);
        const index = userInterest.indexOf(val);
        if (index > -1) {
          userInterest.splice(index, 1);
        }
      } else {
        //add
        setCount(count + 1);
        userInterest.push(val);
      }
    };

    const goBack = () => {
      setCount(0);
      setProgress(3);
      setUserInterest([]);
    };

    const handleOnboarding = async () => {
      setIsLoading(true);
      try {
        const res = await axios.patch(
          "/users/onboarding",
          {
            profession: userProf,
            goal: userGoal,
            interest: userInterest,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        navigate("/subscription");
      } catch (err) {
        handleNotify("Unexpected error, please refresh and try again.");
      }
      setIsLoading(false);
    };

    return (
      <>
        <Back onClick={goBack}>
          <FeatherIcon icon="arrow-left" />
        </Back>
        <Typography
          variant="h3"
          align="center"
          weight="bold"
          style={{ marginTop: "50px" }}
        >
          What are you interested in?
        </Typography>
        <Typography variant="p3" align="center">
          Select atleast 3 interest to continue
        </Typography>

        <InterestWrapper>
          {interestList.map((val, key) => (
            <InterestBadge
              key={key}
              onClick={(e) => handleInterestCLick(e, val)}
              mode={mode}
            >
              {val}
            </InterestBadge>
          ))}
        </InterestWrapper>

        <Button
          label="Finish"
          color="grey"
          block={true}
          style={{ margin: "20px 0px" }}
          onClick={handleOnboarding}
          disabled={count < 3 ? true : false}
          isLoading={isLoading}
        />
      </>
    );
  };

  return (
    <>
      <Progress mode={mode}>
        <span className={progress >= 1 && "completed"}></span>
        <span className={progress >= 2 && "completed"}></span>
        <span className={progress >= 3 && "completed"}></span>
        <span className={progress === 4 && "completed"}></span>
      </Progress>

      <Toast show={notify} type="error" msg={notifyMsg} />

      {progress === 1 ? (
        <UploadProfile />
      ) : progress === 2 ? (
        <Profession />
      ) : progress === 3 ? (
        <Goal />
      ) : (
        <Interest />
      )}
    </>
  );
};

const Onboarding = () => {
  return <AuthStructure main={<MainContent />} bg={img} />;
};

export default Onboarding;
