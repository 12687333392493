export const courses = (payload = null) => {
  return {
    type: "courses",
    payload,
  };
};

export const careerPath = (payload = null) => {
  return {
    type: "career-path",
    payload,
  };
};
