import styled from "styled-components";

export const ImgHolder = styled.div`
  width: 80%;
  border-radius: 306px 306px 40px 40px;
  height: 520px;
  border: 4px solid #000;
  background: url(${(props) => props.bg}), lightgray 50% / cover no-repeat;
  background-position: center;
  box-shadow: 12px 12px 0px 0px #000;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
/*
dashed generator from:
https://kovart.github.io/dashed-border-generator/
*/
export const CircleDash = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -40px;
  margin-left: -50px;
  animation: spin 5s linear infinite;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%237B61FFFF' stroke-width='3' stroke-dasharray='8%2c 10%2c 3%2c 11' stroke-dashoffset='59' stroke-linecap='butt'/%3e%3c/svg%3e");
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
