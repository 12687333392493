export const login = (login, token, user) => {
  return {
    type: "login",
    login,
    token,
    user,
  };
};

export const logout = () => {
  return {
    type: "logout",
  };
};
