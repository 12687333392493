import Typography from "./../../../atoms/Typography/Typography";
import { Slide } from "react-awesome-reveal";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import { InstructorGrid, SubText } from "./InstructorStyle";
import InstructorCards from "../../../molecules/cards/InstructorCards";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import useFetch from "../../../../api/useFetch";
import { useDispatch } from "react-redux";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import { useEffect, useState } from "react";

const PathInstructor = ({ title, desc, align, data, subHeading, mode }) => {
  let dispatch = useDispatch();
  const [shuffleData, setShuffleData] = useState([]);

  const shuffle = () => {
    let random = data
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    setShuffleData(random);
  };

  useEffect(() => {
    shuffle();
  }, []);

  return (
    <SectionWrapper>
      <Container>
        <Slide direction="up">
          <Typography variant="p2" align={align}>
            {subHeading}
          </Typography>
          <Typography variant="h3" weight="bold" align={align}>
            {title}
          </Typography>
          <Typography variant="p3" style={{ marginBottom: "20px" }}>
            {desc}
          </Typography>
        </Slide>

        <InstructorGrid>
          {shuffleData.map((val, key) => (
            <div key={key}>
              <InstructorCards
                bgImg={val.avatar}
                name={val.name}
                jobTitle={val.profession}
              />
            </div>
          ))}
        </InstructorGrid>
      </Container>
    </SectionWrapper>
  );
};

export default PathInstructor;
