import { useParams } from "react-router-dom";
import Overview from "./details/Overview";
import Gain from "./gain/Gain";
import CourseHero from "./hero/CourseHero";
import { Dark, Grey } from "../../../atoms/Colors";
import Faq from "./faq/Faq";
import Testimonials from "../../../organisms/testimonials/Testimonials";
import CourseGrid from "../courses/CourseGrid";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import { useSelector } from "react-redux";
import useFetch from "../../../../api/useFetch";
import CourseInstructor from "../instructor/CourseInstructor";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import { getCourseDuration } from "../../../../helpers/getDuration";
import { Helmet } from "react-helmet";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";

const Course = () => {
  let { url } = useParams();
  const mode = useSelector((state) => state.mode);
  const { data, loading, error } = useFetch(`courses/${url}`);

  if (loading) {
    return (
      <div>
        <CardSkeleton
          style={{ width: "100%", borderRadius: "0px", height: "500px" }}
          mode={mode}
        />
        <Container>
          <CardSkeleton
            variant="text"
            style={{
              width: "200px",
              borderRadius: "10px",
              marginTop: "56px",
              height: "50px",
            }}
            mode={mode}
          />

          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
              marginBottom: "56px",
            }}
            mode={mode}
          />
        </Container>
      </div>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  return (
    <div>
      <TestimonyPopup />
      <Helmet>
        <title> {data.data.title} Course - Nerdy Eye</title>
        <meta
          name="description"
          content={`Learn ${data.data.title}, Get Mentored and be on a fast track to a high paying tech job`}
        />
        <meta name="image" content={data.data.avatar && data.data.avatar.url} />
      </Helmet>
      <CourseHero
        title={data.data.title}
        content={`${data.data.lessons ? data.data.lessons.length : 0} Lessons`}
        badgeText={data.data.paid ? "Paid" : "Free"}
        time={data.data.lessons ? getCourseDuration(data.data.lessons) : 0}
        certificate={true}
        bg={data.data.avatar && data.data.avatar.url}
        trailer={data.data.trailer_url}
        id={data.data._id}
        type="courses"
        slug={data.data.slug}
      />
      <Overview
        title="course"
        desc={data.data.description}
        content={`${data.data.lessons ? data.data.lessons.length : 0} Lessons`}
        lessons={data.data.lessons ? data.data.lessons : []}
        type="courses"
        mode={mode}
      />
      <Gain desc={data.data.why_desc} list={data.data.why_list} />
      <div
        style={{
          background: mode === "light" ? Grey[50] : Dark.bgCard,
          marginTop: "100px",
        }}
      >
        <CourseInstructor
          mode={mode}
          bgImg={data.data.instructorID.avatar.url}
          name={data.data.instructorID.name}
          jobTitle={data.data.instructorID.profession}
          bio={data.data.instructorID.bio}
        />
      </div>

      {/* <Testimonials
        title="Reviews from learners"
        desc="What other students are saying about this course"
        data={data.data.reviews ? data.data.reviews : []}
      /> */}
      <Faq mode={mode} />
      <SectionWrapper>
        <CourseGrid
          title="Related Courses"
          desc="Get access to project based courses and learn by doing"
          url={`/courses/related-courses/${data.data._id}?limit=4`}
        />
      </SectionWrapper>
    </div>
  );
};

export default Course;
