import React from "react";
import { Link } from "react-router-dom";
import Badge from "../../atoms/Badge/Badge";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { SubWrapper } from "./NotificationStyle";
import { Extra } from "../../atoms/Colors";

const SubscriptionNotification = ({ url, status, desc, type, group }) => {
  return (
    <>
      <SubWrapper>
        <Typography variant="h3" align="center" weight="bold">
          {type === "batch" ? status : "Oops!"}
        </Typography>
        {type !== "batch" && (
          <Badge
            variant="primary"
            style={{ marginTop: 20, textTransform: "capitalize" }}
          >
            Subscription Status: {status}
          </Badge>
        )}

        <Typography variant="p1" align="center">
          {desc}
        </Typography>
        {type !== "batch" ? (
          <Link to={url}>
            <Button label="Subscribe Now" />
          </Link>
        ) : (
          <center>
            <a href={group} target="_blank">
              <Button
                style={{
                  background: Extra.purple,
                }}
                label="Join Support Group"
              />
            </a>
          </center>
        )}
      </SubWrapper>
    </>
  );
};

export default SubscriptionNotification;
