import styled from "styled-components";
import { Dark, Grey, Primary } from "../../atoms/Colors";

export const TabWrapper = styled.div`
  color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.heading)};

  .Mui-selected {
    color: ${Primary[500]};
  }
  .MuiTab-root {
    font-family: "montserrat";
    text-transform: capitalize;
    font-size: 16px;
  }
  .MuiTabs-indicator {
    background-color: ${Primary[500]};
  }
  .MuiBox-root {
    border-color: ${(props) =>
      props.mode === "light" ? Grey[200] : Grey[600]};
    padding: 20px 0px 0px 0px;
  }
  &.courses {
    .badge {
      background: ${Grey[600]};
      color: ${Grey[50]};
      padding: 5px;
      width: 25px;
      height: 25px;
      font-size: 12px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .Mui-selected {
      color: ${(props) =>
        props.mode === "light" ? Grey[900] : Grey[50]} !important;
      font-weight: 600;
      .badge {
        background: ${Primary[500]};
      }
    }
  }
`;
