import styled from "styled-components";
import { Primary, Grey, Dark } from "../../atoms/Colors";

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > span {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: -10px;
  }
`;

export const Profile = styled.div`
  width: 30px;
  height: 30px;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  background-position: center;
`;

export const MenuDropdown = styled.div`
  position: absolute;
  top: 40px;
  right: 0px;
  width: 220px;
  height: auto;
  background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bg)};
  border-radius: 20px;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  z-index: 1000;
`;

export const MenuItemWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-transform: capitalize;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[50])};
  &:hover {
    color: ${Primary[500]};
    background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[800])};
    cursor: pointer;
  }
  > svg {
    margin-right: 10px;
  }
`;
