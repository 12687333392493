import Typography from "./../../../atoms/Typography/Typography";
import { Slide } from "react-awesome-reveal";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import { InstructorGrid, SubText } from "./InstructorStyle";
import InstructorCards from "../../../molecules/cards/InstructorCards";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import useFetch from "../../../../api/useFetch";
import { useDispatch } from "react-redux";
import ErrorApiPage from "../../../pages/ErrorApiPage";

const Instructor = ({ title, desc, align, subHeading, mode }) => {
  let dispatch = useDispatch();
  const { data, loading, error } = useFetch(
    "courses/random-courses?type=instructor"
  );
  if (error) {
    return <ErrorApiPage />;
  }

  return (
    <SectionWrapper>
      <Container>
        <Slide direction="up">
          <Typography variant="p2" align={align}>
            {subHeading}
          </Typography>
          <Typography variant="h3" weight="bold" align={align}>
            {title}
          </Typography>
          <Typography variant="p3" style={{ marginBottom: "20px" }}>
            {desc}
          </Typography>
        </Slide>
        {loading ? (
          <InstructorGrid>
            <CardSkeleton mode={mode} height="286px" />
            <CardSkeleton mode={mode} height="286px" />
            <CardSkeleton mode={mode} height="286px" />
            <CardSkeleton mode={mode} height="286px" />
          </InstructorGrid>
        ) : (
          <InstructorGrid>
            {data.data.map((val, key) => (
              <div key={key}>
                <InstructorCards
                  bgImg={val.avatar.url}
                  name={val.name}
                  jobTitle={val.profession}
                />
              </div>
            ))}
          </InstructorGrid>
        )}
      </Container>
    </SectionWrapper>
  );
};

export default Instructor;
