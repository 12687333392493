import React from "react";
import { Container, Row } from "../../../../../helpers/GlobalStyle";
import { SectionWrapper } from "../../../../../helpers/GlobalStyle";
import img from "./../../../../../assets/images/vibes-img.png";
import Typography from "../../../../atoms/Typography/Typography";
import styled from "styled-components";
import Button from "../../../../atoms/Button/Button";

const Details = styled.div`
  padding: 50px;
  @media (max-width: 768px) {
    padding: 30px 0px;
  }
`;

const GoodVibes = () => {
  return (
    <SectionWrapper>
      <Container>
        <Row>
          <div>
            <img src={img} width="100%" />
          </div>
          <Details>
            <Typography variant="h3" weight="bold">
              Enjoy good vibes while you learn & grow with a family 😎
            </Typography>

            <Typography variant="p1" weight="bold">
              We are community centric and career focus
            </Typography>
            <Typography variant="p2">
              Start your career in tech with fun and super talented folks. Join
              private support groups of techies, build connections, learn and
              grow together. Organise group hangouts to connect in person, take
              pictures and engage in enlightening discussions.
            </Typography>
            <Typography variant="p2">
              We're super excited and grateful to have the opportunity to
              educate so many techies working with global firms around the world
              who enjoy the energy we educate with. feel free to join us today
            </Typography>

            <Button label="Enroll Now" onClick={() => window.scrollTo(0, 0)} />
          </Details>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default GoodVibes;
