import styled from "styled-components";

export const WHWrapper = styled.section`
  width: 100%;
  max-width: 960px;
  margin: auto;
  text-align: center;
  padding: 60px 0px;
  .sub-heading {
    max-width: 560px;
    width: 100%;
    margin: auto;
  }
  > h1 {
    text-transform: capitalize;
    margin: 0px;
  }
  .svg {
    position: absolute;
    margin-left: -350px;
  }

  @media (max-width: 1000px) {
    .svg {
      display: none;
    }
  }
`;
