import React from "react";
import {
  BackgroundImg,
  Overlay,
  DetailsWrapper,
  Wrapper,
} from "./CourseCardStyle";
import Typography from "./../../atoms/Typography/Typography";

const InstructorCards = ({ bgImg, name, jobTitle, ...props }) => {
  return (
    <Wrapper
      style={{
        height: "286px",
      }}
      {...props}
    >
      <BackgroundImg bgImg={bgImg} className="bg" />
      <Overlay>
        <DetailsWrapper>
          <div>
            <Typography
              variant="h6"
              weight="bold"
              style={{
                color: "#fff",
                textTransform: "capitalize",
                margin: "10px 0px",
              }}
            >
              {name}
            </Typography>
            <Typography variant="p3">{jobTitle}</Typography>
          </div>
        </DetailsWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default InstructorCards;
