const forgotpwdReducer = (state = 1, action) => {
  switch (action.type) {
    case "next":
      return {
        page: action.payload.page,
        data: action.payload.data,
      };
    default:
      var res = {
        page: 1,
        data: null,
      };
      return res;
  }
};

export default forgotpwdReducer;
