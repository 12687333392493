/* eslint-disable no-shadow */
import React, { memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ children }) {
  //return true if user is logged in
  const auth = useSelector((state) => state.auth);
  return auth.login ? <Outlet /> : <Navigate to="/login" />;
}
export default memo(ProtectedRoute);
