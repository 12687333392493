import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 150px 0px;
  h1 {
    margin: 0px;
  }
`;

export const Desc = styled.div`
  width: 700px;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ccc;
  position: absolute;
  border: 4px solid #fff;
  background-position: center;
  background-size: cover;
  filter: drop-shadow(0px 12px 36px rgba(87, 87, 87, 0.3));
  background-image: url(${(props) => props.bg});

  &.second {
    top: -150px;
    width: 30px;
    height: 30px;
    margin-left: -25px;
    left: 50%;
  }

  &.third {
    right: 0px;
    width: 60px;
    height: 60px;
    top: -100px;
  }

  &.four {
    width: 30px;
    height: 30px;
    bottom: -90px;
    left: 15%;
  }

  &.five {
    width: 30px;
    height: 30px;
    bottom: -100px;
    left: 60%;
  }

  &.six {
    width: 30px;
    height: 30px;
    bottom: 0px;
    right: 5%;
  }

  @media (max-width: 768px) {
    &.first {
      width: 30px;
      height: 30px;
      top: -50px;
    }
    &.six {
      display: none;
    }
  }
`;
