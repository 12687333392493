import { useState } from "react";
import { InterestBadge } from "../../../templates/auth/onboarding/OnboardingStyle";
import Interest from "../../../../helpers/interest";
import { InterestWrapper, BtnWrapper } from "./ProfileFormStyle";
import { useSelector } from "react-redux";
import Button from "../../../atoms/Button/Button";
import { CustomSelect } from "./../../../atoms/inputs/Input";
import { Label } from "../../../atoms/inputs/InputStyle";
import Goals from "../../../../helpers/goals";
import Toast from "../../../molecules/toast/Toast";
import axios from "./../../../../axios/axios";

const InterestEdit = ({ user }) => {
  const auth = useSelector((state) => state.auth);
  const mode = useSelector((state) => state.mode);
  const [goal, setGoal] = useState(user.goal);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userInterest, setUserInterest] = useState(user.interest);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleInterestCLick = (e, val) => {
    e.target.classList.toggle("selected");

    const picked = userInterest.includes(val);
    if (picked) {
      //remove
      setCount(count - 1);
      const index = userInterest.indexOf(val);
      if (index > -1) {
        userInterest.splice(index, 1);
      }
    } else {
      //add
      setCount(count + 1);
      userInterest.push(val);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (userInterest.length < 3) {
      handleNotify("Select atleast 3 interests", "error");
    } else if (goal === "") {
      handleNotify("Your goal can not be empty", "error");
    } else {
      try {
        const body = { goal, interest: userInterest };
        const res = await axios.patch("/users", body, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        handleNotify("changes saved successfully", "success");
      } catch (error) {
        handleNotify(error.response.data.message, "error");
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <CustomSelect
        label="Goal"
        list={Goals}
        value={goal}
        onChange={(e) => setGoal(e.target.value)}
        name="goal"
        onBlur=""
        state=""
        desc=""
      />

      <Label mode={mode}>Interest</Label>
      <InterestWrapper style={{ marginTop: 20 }}>
        {Interest.map((val, key) => (
          <InterestBadge
            key={key}
            onClick={(e) => handleInterestCLick(e, val)}
            mode={mode}
            className={userInterest.includes(val) && "selected"}
          >
            {val}
          </InterestBadge>
        ))}
      </InterestWrapper>

      <BtnWrapper>
        <Button
          label="Save Changes"
          type="submit"
          isLoading={isLoading}
          style={{ margin: "20px 0px" }}
          onClick={handleSubmit}
        />
      </BtnWrapper>
    </>
  );
};

export default InterestEdit;
