import styled from "styled-components";
import { Dark, Grey, Primary } from "../../atoms/Colors";

export const FooterWrapper = styled.div`
  padding: 56px 0px;
  background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bg)};
  border-top: 1px solid
    ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
`;

export const FooterMenu = styled.div`
  color: ${Grey[500]};
  > h5 {
    margin: 0px;
  }
  > ul {
    list-style-type: none;
    padding: 0px;
  }

  > ul > li {
    margin-bottom: 16px;
  }

  a {
    color: ${Grey[500]};
    text-transform: capitalize;
    &:hover {
      color: ${Primary[500]};
    }
  }
`;

export const SocialIconWrapper = styled.div`
  display: flex;
`;

export const SocialIcon = styled.div`
  width: 30px;
  height: 30px;
  background: ${Grey[800]};
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  > a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
  &.instagram {
    &:hover {
      background: #d6249f;
      /* background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      ); */
    }
  }
  &.twitter {
    &:hover {
      background: #0eacee;
    }
  }

  &.linkedin {
    &:hover {
      background: #0e76a8;
    }
  }

  &.youtube {
    &:hover {
      background: #c4302b;
    }
  }
`;

export const CopyRights = styled.div`
  text-align: center;
  padding: 50px;
  padding-top: 100px;
`;
