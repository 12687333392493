import { Helmet } from "react-helmet";
import CommingSoon from "../coming-soon/ComingSoon";
import TalentFeatured from "./featured/TalentFeatured";
import { TalentDp } from "./featured/TalentFeaturedStyle";
import TalentHero from "./hero/TalentHero";
import TalentPipeline from "./pipeline/TalentPipeline";

const Talent = () => {
  return (
    <div>
      <Helmet>
        <title> Nerdy Workforce - Nerdy Eye</title>
        <meta
          name="description"
          content="Access top-tier Tech Jobs and be on the path of your Career ladder"
        />
      </Helmet>
      <CommingSoon
        page="For Tech Talents"
        desc="Access top-tier Tech Jobs and be on the path of your Career ladder"
      />
      {/* <TalentHero />
      <TalentFeatured />
      <TalentPipeline /> */}
    </div>
  );
};

export default Talent;
