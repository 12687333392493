import { useState } from "react";
import { useSelector } from "react-redux";
//
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
//
import Typography from "../../atoms/Typography/Typography";
import { AccordionWrapper } from "./AccordionStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Dark, Grey } from "../../atoms/Colors";

const Faq = ({ data, defaultShow = false, ...props }) => {
  const mode = useSelector((state) => state.mode);
  const [expanded, setExpanded] = useState(defaultShow);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <AccordionWrapper mode={mode} {...props}>
        {data.map((item, i) => (
          <Accordion
            disableGutters
            key={i}
            expanded={expanded === item.label}
            onChange={handleChange(item.label)}
            sx={{
              borderBottom: `1px solid ${
                mode === "light" ? Grey[50] : Grey[600]
              }`,
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded === item.label ? (
                  <FeatherIcon icon="minus" size="16" />
                ) : (
                  <FeatherIcon icon="plus" size="16" />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="p2"
                className="title"
                weight="bold"
                style={{ textTransform: "capitalize" }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ color: mode === "light" ? Grey[500] : Dark.text }}
            >
              <Typography variant="p2">{item.desc}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionWrapper>
    </>
  );
};

export default Faq;
