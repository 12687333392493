import { useState } from "react";
import { FormWrapper, BtnWrapper } from "./ProfileFormStyle";
import {
  Input,
  CustomPhoneInput,
  CustomSelect,
} from "./../../../atoms/inputs/Input";
import { formatPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../atoms/Button/Button";
import axios from "./../../../../axios/axios";
import Toast from "../../../molecules/toast/Toast";
import { useSelector } from "react-redux";
import moment from "moment";

const BasicForm = ({ user }) => {
  const auth = useSelector((state) => state.auth);
  const [phone, setPhone] = useState(`+${user.phone.code}${user.phone.number}`);
  const [phoneErr, setPhoneErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const phoneNum = {
      code: parsePhoneNumber(phone).countryCallingCode,
      number: formatPhoneNumber(phone),
    };
    try {
      const body = { ...values, phone: phoneNum };
      const res = await axios.patch("/users", body, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      handleNotify("changes saved successfully", "success");
    } catch (error) {
      handleNotify(error.response.data.message, "error");
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      otherNames: user.otherNames,
      username: user.username,
      dob: moment(user.dob).utc().format("YYYY-MM-DD"),
      gender: user.gender,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("invalid email address").required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      username: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      dob: Yup.date().required("Required"),
    }),
    onSubmit: (values) => {
      if (!phone) {
        setPhoneErr("Required");
      } else {
        //form submittion
        handleSubmit(values);
      }
    },
  });

  return (
    <>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <div>
            <Input
              label="First Name"
              type="text"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              state={
                formik.touched.firstName && formik.errors.firstName && "error"
              }
              desc={
                formik.touched.firstName &&
                formik.errors.firstName &&
                formik.errors.firstName
              }
            />
          </div>

          <div>
            <Input
              label="Last Name"
              type="text"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              state={
                formik.touched.lastName && formik.errors.lastName && "error"
              }
              desc={
                formik.touched.lastName &&
                formik.errors.lastName &&
                formik.errors.lastName
              }
            />
          </div>

          <div>
            <Input
              label="Other Names"
              type="text"
              name="otherNames"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.otherNames}
              state={
                formik.touched.otherNames && formik.errors.otherNames && "error"
              }
              desc={
                formik.touched.otherNames &&
                formik.errors.otherNames &&
                formik.errors.otherNames
              }
            />
          </div>

          <div>
            <Input
              label="Username"
              type="text"
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              state={
                formik.touched.username && formik.errors.username && "error"
              }
              desc={
                formik.touched.username &&
                formik.errors.username &&
                formik.errors.username
              }
            />
          </div>

          <div>
            <Input
              label="Email Address"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              state={formik.touched.email && formik.errors.email && "error"}
              desc={
                formik.touched.email &&
                formik.errors.email &&
                formik.errors.email
              }
            />
          </div>

          <div>
            <CustomPhoneInput
              label="Phone Number"
              international
              placeholder="Enter phone number"
              countryCallingCodeEditable={false}
              defaultCountry="NG"
              onBlur={() => phone && setPhoneErr("")}
              value={phone}
              onChange={setPhone}
              state={phoneErr !== "" && "error"}
              desc={phoneErr}
            />
          </div>

          <div>
            <Input
              label="Date of Birth"
              type="date"
              name="dob"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dob}
              state={formik.touched.dob && formik.errors.dob && "error"}
              desc={
                formik.touched.dob && formik.errors.dob && formik.errors.dob
              }
            />
          </div>

          <div>
            <CustomSelect
              label="Gender"
              list={["male", "female", "others"]}
              value={formik.values.gender}
              onChange={formik.handleChange}
              name="gender"
              onBlur={formik.handleBlur}
              state={formik.touched.gender && formik.errors.gender && "error"}
              desc={
                formik.touched.gender &&
                formik.errors.gender &&
                formik.errors.gender
              }
            />
          </div>
        </FormWrapper>

        <BtnWrapper>
          <Button
            label="Save Changes"
            type="submit"
            isLoading={isLoading}
            style={{ margin: "20px" }}
          />
        </BtnWrapper>
      </form>
    </>
  );
};

export default BasicForm;
