import Hero from "./hero/Hero";
import Steps from "./steps/Steps";
import Creators from "./creators/Creators";
import Students from "./creators/Students";
import Review from "./review/Review";

const Home = () => {
  return (
    <>
      <Hero />
      <Steps />
      <Creators />
      <Students />
      <Review />
    </>
  );
};

export default Home;
