import { useState } from "react";
import { Input, Password, CheckboxInput } from "./../../../atoms/inputs/Input";
import { FormExtraAction } from "./../../../templates/auth/signup/SignUpStyle";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../../atoms/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "./../../../../axios/axios";
import { useDispatch } from "react-redux";
import { login } from "../../../../actions/auth";
import Toast from "../../../molecules/toast/Toast";

const LoginForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const dispatch = useDispatch();

  const handleNotify = (msg) => {
    showNotify(true);
    setNotifyMsg(msg);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const logIn = async (values) => {
    setIsLoading(true);
    try {
      const body = { ...values };
      const res = await axios.post("/auth/login", body);
      dispatch(login(true, res.data.token, res.data.data));
      navigate("/profile");
    } catch (error) {
      handleNotify(error.response.data.message);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      eu: "",
      password: "",
    },
    validationSchema: Yup.object({
      eu: Yup.string().required("Required"),
      password: Yup.string().min(8).required("Required"),
    }),
    onSubmit: (values) => {
      //form submittion
      logIn(values);
    },
  });
  return (
    <div>
      <Toast show={notify} type="error" msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Email or Username"
          name="eu"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.eu}
          state={formik.touched.eu && formik.errors.eu && "error"}
          desc={formik.touched.eu && formik.errors.eu && formik.errors.eu}
        />
        <Password
          label="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />
        <FormExtraAction>
          <CheckboxInput
            checked={rememberMe}
            onClick={() => setRememberMe(!rememberMe)}
            label="Remember me"
          />
          <Link to="/forgot-password">Forgot Password?</Link>
        </FormExtraAction>

        <Button
          label="Log In"
          color="grey"
          block={true}
          isLoading={isLoading}
          style={{ margin: "20px 0px" }}
          // onClick={handleLogin}
        />
      </form>
    </div>
  );
};

export default LoginForm;
