import styled from "styled-components";
import { Grey } from "../../../atoms/Colors";

export const WHiringWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  row-gap: 30px;
`;
export const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 40px;
  }
`;
export const Steplist = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  .vertical-timeline-element-content {
    padding: 0px;
    background: none;
  }

  .vertical-timeline-element-content-arrow {
    display: none;
  }
  .vertical-timeline-element {
    margin: 0px 0px;
  }
  .vertical-timeline {
    margin: 0;
  }
`;
export const ListNo = styled.span`
  background: ${(props) => (props.mode === "dark" ? Grey[700] : Grey[50])};
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.mode === "dark" ? "#fff" : "#000")};
  font-size: 24px;
`;

export const WHiringCard = styled.div`
  box-sizing: border-box;
  border-radius: 50px;
  border: 5px solid #000000;
  filter: drop-shadow(-20px -20px 0px #000000);
  width: 90%;
  height: 480px;
  background: #ccc;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    width: 90%;
    height: 300px;
    /* display: none; */
  }
`;
