import { useRef, useState } from "react";
import Typography from "./../../../atoms/Typography/Typography";
import { Slide } from "react-awesome-reveal";
import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import { SingleInstructor } from "./InstructorStyle";
import InstructorCards from "../../../molecules/cards/InstructorCards";
import { useIsOverflow } from "../../../../customhooks/useIsOverflow";

const CourseInstructor = ({ name, jobTitle, bio, bgImg, mode }) => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  return (
    <SectionWrapper>
      <Container>
        <Slide direction="up">
          <SingleInstructor mode={mode}>
            <Typography variant="p2" align="center">
              Meet Your Instructor
            </Typography>
            <Typography
              variant="h3"
              weight="bold"
              align="center"
              style={{ textTransform: "capitalize", marginBottom: "32px" }}
            >
              learn from experts in the industry
            </Typography>
            <div className="row">
              <InstructorCards
                // name={name}
                // jobTitle={jobTitle}
                bgImg={bgImg}
                style={{ height: "350px" }}
              />
              <div className="details">
                <Typography
                  variant="h4"
                  weight="bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {jobTitle}
                </Typography>
                <div className={`bio ${showMore && "show"}`} ref={ref}>
                  <Typography variant="p2">{bio}</Typography>
                  {isOverflow && (
                    <div
                      className="fade"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Show Less" : "Show More"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SingleInstructor>
        </Slide>
      </Container>
    </SectionWrapper>
  );
};

export default CourseInstructor;
