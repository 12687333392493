import { Container, Row } from "../../../../../helpers/GlobalStyle";
import Typography from "../../../../atoms/Typography/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { InfoList } from "../details/DetailsStyle";
import { Success } from "../../../../atoms/Colors";
import { ImgWrapper } from "./GainStyle";
import Icons from "../../../../../assets/icons";

const Gain = ({ desc, list }) => {
  return (
    <div>
      <Container>
        <Row style={{ alignItems: "center", gap: "40px" }}>
          <div>
            <Typography variant="h3" weight="bold">
              Why take this course?
            </Typography>
            <Typography variant="p2">{desc}</Typography>

            {list &&
              list.map((val, i) => (
                <InfoList style={{ fontSize: "20px" }} key={i}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ color: Success[500] }}
                  />
                  <Typography variant="p3" weight="semi-bold">
                    {val}
                  </Typography>
                </InfoList>
              ))}
          </div>

          <div>
            <ImgWrapper>
              <Icons.Blob
                fill="#7B61FF"
                style={{
                  right: "-50px",
                  top: "-20px",
                  zIndex: -1,
                  position: "absolute",
                }}
              />

              <Icons.Blob2
                style={{
                  bottom: "-20px",
                  left: "-50px",
                  zIndex: -1,
                  position: "absolute",
                }}
              />
            </ImgWrapper>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Gain;
