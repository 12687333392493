import {
  Wrapper,
  BackgroundImg,
  Overlay,
  DetailsWrapper,
  DescWrapper,
} from "./CourseCardStyle";
import Typography from "./../../atoms/Typography/Typography";

const CareerCard = ({ bgImg, title, tutor, courses, desc }) => {
  return (
    <Wrapper>
      <BackgroundImg bgImg={bgImg} className="bg" />
      <Overlay>
        <DetailsWrapper>
          <div>
            <Typography
              variant="h4"
              weight="bold"
              style={{
                color: "#fff",
                textTransform: "capitalize",
                width: "250px",
              }}
            >
              {title}
            </Typography>
            <Typography variant="p3" weight="semi-bold">
              {tutor} Instructors - {courses} Courses
            </Typography>
          </div>
        </DetailsWrapper>
        <DescWrapper className="desc">{desc}</DescWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default CareerCard;
