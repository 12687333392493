import AuthStructure from "../../../organisms/auth/AuthStructure";
import { useNavigate, Navigate } from "react-router-dom";
import img from "./../../../../assets/images/auth/login.jpg";
import { Back, Skip } from "../../../organisms/auth/AuthStructureStyle";
import FeatherIcon from "feather-icons-react";
import Typography from "./../../../atoms/Typography/Typography";
import Button from "../../../atoms/Button/Button";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { OtpWrapper } from "./VerifyEmailStyle";
import { useSelector } from "react-redux";
import Toast from "../../../molecules/toast/Toast";
import axios from "./../../../../axios/axios";

const MainContent = () => {
  const auth = useSelector((state) => state.auth);

  const mode = useSelector((state) => state.mode);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [otp, setOtp] = useState("");
  const [notify, showNotify] = useState(false);
  const [succToast, setSuccToast] = useState(false);
  const [otpErr, setOtpErr] = useState("Enter all the four digit");

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleNotify = () => {
    showNotify(true);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post("/auth/verify-email", { otp });
      if (res.data.status === "success") {
        navigate("/subscription");
      }
    } catch (err) {
      setOtpErr(err.response.data.message);
      handleNotify();
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    otp.length < 4 ? handleNotify() : submit();
  };

  const handleResend = async () => {
    setIsResending(true);
    try {
      const res = await axios.patch("/auth/resend-otp", {
        email: auth.user.email,
      });
      if (res.data.status === "success") {
        setSuccToast(true);
        setInterval(() => {
          setSuccToast(false);
        }, 5000);
      }
    } catch (err) {
      setOtpErr(err.response.data.message);
      handleNotify();
    }
    setIsResending(false);
  };

  return (
    <>
      <Back onClick={() => navigate(-1)}>
        <FeatherIcon icon="arrow-left" />
      </Back>
      <Skip onClick={() => navigate("/profile")}>
        <Typography variant="p3" weight="semi-bold">
          Skip
        </Typography>
      </Skip>
      <Toast show={notify} type="error" msg={otpErr} />
      <Toast
        show={succToast}
        type="success"
        msg="Resent successfully, might take a few minutes to get to your email"
      />

      <Typography
        variant="h3"
        align="center"
        weight="bold"
        style={{ marginTop: "50px" }}
      >
        Verify your email address
      </Typography>
      <Typography variant="p3" align="center">
        OTP code has been sent to <strong>{auth.user.email}</strong>, it may
        take few minutes to arrive at your email. Enter code below.
      </Typography>
      <OtpWrapper mode={mode}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          shouldAutoFocus={true}
          isInputNum={true}
        />
      </OtpWrapper>
      <Button
        label="Verify"
        color="grey"
        block={true}
        style={{ margin: "20px 0px" }}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
      <Typography
        variant="p3"
        align="center"
        weight="semi-bold"
        style={{ marginTop: "20px" }}
      >
        Didn't recieve code?{" "}
        <Button
          onClick={handleResend}
          variant="text"
          color="primary"
          label={isResending ? " Resending..." : "Resend OTP"}
          style={{ padding: 0, display: "inline" }}
          isLoading={isResending}
        />
      </Typography>
    </>
  );
};
const VerifyEmail = () => {
  return <AuthStructure main={<MainContent />} bg={img} />;
};

export default VerifyEmail;
