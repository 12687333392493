import styled from "styled-components";
import { Extra, Grey } from "../../atoms/Colors";

export const ReviewStarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  margin-top: 20px;

  .review-star button {
    font-size: 20px;
  }

  button {
    background: none;
    border: 0px;
    cursor: pointer;
    transition: 0.2s;
    color: ${(props) => (props.mode === "light" ? Grey[600] : Grey[50])};
  }

  button:hover,
  button:nth-child(4):hover ~ button,
  button:nth-child(3):hover ~ button,
  button:nth-child(2):hover ~ button,
  button:nth-child(1):hover ~ button,
  button.active,
  button.active ~ button {
    color: ${Extra.yellow};
    font-size: 24px;
    transition: 0.2s;
  }
`;
