import styled from "styled-components";
import { Dark, Extra, Grey, Success } from "../../atoms/Colors";

export const JobsWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 50px 0px;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bgCard)};
`;

export const JobInput = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 14px;
  margin: auto;
  width: 85%;
  max-width: 600px;
  box-shadow: 0px 10px 40px 6px rgba(87, 87, 87, 0.12);
  background-color: ${(props) => (props.mode === "light" ? "#fff" : Grey[700])};
  color: ${(props) => (props.mode === "dark" ? Grey[50] : Grey[500])};
  border-radius: 30px;
  form {
    position: relative;
    width: 100%;
  }
  input {
    width: 100%;
    height: 50px;
    border: 0px;
    background: none;
    padding: 0px 8px;
    font-size: 16px;
    ::placeholder {
      font-size: 14px;
      color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    }
    &:focus {
      outline: 0px;
    }
  }
  margin-top: 40px;
  margin-bottom: -75px;
`;
export const JobLayout = styled.div`
  display: flex;

  margin-top: 70px;
  .sidebar {
    width: 250px;
  }
  .main {
    width: 100%;
  }
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
  }
`;

export const JobsTab = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
