import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import logo from "./../../../../../assets/images/logo.png";
import sign from "./../../../../../assets/images/samSignature.png";
import stamp from "./../../../../../assets/images/cert-stamp.png";
import { CertWrapper, CertPrev, Congratualtion } from "./CertificateStyle";
import Button from "../../../../atoms/Button/Button";
import jsPDF from "jspdf";
import { Container } from "../../../../../helpers/GlobalStyle";
import { ButtonGroup } from "../../../../atoms/Button/ButtonStyle";
import Typography from "../../../../atoms/Typography/Typography";
import { useSelector } from "react-redux";
import useFetch from "../../../../../api/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import CardSkeleton from "../../../../organisms/skeleton/CardSkeleton";

const PathCertificatesPrep = ({ data }) => {
  const [cert, setCert] = useState(null);

  const turn2img = () => {
    const input = window.document.getElementById("content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setCert(imgData);
    });
  };

  const downloadPdfDocument = () => {
    const input = window.document.getElementById("content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        format: [930, 590],
        unit: "px",
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`certificate.pdf`);
    });
  };

  useEffect(() => {
    turn2img();
  }, []);

  return (
    <div>
      <Container>
        <img
          src={cert}
          width="100%"
          style={{
            borderRadius: "20px",
          }}
        />
        <CertPrev>
          <CertWrapper id="content">
            <div className="cert">
              <center>
                <img src={logo} width="80" className="logo" />

                <p className="sub-title">This is to certify that</p>
                <h1 className="name">
                  {data.userID.firstName} {data.userID.otherNames}{" "}
                  {data.userID.lastName}
                </h1>
                <p className="desc">
                  has successfully completed the{" "}
                  <strong className="course">
                    {data.bootcamp_id.title} Bootcamp
                  </strong>{" "}
                  and hereby awarded this certificate of completion
                </p>
              </center>

              <div className="footer">
                <div className="date">
                  <p>
                    <strong>Date Issued:</strong> <br />{" "}
                    {moment(data.cert_issued_date).format("Do MMM, YYYY")}
                  </p>
                </div>
                <img
                  src={stamp}
                  width="80px"
                  height="80px"
                  style={{ marginTop: "-20px" }}
                />
                <div className="instructor">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "5px",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <img src={sign} width="40" />
                  </div>
                  <p style={{ fontSize: "8px" }}>Founder - Samuel L. Agbo</p>
                </div>
              </div>
              <div className="sub-footer">
                Certificate public url:
                {` ${window.location.origin}/bootcamp-certificate?bid=${data.bootcamp_id._id}&uid=${data.userID._id}`}
              </div>
            </div>
          </CertWrapper>
        </CertPrev>

        <ButtonGroup style={{ padding: "0 40px", marginBottom: 50 }}>
          <Button label="Download Pdf" onClick={downloadPdfDocument} />
          <a href={cert} download="cerficates.jpg">
            <Button
              label="Download Jpg"
              variant="text"
              block={true}
              color="grey"
            />
          </a>
        </ButtonGroup>
      </Container>
    </div>
  );
};

const BootcampCertificates = () => {
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);
  const { id } = useParams();
  let navigate = useNavigate();

  const { data, loading, error } = useFetch(
    `progress/bootcamp-certificate/${id}`,
    auth.token
  );

  if (loading) {
    return (
      <div>
        <CardSkeleton
          style={{ borderRadius: 0, height: 200, marginBottom: 50 }}
        />
        <Container>
          <CardSkeleton style={{ height: 400, marginBottom: 20 }} />
        </Container>
      </div>
    );
  }

  if (error) {
    //navigate back to profile courses
    navigate("/profile/courses?type=bottcamps");
  }

  return (
    <>
      <Congratualtion mode={mode}>
        <Container>
          <Typography
            variant="h4"
            weight="bold"
            style={{ textTransform: "capitalize" }}
          >
            Congratulations {auth.user.firstName} 🎊🥳
          </Typography>
          <Typography variant="p2">
            We are super excited to see that you have come this far, it takes a
            lot of discipline, dedication and consistency. Don't forget, we are
            always rooting for you 💪. Feel free to download your certificates,
            add it to your resume/cv, share it on your Social Media Platform
            tagging us.
          </Typography>
        </Container>
      </Congratualtion>
      <PathCertificatesPrep data={data.data} />
    </>
  );
};

export default BootcampCertificates;
