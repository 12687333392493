import {
  BackgroundImg,
  Overlay,
  DetailsWrapper,
  DescWrapper,
} from "./CourseCardStyle";
import Typography from "../../atoms/Typography/Typography";
import { Badge1 } from "./CourseCardStyle";
import {
  BootcampSpan,
  BootcampWrapper,
} from "../../templates/courses/bootcamp/BootcampStyle";

const BootcampCard = ({ bgImg, type, title, date, desc, masterClass }) => {
  return (
    <BootcampWrapper>
      <BackgroundImg bgImg={bgImg} className="bg" />
      <Overlay>
        <DetailsWrapper>
          <div>
            <Badge1 className={`${masterClass && "active"}`}>{type}</Badge1>
            <Typography
              variant="h4"
              weight="bold"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              {title}
            </Typography>
            <Typography variant="p3" weight="semi-bold">
              {/* Start Date: <BootcampSpan>{date}</BootcampSpan> */}
            </Typography>
          </div>
        </DetailsWrapper>
        <DescWrapper className="desc">{desc}</DescWrapper>
      </Overlay>
    </BootcampWrapper>
  );
};

export default BootcampCard;
