import styled from "styled-components";

export const THWrapper = styled.div`
  width: 100%;
  max-width: 960px;
  margin: auto;
  text-align: center;
  padding: 50px 0px;
  .sub-heading {
    max-width: 560px;
    width: 100%;
    margin: auto;
    padding: 20px 0;
  }
  > h1 {
    text-transform: capitalize;
    margin: 0px;
  }
  .svg {
    position: absolute;
    margin-left: -300px;
  }
  .talent-btn {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    @media (max-width: 480px) {
      flex-direction: column;
      row-gap: 30px;
      margin: auto;
      max-width: 220px;
    }
  }

  @media (max-width: 1000px) {
    .svg {
      display: none;
    }
  }
`;
