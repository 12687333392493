import Button from "../../../atoms/Button/Button";
import { Container } from "../../../../helpers/GlobalStyle";
import Badge from "../../../atoms/Badge/Badge";
import Typography from "../../../atoms/Typography/Typography";
import {
  JobHeaderIcon,
  JobLevel,
  SimilarJob,
  SingleJobHeader,
  SingleJobWrapper,
} from "./SingleJobStyle";
import { Grey } from "../../../atoms/Colors";
import Icons from "../../../../assets/icons";
import JobCard from "../../../molecules/cards/JobCard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useFetch from "../../../../api/useFetch";
import { CompanyDetail } from "../../../molecules/cards/JobCardStyle";
import moment from "moment";
import { useParams } from "react-router-dom";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";

const SingleJob = ({ mode }) => {
  console.log(window.location.href);

  let { url } = useParams();
  const { data, loading, error } = useFetch(`jobs/${url}`);

  if (error) {
    console.log(error);
    return <div>Oops! something went wrong</div>;
  }

  console.log(data);
  return (
    <>
      <Container>
        {loading ? (
          <SingleJobWrapper>
            <SingleJobHeader>
              <CardSkeleton mode={mode} height="320px" />
              <CardSkeleton mode={mode} height="320px" />
            </SingleJobHeader>
            <JobLevel>
              <CardSkeleton mode={mode} height="320px" />
              <CardSkeleton mode={mode} height="320px" />
              <CardSkeleton mode={mode} height="320px" />
            </JobLevel>
          </SingleJobWrapper>
        ) : (
          <SingleJobWrapper>
            {data.map((job, i) => (
              <>
                <SingleJobHeader>
                  <div className="header1">
                    <Typography
                      variant="h6"
                      weight="bold"
                      style={{ color: `${Grey[400]}` }}
                    >
                      {job.recruiter.companyName}
                    </Typography>
                    <Typography variant="h3" weight="bold">
                      {job.title}
                    </Typography>
                    <Typography variant="p2" weight="bold">
                      {job.currency}
                      {job.salary.from.toLocaleString()} - {job.currency}
                      {job.salary.to.toLocaleString()}
                    </Typography>
                    <CompanyDetail>
                      <Badge variant="purple">{job.jobTime}</Badge>
                      <Badge variant="purple">{job.jobType}</Badge>
                    </CompanyDetail>
                  </div>

                  <div className="header2">
                    <div style={{ display: "flex", columnGap: "10px" }}>
                      <Button label="Apply now" size="md" />{" "}
                      <CopyToClipboard text={window.location.href}>
                        <JobHeaderIcon>
                          <Icons.Copy style={{ color: `${Grey[700]}` }} />
                        </JobHeaderIcon>
                      </CopyToClipboard>
                    </div>

                    <Typography variant="p2" weight="semi-bold">
                      {moment(job.createdAt).format("MMM Do")}
                    </Typography>
                  </div>
                </SingleJobHeader>
                <JobLevel>
                  <div>
                    <Typography variant="h6" weight="semi-bold">
                      Specialty
                    </Typography>
                    <Typography variant="p2">{job.title}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" weight="semi-bold">
                      Expertise level
                    </Typography>
                    <Typography variant="p2">{job.experience}</Typography>
                  </div>
                </JobLevel>
                <div>
                  <Typography variant="h6" weight="semi-bold">
                    Responsibilities
                  </Typography>
                  <Typography variant="p3">{job.responsibilities}</Typography>
                </div>
                <div>
                  <Typography variant="h6" weight="semi-bold">
                    Company Description
                  </Typography>
                  <Typography variant="p3">{job.description}</Typography>
                </div>
                <div>
                  <Typography variant="h6" weight="semi-bold">
                    Working conditions and benefits
                  </Typography>
                  <Typography variant="p3">{job.workingCondition}</Typography>
                </div>
              </>
            ))}

            <SimilarJob>
              <Typography variant="h3" weight="bold">
                Similar Jobs
              </Typography>
              <div className="similarjobs">
                <JobCard variant="featured" vertical={true} />
                <JobCard vertical={true} isNew={true} />
                <JobCard vertical={true} />
                <JobCard vertical={true} />
              </div>
            </SimilarJob>
          </SingleJobWrapper>
        )}
      </Container>
    </>
  );
};

export default SingleJob;
