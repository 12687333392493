import FeatherIcon from "feather-icons-react";
import React from "react";
import { ModeWrapper } from "./ModeSwitchStyle";
import { useSelector, useDispatch } from "react-redux";
import { light, dark } from "../../../actions/mode";

const ModeSwitch = ({ ...props }) => {
  const mode = useSelector((state) => state.mode);
  let dispatch = useDispatch();
  return (
    <div>
      <ModeWrapper mode={mode} {...props}>
        {mode === "light" ? (
          <FeatherIcon icon="moon" onClick={() => dispatch(dark())} />
        ) : (
          <FeatherIcon icon="sun" onClick={() => dispatch(light())} />
        )}
      </ModeWrapper>
    </div>
  );
};

export default ModeSwitch;
