import React from "react";
import Bootcamp from "./bootcamp/Bootcamp";
import Career from "./career/Career";
import CourseGrid from "./courses/CourseGrid";
import Hero from "./hero/Hero";
import Instructor from "./instructor/Instructor";
// import TempCourse from "./temp-course/TempCourse";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import TestimonyPopup from "../../organisms/testimonials/TestimonyPopup";

const Courses = () => {
  const mode = useSelector((state) => state.mode);

  return (
    <>
      <Helmet>
        <title> Tech Courses, Career Path & Bootcamps - Nerdy Eye</title>
        <meta
          name="description"
          content="Access Free and paid online courses, training and bootcamps. Get access to project based courses and learn by doing."
        />
      </Helmet>
      <TestimonyPopup />
      {/* <TempCourse /> */}
      <Hero />
      <Bootcamp />
      <Career
        title="Choose a career path"
        desc="Learn from industry experts with years of experience and track record of success"
        url="/career-paths/random-career-paths?limit=3"
      />
      <CourseGrid
        title="Courses"
        desc="Get access to project based courses and learn by doing"
        url="courses/random-courses?type=courses&limit=4"
      />

      <Instructor
        desc="You will be learning from practicing professionals as they share insights from real-life scenarios."
        title="Taught By Experts Not Just Teachers"
        mode={mode}
      />
    </>
  );
};

export default Courses;
