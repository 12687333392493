import React from "react";
import styled from "styled-components";
import Typography from "../../atoms/Typography/Typography";
import Badge from "../../atoms/Badge/Badge";
import { Eye } from "feather-icons-react/build/IconComponents";
import { Dark, Grey } from "../../atoms/Colors";

const JobCard = styled.div`
  with: 100%;
  height: 130px;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  :hover{
    border:none;
    box-shadow: 0 3px 10px 10px rgb(0 0 0 / 0.1);

  }

  .Badge_container {
    display: flex;
    justify-content: space-between;
    align-item: center;
    align-content: center;
    flex-wrap: wrap;

    span {
      margin-right: 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #191a1d;
      @media (max-width: 768px) {
      margin-bottom:10px;
    

  
  }
    }
  }
  .views {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 40px;
    float:right;
    @media (max-width: 768px) {
       float:left;
       margin-bottom: 20px;
       line-height:2;

  
  }
    span {
      font-size: 14px;
      line-height: 24px;
      color: #222222;
      float:right;
    }
  }
  .job_post{
   
  
   span:not(:last-child ) {
       margin-left: 15px;
       margin-right:15px;
    @media (max-width: 768px) {
        margin-right:15px;
       margin-left: 0px;


  
  }


   }

  }

  @media (max-width: 768px) {
    padding: 0px 25px 30px 25px;
    height: auto;

    h5 {
      font-size: 16px;
    }
 
  
  }




`;





const ProfileJobCard = ({title,details1, details2, details3, details4, details5, expert,remote, time}) => {
  return (
    <JobCard>
      <div>
        <Typography variant="h5" weight="bold">
          {title}
        </Typography>
        <div className="Badge_container">
          <Badge className="job_item">{details1}</Badge>
          <Badge>{details2}</Badge>
          <Badge>{details3}</Badge>
          <Badge>{details4}</Badge>
          <Badge>{details5}</Badge>
        </div>
      </div>

      <div >
        <Typography variant="h6" className="views">
          <Eye /> <span>2342 views</span>
        </Typography>
        <div  className="job_post">
          <span>{expert}</span>
          <span>{remote}</span>
          <span>{time}</span>
        </div>
      </div>
  
    </JobCard>
  );
};

export default ProfileJobCard;
