import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect } from "react";
import {
  Container,
  Row,
  SectionWrapper,
} from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import ProgressBar from "../../../molecules/progress/ProgressBar";
import {
  SubBadge,
  SubBadgeWrapper,
  SubDetails,
  DownloadBtn,
  InvoiceWrapper,
  InvoicePrev,
  CardDetails,
} from "./BillingStyle";
import moment from "moment/moment";
import { useState } from "react";
import Button from "../../../atoms/Button/Button";
import { Link } from "react-router-dom";
import Cards from "../../../molecules/cards/Cards";
import Table from "../../../organisms/tables/Table";
import CancelSub from "./CancelSub";
import { useSelector } from "react-redux";
import { singleRecord } from "../../../../api/api";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import logo from "../../../../assets/images/logo-main.png";
import Badge from "../../../atoms/Badge/Badge";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Error, Success } from "../../../atoms/Colors";

const Billing = () => {
  const auth = useSelector((state) => state.auth);
  const [historyData, setHistoryData] = useState(null);
  const [history, setHistory] = useState(null);
  const [card, setCard] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [percent, setPercent] = useState(0);
  const [sub, setSub] = useState(null);
  const [platformTrans, setPlatformTrans] = useState(null);
  const [bootcampSub, setBootcampSub] = useState(null);
  const [invoiceImg, setInvoiceImg] = useState(null);
  const [dwnInvoiceData, setDwnInvoiceData] = useState(null);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Plan",
      selector: (row) => row.plan,
      sortable: true,
    },
    {
      name: "Amount",
      sortable: true,
      cell: (row) => <span>&#x20a6;{Number(row.amount).toLocaleString()}</span>,
    },
    {
      name: "Download",
      sortable: true,
      cell: (row) => (
        <DownloadBtn onClick={() => downloadInvoice(row._id)}>
          <FeatherIcon icon="download-cloud" size={18} />
        </DownloadBtn>
      ),
    },
  ];

  const bootcampColumns = [
    {
      name: "Title",
      selector: (row) => (
        <>
          <Link
            to={`/profile/bootcamps/${row.bootcampID[0].slug}`}
            style={{ textTransform: "capitalize" }}
          >
            {row.bootcampID[0].title}
          </Link>
        </>
      ),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        `${row.currency}${Number(row.amount).toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Billing Cycle",
      selector: (row) => row.duration,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        moment(row.endDate) > moment() ? (
          <span style={{ color: Success[300] }}>
            <strong>Active</strong>
          </span>
        ) : (
          <strong style={{ color: Error[600] }}>Expired</strong>
        ),
      sortable: true,
    },
    {
      name: "Expiry",
      sortable: true,
      cell: (row) => <span>{moment(row.endDate).format("Do MMM, YYYY")}</span>,
    },
    {
      name: "Auto Renew",
      sortable: true,
      cell: (row) => <span>--</span>,
    },
    {
      name: "Subscription",
      cell: (row) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            label={<FeatherIcon icon="pause-circle" size={16} />}
            title="Pause Subscription"
            size="sm"
            color="grey"
          />

          <Button
            label={<FeatherIcon icon="plus-circle" size={16} />}
            title="Add Subscription"
            size="sm"
            color="success"
          />
        </div>
      ),
    },
  ];

  const turn2img = () => {
    const input = window.document.getElementById("content");
    html2canvas(input, { scale: 4 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setInvoiceImg(imgData);
    });
  };

  const downloadPdfDocument = () => {
    const input = window.document.getElementById("content");
    html2canvas(input, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        format: "a4",
        unit: "mm",
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`invoice.pdf`);
    });
  };

  const downloadInvoice = async (id) => {
    const trans = history.find((subject) => subject._id === id);
    setDwnInvoiceData(trans);
    turn2img();
    setTimeout(() => {
      downloadPdfDocument();
    }, 1000);
  };

  const getSubStatus = async () => {
    const res = await singleRecord(auth.token, "/sub");
    // console.log(res);
    if (res.data) {
      let prepEnd = moment(res.data.endDate).format("YYYY-MM-DD");
      let end = moment(prepEnd);
      let startPrep = moment().format("YYYY-MM-DD");
      // let start = moment(startPrep);
      let start = moment(res.data.startDate);
      let tDate = moment(Date.now()).format("YYYY-MM-DD"); // get today date
      let today = moment(tDate); //prepare today date
      let useToday = today > end ? end : today;

      let diff = end.diff(start, "days");
      let total = diff < 0 ? 0 : diff; //total window of subscription
      let diffSpent = useToday.diff(start, "days");
      let spent = diffSpent < 0 ? 0 : diffSpent; // spen days
      setRemaining(total - spent); //remaining

      setPercent((spent / total) * 100);
      setPlatformTrans(res.data);
    }
    setSub(res.sub_status);
  };

  // console.log(percent);

  const getBillingHistory = async () => {
    const res = await singleRecord(auth.token, "/sub/history");
    // console.log(res.data);

    if (res.data.length !== 0) {
      let prepData = [];
      res.data.map((val) => {
        prepData.push({
          date: moment(val.startDate).format("MMM Do, YY"),
          plan: val.plan,
          amount: val.amount,
          _id: val._id,
        });
      });
      setHistoryData(prepData);
      setHistory(res.data);
    } else {
      setHistoryData([{}]);
    }
  };

  const getCard = async () => {
    const res = await singleRecord(auth.token, "/sub/last-card");
    setCard(res.data);
  };

  const getBootcamps = async () => {
    const res = await singleRecord(auth.token, "/sub/bootcamps");
    setBootcampSub(res.data);
  };

  useEffect(() => {
    getSubStatus();
    getBillingHistory();
    getCard();
    getBootcamps();
  }, []);

  return (
    <div>
      <SectionWrapper>
        <Container>
          <Typography variant="h3" weight="bold">
            Billing
          </Typography>
          <Typography variant="p2">Manage billing and subscription</Typography>
          <Row style={{ marginTop: 48, gap: 40 }}>
            <div>
              <Typography variant="h6" weight="semi-bold">
                Card Details
              </Typography>
              {card ? (
                <>
                  <CardDetails>
                    <Typography variant="p2" weight="semi-bold">
                      Card Number
                    </Typography>
                    <Typography variant="p2" weight="">
                      {card.first_digits} **** **** **** {card.last_digits}
                    </Typography>
                  </CardDetails>
                  <CardDetails>
                    <Typography variant="p2" weight="semi-bold">
                      Expiry
                    </Typography>
                    <Typography variant="p2">{card.expiry}</Typography>
                  </CardDetails>
                  <CardDetails>
                    <Typography variant="p2" weight="semi-bold">
                      Type
                    </Typography>
                    <Typography variant="p2">{card.type}</Typography>
                  </CardDetails>
                </>
              ) : card === "" ? (
                <>
                  <CardSkeleton height="12px" style={{ marginTop: 10 }} />
                  <CardSkeleton height="12px" style={{ marginTop: 10 }} />
                  <CardSkeleton height="12px" style={{ marginTop: 10 }} />
                  <CardSkeleton height="12px" style={{ marginTop: 10 }} />
                </>
              ) : (
                <Typography variant="p2">
                  No payment details associated with your account yet. Activate
                  your subscription to do that.
                </Typography>
              )}
            </div>
            <div>
              <Typography variant="h6" weight="semi-bold">
                Billing Cycle
              </Typography>

              {platformTrans ? (
                <>
                  <Typography variant="p2">
                    Your Platform Subscription will be billed
                    <strong> {platformTrans.duration}</strong>
                  </Typography>
                </>
              ) : (
                <Typography variant="p2">No billing cycle found</Typography>
              )}
            </div>
          </Row>
          <Row style={{ marginTop: 48, gap: 40 }}>
            {/* <div>
              {sub ? (
                <SubDetails>
                  <div>
                    <Typography variant="h6" weight="semi-bold">
                      Platform Subscription
                    </Typography>
                    {sub === "active" ? (
                      <>
                        <Typography variant="p3">
                          You have access to all courses, career-path, ability
                          to enroll for bootcamps and all features/benefits.
                          Start Date:
                          <strong>
                            {" "}
                            {moment(platformTrans.startDate).format(
                              "Do MMM, YYYY"
                            )}{" "}
                          </strong>
                          - Expiring:
                          <strong>
                            {" "}
                            {moment(platformTrans.endDate).format(
                              "Do MMM, YYYY"
                            )}
                          </strong>
                        </Typography>

                        <ProgressBar
                          progress={`${percent}%`}
                          variant={remaining <= 3 ? "error" : "success"}
                        />
                        <Typography variant="p3" align="right">
                          {remaining} {remaining > 1 ? "days" : "day"} remaining
                        </Typography>

                        <Link to="/subscription">
                          <Button color="success" label="Add more days" />
                        </Link>
                      </>
                    ) : (
                      <>
                        <Typography variant="p3">
                          You do not have an active subscription, click the
                          button below to subscripe now
                        </Typography>
                        <Link to="/subscription">
                          <Button label="Activate Subscription" />
                        </Link>
                      </>
                    )}
                  </div>
                  {sub === "active" ? (
                    <SubBadgeWrapper sub="active">
                      <SubBadge sub="active">
                        <FeatherIcon icon="check" />
                      </SubBadge>
                      <Typography variant="small1" weight="semi-bold">
                        Active
                      </Typography>
                    </SubBadgeWrapper>
                  ) : (
                    <SubBadgeWrapper sub="inactive">
                      <SubBadge sub="inactive">
                        <FeatherIcon icon="x" />
                      </SubBadge>
                      <Typography variant="small1" weight="semi-bold">
                        Inactive
                      </Typography>
                    </SubBadgeWrapper>
                  )}
                </SubDetails>
              ) : (
                <>
                  <CardSkeleton width="100px" height="20px" />
                  <CardSkeleton height="12px" style={{ marginTop: 20 }} />
                  <CardSkeleton height="12px" style={{ marginTop: 8 }} />
                </>
              )}
            </div> */}
            <div>
              <SubDetails>
                <div>
                  <Typography variant="h6" weight="semi-bold">
                    Bootcamp Subscription
                  </Typography>
                  {bootcampSub && bootcampSub.length > 0 ? (
                    <>
                      <Typography variant="p3">
                        You have <b>{bootcampSub.length}</b> active bootcamp
                        subscription. This give you access to more amazing
                        learning support.
                      </Typography>
                    </>
                  ) : bootcampSub && bootcampSub.length === 0 ? (
                    <>
                      <div>
                        <Typography variant="p3">
                          You do not have an active bootcamp subscription.
                          Enroll and subscribe for one.{" "}
                          <Link to="/courses/search?type=bootcamps">
                            Click here
                          </Link>
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <CardSkeleton height="12px" style={{ marginTop: 20 }} />
                      <CardSkeleton height="12px" style={{ marginTop: 8 }} />
                    </>
                  )}
                </div>
                {bootcampSub && bootcampSub.length > 0 ? (
                  <SubBadgeWrapper sub="active">
                    <SubBadge sub="active">
                      <FeatherIcon icon="check" />
                    </SubBadge>
                    <Typography variant="small1" weight="semi-bold">
                      Active
                    </Typography>
                  </SubBadgeWrapper>
                ) : (
                  bootcampSub &&
                  bootcampSub.length === 0 && (
                    <SubBadgeWrapper sub="inactive">
                      <SubBadge sub="inactive">
                        <FeatherIcon icon="x" />
                      </SubBadge>
                      <Typography variant="small1" weight="semi-bold">
                        Inactive
                      </Typography>
                    </SubBadgeWrapper>
                  )
                )}
              </SubDetails>
            </div>
          </Row>
          <Link to="/subscription">
            <Button label="Manage Subscription" />
          </Link>

          {/* list of subscribed bootcamps */}
          <div style={{ marginTop: 56, gap: 40 }}>
            <Typography variant="h6" weight="bold">
              Subscribed Bootcamps
            </Typography>
            {bootcampSub ? (
              <Cards style={{ marginTop: 20, marginBottom: 50 }}>
                <Table
                  data={bootcampSub.length == 0 ? [{}] : bootcampSub}
                  columns={bootcampColumns}
                />
              </Cards>
            ) : (
              <>
                <CardSkeleton
                  height="150px"
                  style={{ marginTop: 20, marginBottom: 50 }}
                />
              </>
            )}
          </div>

          <Typography variant="h6" weight="bold" style={{ marginTop: 56 }}>
            Payment Receipts
          </Typography>
          {historyData ? (
            <Cards style={{ marginTop: 20, marginBottom: 50 }}>
              <Table data={historyData} columns={columns} />
            </Cards>
          ) : (
            <>
              <CardSkeleton
                height="150px"
                style={{ marginTop: 20, marginBottom: 50 }}
              />
            </>
          )}
          {sub === "active" && platformTrans && platformTrans.subAutoRenew && (
            <CancelSub />
          )}
        </Container>
      </SectionWrapper>

      <InvoicePrev>
        <InvoiceWrapper id="content">
          <div className="row">
            <div>
              <img src={logo} width="100px" />
              <Typography variant="p3">
                35 Adebisi Alagomeji, Yaba, Lagos, Nigeria.
                <br />
              </Typography>
              <Typography variant="p3">Bill To:</Typography>

              <Typography>
                <b>Email</b>: billing@nerdyeye.com
              </Typography>

              <Typography
                variant="h6"
                style={{ textTransform: "capitalize" }}
                weight="semi-bold"
              >
                {auth.user.firstName} {auth.user.otherNames}{" "}
                {auth.user.lastName}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" weight="bold" style={{ color: "#222" }}>
                Invoice
              </Typography>
              <p>
                <b>Invoice Id</b>:{" "}
                {dwnInvoiceData && dwnInvoiceData._id.substr(0, 6)}
              </p>
              <p>
                <b>Billed On</b>{" "}
                {dwnInvoiceData &&
                  moment(dwnInvoiceData.startDate).format("DD-MM-YYYY")}
              </p>
              <p>
                <b>Due On</b>{" "}
                {dwnInvoiceData &&
                  moment(dwnInvoiceData.endDate).format("DD-MM-YYYY")}
              </p>

              <Badge variant="success">Paid</Badge>
              <Typography variant="h3" weight="bold" style={{ color: "#222" }}>
                &#x20a6;
                {dwnInvoiceData &&
                  Number(dwnInvoiceData.amount).toLocaleString()}
              </Typography>
            </div>
          </div>

          <table width="100%" style={{ marginTop: 30 }}>
            <tr border="1">
              <td>
                <b>Date</b>
              </td>
              <td>
                <b>Plan</b>
              </td>
              <td>
                <b>Amount</b>
              </td>
              <td>
                <b>Qty</b>
              </td>
              <td>
                <b>Subtotal</b>
              </td>
            </tr>
            <tr>
              <td>
                {dwnInvoiceData &&
                  moment(dwnInvoiceData.startDate).format("DD-MM-YYYY")}
              </td>
              <td>{dwnInvoiceData && dwnInvoiceData.plan}</td>
              <td>
                &#x20a6;{" "}
                {dwnInvoiceData &&
                  Number(dwnInvoiceData.amount).toLocaleString()}
              </td>
              <td>1</td>
              <td>
                &#x20a6;{" "}
                {dwnInvoiceData &&
                  Number(dwnInvoiceData.amount).toLocaleString()}
              </td>
            </tr>
          </table>

          <div className="summary">
            <div>
              <p>Subtotal:</p>
              <p>
                {" "}
                &#x20a6;
                {dwnInvoiceData &&
                  Number(dwnInvoiceData.amount).toLocaleString()}
              </p>
            </div>

            <div>
              <p>
                <b>Total:</b>
              </p>
              <p>
                {" "}
                &#x20a6;
                {dwnInvoiceData &&
                  Number(dwnInvoiceData.amount).toLocaleString()}
              </p>
            </div>
          </div>
        </InvoiceWrapper>
      </InvoicePrev>
      {/* view invoice: <img src={invoiceImg} width="100%" /> */}
    </div>
  );
};

export default Billing;
