import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { Wrapper, SubNotificationWrapper } from "./NotificationStyle";
import { Container } from "../../../helpers/GlobalStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Extra } from "../../atoms/Colors";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const SubNotification = ({ title, desc, showClose, variant, ...props }) => {
  const [open, setOpen] = useState(true);
  const auth = useSelector((state) => state.auth);

  let now = moment();
  let timeCreated = moment(auth.user.createdAt);

  let duration = moment.duration(now.diff(timeCreated));
  let hours = Math.floor(duration.asHours());
  let miliSeconds = duration.asMilliseconds();
  const countTo = 18000000 - miliSeconds;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <>ended</>;
    } else {
      // Render a countdown
      return (
        <>
          Ends in {hours}h : {minutes}m : {seconds}s
        </>
      );
    }
  };

  return (
    <>
      {hours < 5 && (
        <Wrapper>
          <Collapse in={open}>
            <SubNotificationWrapper variant={variant}>
              <Container>
                <FeatherIcon
                  icon="x"
                  size={18}
                  onClick={() => setOpen(false)}
                  style={{ position: "absolute", right: 20 }}
                />
                <p style={{ fontSize: 20 }}>
                  <strong>
                    <Countdown
                      date={Date.now() + countTo}
                      renderer={renderer}
                    />
                  </strong>
                </p>
                <p>{desc}</p>
                {variant === "general" && (
                  <p>
                    <Link to="/subscription" style={{ color: Extra.yellow }}>
                      <strong>Click Here</strong>
                    </Link>{" "}
                    to subscribe now
                  </p>
                )}
              </Container>
            </SubNotificationWrapper>
          </Collapse>
        </Wrapper>
      )}
    </>
  );
};

export default SubNotification;
