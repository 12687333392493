import styled from "styled-components";
import { Error, Grey, Info } from "../Colors";

export const Wrapper = styled.div`
  margin: 20px 0px;
`;
export const InputFieldStyle = styled.input`
  width: 100%;
  height: 50px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  font-size: 14px;
  margin-top: 12px;
  ::placeholder {
    color: ${Grey[300]};
  }
  &:focus {
    outline: 1px solid ${Info[300]};
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${(props) => (props.mode === "light" ? Grey[600] : Grey[200])};
  text-transform: capitalize;
  font-weight: 600;
`;

export const Desc = styled.span`
  color: ${(props) =>
    props.state === "error" ? `${Error[500]}` : `${Grey[500]}`};
`;

export const DiscountWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: none;
  border-bottom: 1px solid
    ${(props) => (props.mode === "light" ? Grey[200] : Grey[500])};
  display: flex;
  align-items: center;
  padding: 0px 0px;
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 12px;

  > svg {
    cursor: pointer;
  }
  .input {
    background: none;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    height: 28px;
    &:focus {
      outline: none;
    }
  }
`;

export const PwdWrapper = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-top: 12px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  > svg {
    cursor: pointer;
  }
`;

export const PwdInput = styled.input`
  width: 100%;
  background: none;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  border: 0px;
  /* padding: 20px; */
  box-sizing: border-box;
  &:focus {
    outline: 0px;
    ${PwdWrapper} {
      background: red;
    }
  }
`;

export const PhoneWrapper = styled.div`
  width: 100%;
  height: 50px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  font-size: 14px;
  margin-top: 12px;

  .PhoneInput {
    margin-top: -15px;
  }

  .PhoneInputInput {
    background: none;
    color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
    border: 0px;
    height: 40px;
    &:focus {
      outline: 0px;
    }
  }
  ::placeholder {
    color: ${Grey[300]};
  }
`;

export const CheckboxWrapper = styled.span`
  .MuiTypography-root {
    font-weight: 500;
    font-family: "Montserrat";
    /* font-size: 14px; */
    color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  }

  .MuiCheckbox-root {
    color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
  }
`;

export const SelectWrapper = styled.div`
  margin: 20px 0px;
  .MuiInputBase-root {
    width: 100%;
    height: 50px;
    border: ${(props) =>
      props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
    border-radius: 25px;
    padding: 20px;
    box-sizing: border-box;
    background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
    color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[200])};
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 12px;
    ::placeholder {
      color: ${Grey[300]};
    }
    &:focus {
      outline: 1px solid ${Info[300]};
    }
  }

  .MuiInputBase-root::before,
  .MuiInputBase-root::after {
    border-bottom: 0px;
    content: none;
  }

  .MuiInputBase-root:hover > .MuiInputBase-root::before {
    border-bottom: 10px;
  }
`;

export const TextAreaField = styled.textarea`
  width: 100%;
  height: 170px;
  border: ${(props) =>
    props.state === "error" ? `1px solid ${Error[500]}` : "0px"};
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[800])};
  color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[200])};
  font-size: 14px;
  margin-top: 12px;
  resize: none;
  ::placeholder {
    color: ${Grey[300]};
  }
  &:focus {
    outline: 1px solid ${Info[300]};
  }
`;
