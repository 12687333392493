import * as React from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TabWrapper } from "./TabsStyle";
import { useSearchParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CourseTabs({
  firstCom,
  secondCom,
  thirdCom,
  active = 0,
  changeActive,
  mode,
}) {
  const [value, setValue] = React.useState(active);

  const [searchParams, setSeachParams] = useSearchParams();

  const handleUrlChange = (val, field) => {
    // changeState(val);
    const queryParams = Object.fromEntries([...searchParams]);
    if (queryParams[field]) {
      // update
      queryParams[field] = val;
      setSeachParams(queryParams);
    } else {
      // new
      setSeachParams({ ...queryParams, [field]: [val] });
    }
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);
    let val = "";
    if (newValue === 1) {
      val = "careerpath";
    } else if (newValue === 2) {
      val = "bootcamps";
    } else {
      val = "courses";
    }

    handleUrlChange(val, "type");
    setValue(newValue);
  };

  return (
    <TabWrapper mode={mode} className="courses">
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
            variant="scrollable"
            allowScrollButtonsMobile
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 1 },
              },
            }}
          >
            <Tab
              label="Courses"
              // icon={<span className="badge">9</span>}
              iconPosition="end"
              {...a11yProps(0)}
              disableRipple={true}
            />
            <Tab
              label="Career Path"
              // icon={<span className="badge">0</span>}
              iconPosition="end"
              {...a11yProps(1)}
              disableRipple={true}
            />
            <Tab
              label="Bootcamps"
              // icon={<span className="badge">0</span>}
              iconPosition="end"
              {...a11yProps(2)}
              disableRipple={true}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {firstCom}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {secondCom}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {thirdCom}
        </TabPanel>
      </Box>
    </TabWrapper>
  );
}
