import Skeleton from "@mui/material/Skeleton";
import { Grey } from "../../atoms/Colors";
import { useSelector } from "react-redux";

const CardSkeleton = ({ height = "200px", width = "100%", ...props }) => {
  const mode = useSelector((state) => state.mode);

  return (
    <div>
      <Skeleton
        variant="rounded"
        width={width}
        height={height}
        sx={{
          borderRadius: "30px",
          bgcolor: mode === "light" ? Grey[100] : Grey[700],
        }}
        {...props}
      />
    </div>
  );
};

export default CardSkeleton;
