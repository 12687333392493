import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { Extra } from "../../../atoms/Colors";
import { TypeAnimation } from "react-type-animation";
import { StepList, StepsWrapper } from "./StepsStyle";
import icons from "../../../../assets/icons";
import { Zoom } from "react-awesome-reveal";

const Steps = () => {
  return (
    <StepsWrapper>
      <SectionWrapper>
        <Container>
          <div className="text">
            <Typography variant="h3" align="center">
              How It Works For{" "}
              <TypeAnimation
                sequence={["Creators", 1000, "Students", 1000, "Coaches", 1000]}
                speed={50}
                style={{ color: Extra.purple }}
                repeat={Infinity}
              />
            </Typography>
          </div>
          <Typography variant="p2" align="center">
            Comunity-first learning approach
          </Typography>
          <div className="list-con">
            <Zoom direction="left">
              <StepList>
                <div className="num">1</div>
                <div className="details">
                  <Typography variant="h5">
                    Start or join a Learning Trybe
                  </Typography>
                  <Typography variant="p3">
                    Creators, coaches or educators create Learning Trybe
                    (community) While students find & join Learning Trybe.
                  </Typography>
                </div>
              </StepList>
            </Zoom>
            <icons.StepArrow className="arrone" />

            <div className="list-two">
              <Zoom direction="left">
                <StepList className="two">
                  <div className="num">2</div>
                  <div className="details">
                    <Typography variant="h5">
                      Create or enroll for a course
                    </Typography>
                    <Typography variant="p3">
                      As a creator or coach, you can create a one-time fee or
                      subscription course and bootcamps. As a learner or
                      students you enroll for a course or bootcamp in your
                      learning trybe.
                    </Typography>
                  </div>
                </StepList>
              </Zoom>
            </div>

            <icons.StepArrow className="arrtwo" />

            <Zoom direction="left">
              <StepList className="three">
                <div className="num">3</div>
                <div className="details">
                  <Typography variant="h5">
                    Enjoy the experience 💃🕺
                  </Typography>
                  <Typography variant="p3">
                    Creators or coaches teach in a community as their students
                    also learn in a communtiy enjoying community-first learning
                    approach.
                  </Typography>
                </div>
              </StepList>
            </Zoom>
          </div>
        </Container>
      </SectionWrapper>
    </StepsWrapper>
  );
};

export default Steps;
