import styled from "styled-components";
import { useSelector } from "react-redux";
import { Dark, Grey } from "../../atoms/Colors";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 11px 90px 6px
    ${(props) => (props.mode === "light" ? "#e8e6e6" : Dark.bg)};
  background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bgCard)};
  color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
  box-sizing: border-box;
`;

const Cards = ({ children, ...props }) => {
  const mode = useSelector((state) => state.mode);

  return (
    <Wrapper mode={mode} {...props}>
      {children}
    </Wrapper>
  );
};

export default Cards;
