import styled from "styled-components";

export const GridWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 32px;
  grid-template-columns: auto auto;
  &.career-path {
    grid-template-columns: auto auto;
  }

  @media (max-width: 1280px) {
    grid-template-columns: auto auto auto;
    &.career-path {
      grid-template-columns: auto auto;
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: auto auto;
    &.career-path {
      grid-template-columns: auto;
    }
  }
  @media (max-width: 560px) {
    grid-template-columns: auto;
  }
`;
