import AuthStructure from "../../../organisms/auth/AuthStructure";
import { useNavigate, Link, Navigate } from "react-router-dom";
import img from "./../../../../assets/images/auth/login.jpg";
import { Back } from "../../../organisms/auth/AuthStructureStyle";
import FeatherIcon from "feather-icons-react";
import Typography from "./../../../atoms/Typography/Typography";
import GoogleBtn from "../../../atoms/Button/GoogleBtn";
import { useSelector } from "react-redux";
import LoginForm from "../../../organisms/forms/login/LoginForm";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";

const MainContent = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  if (auth) {
    return <Navigate to="/profile" />;
  }
  return (
    <>
      <Back onClick={() => navigate(-1)}>
        <FeatherIcon icon="arrow-left" />
      </Back>

      <Typography
        variant="h3"
        align="center"
        weight="bold"
        style={{ marginTop: 50 }}
      >
        Welcome Back
      </Typography>
      <Typography variant="p3" align="center">
        Log into your account
      </Typography>

      <LoginForm />

      {/* <Typography variant="p3" align="center" weight="semi-bold">
        OR
      </Typography>

      <GoogleBtn /> */}

      <Typography
        variant="p3"
        align="center"
        weight="semi-bold"
        style={{ marginTop: 20 }}
      >
        Don't have an account? <Link to="/create-account">Sign Up Now</Link>
      </Typography>
    </>
  );
};

const Login = () => {
  return <AuthStructure main={<MainContent />} bg={img} />;
};

export default Login;
