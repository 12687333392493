import styled from "styled-components";
import { Extra, Grey, Primary, Success } from "../Colors";

export const Wrapper = styled.span`
  padding: 8px 12px;
  border-radius: 15px;
  background: ${(props) =>
    props.variant === "primary"
      ? Primary[50]
      : props.variant === "success"
      ? Success[50]
      : props.variant === "yellow"
      ? Extra.yellow
      : props.variant === "purple"
      ? Extra.purple
      : Grey[50]};
  color: ${(props) =>
    props.variant === "primary"
      ? Primary[500]
      : props.variant === "success"
      ? Success[500]
      : props.variant === "purple"
      ? "#fff"
      : Grey[700]};
  font-size: 12px;
  font-weight: bold;
`;
