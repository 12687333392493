import styled from "styled-components";
import { Dark, Grey, Primary } from "../../../atoms/Colors";

export const HeroWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  .cursor-one {
    position: absolute;
    right: 0px;
    top: -150px;
  }

  .cursor-two {
    position: absolute;
    left: -50px;
    bottom: -150px;
  }
  @media (max-width: 1200px) {
    .cursor-two {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  /* margin-top: 120px; */
  margin: 120px 0px;
  /* height: 635px; */
  height: auto;
  width: 100%;
  overflow: hidden;
`;
export const Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 670px;
  @media (max-width: 920px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const FormWrapper = styled.div`
  form {
    width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 920px) {
    width: 100%;
  }
  #q {
    height: 56px;
    width: 600px;
    border-radius: 50px;
    border: none;
    box-shadow: 0px 10px 40px 6px rgba(87, 87, 87, 0.12);
    background-color: ${(props) =>
      props.mode === "light" ? "#fff" : Dark.bgCard};
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};

    ::placeholder {
      font-size: 14px;
      /* color: #a3a3a3; */
      color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    }
    &:focus {
      outline: 0px;
    }
    @media (max-width: 920px) {
      width: 100%;
    }
  }

  input[type="search"] {
    padding-left: 4rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23999' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 1rem 1rem;
    background-size: 1.8rem;
    position: relative;
  }
`;

export const CourseList = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${(props) => (props.mode === "light" ? Grey[700] : Dark.heading)};

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    list-style-type: none;
  }

  > ul > li > a {
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    text-decoration: underline;
  }

  > ul > li > a:hover {
    color: ${Primary[500]} !important;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    > ul {
      padding: 0 24px;
      justify-content: center;
      gap: 18px;
    }
  }
`;

export const CircleDash = styled.div`
  position: absolute;
  top: -50px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D23431FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='83' stroke-linecap='butt'/%3e%3c/svg%3e");
  animation: spin 5s linear infinite;
  @media (max-width: 920px) {
    width: 51px;
    height: 51px;
    top: -130px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const OvalDash = styled.div`
  position: absolute;
  right: 0px;
  bottom: 150px;
  width: 70px;
  height: 70px;
  transform: rotate(45deg);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D23431FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
  /* animation: spin 20s linear infinite; */
  @media (max-width: 920px) {
    width: 35px;
    height: 35px;
    right: -30px;
    bottom: 230px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Frame1 = styled.div`
  position: absolute;
  top: -70px;
  right: -10px;

  > .triangle1 {
    position: absolute;
    left: -30px;
    bottom: -10px;
    width: 18px;
    height: 18px;
    background: #7b61ff;
    border-radius: 70% 30% 20% 80% / 70% 70% 20% 40%;
    border: 4px solid #ffffff;
    transform: rotate(50deg);
  }
  @media (max-width: 920px) {
    width: 200px;
    top: -90px;
    right: -80px;
  }
`;

export const CursorCard = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  width: 150px;
  height: 50px;
  box-shadow: 0px 37px 90px 6px rgba(87, 87, 87, 0.12);
  border-radius: 10px;
  padding: 10px 16px;
  gap: 12px;
`;

export const Frame2 = styled.div`
  position: absolute;
  left: -40px;
  bottom: 300px;

  > .triangle2 {
    position: absolute;
    right: -26px;
    top: -23px;
    width: 18px;
    height: 18px;
    background: #f8bf3e;
    border-radius: 70% 30% 20% 80% / 70% 70% 20% 40%;
    border: 4px solid #ffffff;
    transform: rotate(50deg);
  }
  @media (max-width: 920px) {
    width: 200px;
    bottom: 90px;
    left: -120px;
  }
`;
