import Tabs from "../../../organisms/tabs/Tabs";
import { useSelector } from "react-redux";
import { GridWrapper } from "./UserCoursesStyle";
import { Link, useSearchParams } from "react-router-dom";
import CourseCard from "../../../molecules/cards/CourseCard";
import CareerCard from "../../../molecules/cards/CareerCard";
import BootcampCard from "../../../molecules/cards/Bootcamp";
import useFetch from "../../../../api/useFetch";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import ErrorApiPage from "../../../pages/ErrorApiPage";

const Courses = ({ auth }) => {
  const { data, loading, error } = useFetch("/enrollment/courses", auth.token);

  return (
    <div>
      <GridWrapper>
        {loading && (
          <>
            <CardSkeleton height="350px" />
            <CardSkeleton height="350px" />
          </>
        )}

        {data && data.length === 0 && (
          <div>
            You have not started any course yet.{" "}
            <Link to="/courses/search?type=courses">Click here </Link>
            to start a course
          </div>
        )}

        {data &&
          data.data.map((course, i) => (
            <Link to={`/profile/courses/${course.courseID.slug}`} key={i}>
              <CourseCard
                bgImg={course.courseID.avatar && course.courseID.avatar.url}
                title={course.courseID.title}
                intructor={course.courseID.instructorID.name}
                desc={course.courseID.description}
              />
            </Link>
          ))}
      </GridWrapper>
    </div>
  );
};

const CareerPath = ({ auth }) => {
  const { data, loading, error } = useFetch(
    "/enrollment/career-paths",
    auth.token
  );

  return (
    <div>
      <GridWrapper className="career-path">
        {loading && (
          <>
            <CardSkeleton height="350px" />
            <CardSkeleton height="350px" />
          </>
        )}

        {error && <ErrorApiPage error={error} />}

        {data && data.length === 0 && (
          <div>
            You have not started any career path yet.{" "}
            <Link to="/courses/search?type=careerpath">Click here </Link>
            to start a course
          </div>
        )}

        {data &&
          data.data.map((path, i) => (
            <Link
              to={`/profile/career-paths/${path.career_path_id.slug}`}
              key={i}
            >
              <CareerCard
                bgImg={path.career_path_id.avatar.url}
                title={path.career_path_id.title}
                tutor={path.career_path_id.course_Ids.length}
                courses={path.career_path_id.course_Ids.length}
                desc={path.career_path_id.description.substr(0, 150)}
              />
            </Link>
          ))}
      </GridWrapper>
    </div>
  );
};

const Bootcamp = ({ auth }) => {
  const { data, loading, error } = useFetch(
    "/enrollment/bootcamps",
    auth.token
  );

  return (
    <div>
      <GridWrapper className="career-path">
        {loading && (
          <>
            <CardSkeleton height="350px" />
            <CardSkeleton height="350px" />
          </>
        )}

        {error && <ErrorApiPage error={error} />}

        {data && data.length === 0 && (
          <div>
            You have not started any career path yet.{" "}
            <Link to="/courses/search?type=bootcamps">Click here </Link>
            to start a course
          </div>
        )}

        {data &&
          data.data.map((bootcamp, i) => (
            <Link to={`/profile/bootcamps/${bootcamp.bootcamp_id.slug}`}>
              <BootcampCard
                bgImg={bootcamp.bootcamp_id.avatar.url}
                type={bootcamp.bootcamp_id.type}
                title={bootcamp.bootcamp_id.title}
                // date="23-01-2023"
                desc={bootcamp.bootcamp_id.description.substr(0, 150)}
                // masterClass={val.masterClass}
              />
            </Link>
          ))}

        {/* <Link to="#">
          <BootcampCard
            // bgImg={val.bgImg}
            type="physical"
            title="Product design"
            date="23-01-2023"
            desc="some text here"
            masterClass={true}
          />
        </Link> */}
      </GridWrapper>
    </div>
  );
};

const LearningTabs = () => {
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);

  const [srcParms, setSrcParams] = useSearchParams();
  const type = srcParms.get("type");

  return (
    <>
      <Tabs
        mode={mode}
        selected={type === "career-paths" ? 1 : 0}
        data={[
          {
            label: "Courses",
            component: <Courses auth={auth} />,
          },
          {
            label: "Career Paths",
            component: <CareerPath auth={auth} />,
          },
          {
            label: "Bootcamps",
            component: <Bootcamp auth={auth} />,
          },
        ]}
      />
    </>
  );
};

export default LearningTabs;
