import { useState, useEffect } from "react";
import axios from "./../axios/axios";

function useApiErrorHandler() {
  const [error, setError] = useState(null);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return error;
}

export default useApiErrorHandler;
