import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useSelector } from "react-redux";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "../../atoms/Typography/Typography";
import CommingSoon from "../coming-soon/ComingSoon";
import JobsLayout from "./JobsLayout";
import { JobsWrapper, JobInput } from "./JobsStyle";
import { Helmet } from "react-helmet";

const Jobs = () => {
  const mode = useSelector((state) => state.mode);
  return (
    <>
      <Helmet>
        <title> Tech Job - Remote, Hybrid & On-site - Nerdy Eye</title>
        <meta
          name="description"
          content="Find and apply to tech jobs from top employers. Filter through from series of tech job listed"
        />
      </Helmet>
      <Container>
        <CommingSoon
          page="Job Listing"
          desc="With the job listing features, employers will be able to list jobs and tech talents will be able to apply for jobs"
        />
      </Container>
      {/* <JobsWrapper mode={mode}>
        <Container>
          <Typography variant="h2" weight="bold" align="center">
            Find Jobs
          </Typography>
          <JobInput mode={mode}>
            <FeatherIcon icon="search" />
            <form>
              <input type="text" placeholder="Search Jobs" name="title" />
            </form>
          </JobInput>
        </Container>
      </JobsWrapper>
      <JobsLayout mode={mode}></JobsLayout> */}
    </>
  );
};

export default Jobs;
