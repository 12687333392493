import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

function handleApiError(error) {
  // Check if the error is a network error
  if (!error.response) {
    // Handle network errors (e.g., server down, no internet)
    console.error("Network Error:", error.message);
  } else {
    // Handle API errors (e.g., 4xx, 5xx responses)
    // if (error.response.data.message === "jwt expired") {
    //   useLogout();
    // }
    console.error("API Error:", error.response.data);
  }

  // Rethrow the error to maintain the error flow in the promise chain
  throw error;
}

// Request interceptor (optional)
// instance.interceptors.request.use(
//   (config) => {
//     // Modify request config if needed (e.g., adding headers)
//     return config;
//   },
//   (error) => {
//     // Handle request error (e.g., request config validation)
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     // Handle successful responses (e.g., extract data)
//     return response.data;
//   },
//   (error) => {
//     // Handle API errors
//     return handleApiError(error);
//   }
// );

export default instance;
