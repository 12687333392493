import styled from "styled-components";
import { Grey, Dark } from "../../../atoms/Colors";

export const SrcWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 50px 0px;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bgCard)};
`;

export const SrcInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .filter-btn {
    margin-bottom: -115px;
    min-width: 50px;
    height: 50px;
    background-color: ${(props) =>
      props.mode === "light" ? "#fff" : Grey[700]};
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    border-radius: 50%;
    display: none;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 40px 6px rgba(87, 87, 87, 0.12);
  }
  @media (max-width: 768px) {
    .filter-btn {
      display: flex;
    }
  }
`;

export const SrcInput = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 14px;
  margin: auto;
  width: 85%;
  max-width: 600px;
  box-shadow: 0px 10px 40px 6px rgba(87, 87, 87, 0.12);
  background-color: ${(props) => (props.mode === "light" ? "#fff" : Grey[700])};
  color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
  border-radius: 30px;
  form {
    position: relative;
    width: 100%;
  }
  input {
    width: 100%;
    height: 50px;
    border: 0px;
    background: none;
    padding: 0px 8px;
    font-size: 16px;
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};

    ::placeholder {
      font-size: 14px;
      color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    }
    &:focus {
      outline: 0px;
    }
  }
  margin-top: 40px;
  margin-bottom: -75px;
`;

export const SrcContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1440px) {
    margin: auto 88px;
  }

  @media (max-width: 1000px) {
    margin: auto 56px;
  }

  @media (max-width: 567px) {
    margin: auto 20px;
  }
`;

export const SrcLayout = styled.div`
  display: flex;
  padding-bottom: 50px;
  .sidebar {
    width: 300px;
  }
  .main {
    width: 100%;
  }
  .close {
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
    display: none;
  }
  .filter {
    width: 100%;
    border: 1px solid
      ${(props) => (props.mode === "light" ? Grey[800] : Grey[50])};
    color: ${(props) => (props.mode === "light" ? Grey[800] : Grey[50])};
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px 0px;
    border-radius: 6px;
    margin-top: 50px;
    margin-bottom: -30px;
    cursor: pointer;
    display: none;
  }
  @media (max-width: 768px) {
    .filter {
      display: flex;
    }
    .close {
      display: block;
    }
    .sidebar {
      /* display: none; */
      position: fixed;
      background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[800])};
      z-index: 100;
      top: 0px;
      left: -320px;
      height: 100%;
      width: 320px;
      padding: 28px;
      box-sizing: border-box;
      transition: 0.5s;
      box-shadow: 10px 10px 40px 6px rgba(87, 87, 87, 0.12);
    }
    .show {
      /* display: block; */
      left: 0px;
      transition: 0.5s;
    }
  }
`;

export const GridRow = styled.div`
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: auto auto;
  > div {
    padding: 20px;
    box-sizing: border-box !important;
  }

  @media (max-width: 1000px) {
    grid-template-columns: auto;

    > div {
      padding: 10px;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;
