import styled from "styled-components";
import { Error, Info, Success, Warning } from "../../atoms/Colors";

export const Wrapper = styled.div`
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: ${Error[600]};
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${Success[600]};
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background: ${Info[600]};
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background: ${Warning[600]};
  }
`;
