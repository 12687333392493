import {
  JobsCard,
  NewJobTag,
  TalentDp,
  JobContent,
  CompanyDetail,
  TimeTag,
} from "./JobCardStyle";
import { useSelector } from "react-redux";
import Badge from "../../atoms/Badge/Badge";
import Typography from "../../atoms/Typography/Typography";
import moment from "moment/moment";

const JobCard = ({
  variant = "",
  vertical,
  isNew,
  role,
  salaryFrom,
  salaryTo,
  currency,
  jobType,
  createdAt,
  jobTime,
  logo,
  firm,
  ...props
}) => {
  const mode = useSelector((state) => state.mode);

  return (
    <div>
      <JobsCard mode={mode} variant={variant} vertical={vertical} {...props}>
        {isNew && <NewJobTag>new!</NewJobTag>}
        <TalentDp bg={logo} />
        <JobContent>
          <Typography variant="p2">{firm}</Typography>
          <Typography variant="h5" weight="bold">
            {role}
          </Typography>
          <Typography variant="p3" weight="bold">
            {currency}
            {Number(salaryFrom).toLocaleString()} - {currency}
            {Number(salaryTo).toLocaleString()}
          </Typography>
          <TimeTag>
            <CompanyDetail>
              <Badge variant="purple">{jobTime}</Badge> <Badge>{jobType}</Badge>
            </CompanyDetail>
            <Typography variant="small1" weight="bold">
              {moment(createdAt).format("MMM Do")}
            </Typography>
          </TimeTag>
        </JobContent>
      </JobsCard>
    </div>
  );
};

export default JobCard;
