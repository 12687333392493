import Typography from "../../../atoms/Typography/Typography";
import { SrcLayout, GridRow, SrcContainer } from "./SearchStyle";
import FormRadio from "../../../atoms/radio/FormRadio";
import { useEffect, useState } from "react";
import CourseTabs from "../../../organisms/tabs/CourseTabs";
import { useSearchParams, useLocation, Link } from "react-router-dom";
import useFetch from "../../../../api/useFetch";

import img1 from "./../../../../assets/images/bootcamp/img1.png";
import img2 from "./../../../../assets/images/bootcamp/img2.png";
import img3 from "./../../../../assets/images/bootcamp/img3.png";
import BootcampCard from "../../../molecules/cards/Bootcamp";
import { BootcampGrid } from "../bootcamp/BootcampStyle";
import CourseCard from "../../../molecules/cards/CourseCard";
import CareerCard from "../../../molecules/cards/CareerCard";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const CoursesCom = () => {
  let location = useLocation();

  const { data, loading, error } = useFetch(
    `/courses${
      location.search === "" ? "?q=" : location.search
    }&page=1&limit=10`
  );

  if (loading) {
    return (
      <GridRow>
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
      </GridRow>
    );
  }

  if (error) {
    return <div>Oops! incorrect url, try and search again</div>;
  }

  return (
    <>
      <GridRow>
        {data.data.map((course, key) => (
          <div key={key}>
            <Link to={`/courses/${course.slug}`}>
              <CourseCard
                bgImg={course.avatar && course.avatar.url}
                type={!course.paid && "Free"}
                title={course.title}
                intructor={course.instructorID.name}
                desc={course.description}
                // masterClass={!course.paid && true}
              />
            </Link>
          </div>
        ))}
      </GridRow>
    </>
  );
};

const CareerCom = () => {
  let location = useLocation();

  const { data, loading, error } = useFetch(
    `/career-paths${
      location.search === "" ? "?q=" : location.search
    }&page=1&limit=10`
  );

  if (loading) {
    return (
      <GridRow>
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
      </GridRow>
    );
  }

  if (error) {
    return <div>Oops! incorrect url, try and search again</div>;
  }

  return (
    <GridRow>
      {data.data.map((val, key) => (
        <div key={key}>
          <Link to={`/courses/career-paths/${val.slug}`}>
            <CareerCard
              bgImg={val.avatar.url}
              title={val.title}
              tutor={val.course_Ids.length}
              courses={val.course_Ids.length}
              desc={val.description}
            />
          </Link>
        </div>
      ))}
    </GridRow>
  );
};

const BootcampCom = () => {
  let location = useLocation();

  const { data, loading, error } = useFetch(
    `/bootcamps${
      location.search === "" ? "?q=" : location.search
    }&page=1&limit=10`
  );

  if (loading) {
    return (
      <GridRow>
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
        <CardSkeleton height="380px" />
      </GridRow>
    );
  }

  if (error) {
    return <div>Oops! incorrect url, try and search again</div>;
  }

  return (
    <GridRow>
      {data.data.map((val, key) => (
        <div key={key}>
          <Link to={`/courses/bootcamps/${val.slug}`}>
            <BootcampCard
              bgImg={val.avatar.url}
              type={val.type}
              title={val.title}
              date={val.date}
              desc={val.description}
              masterClass={val.masterClass}
            />
          </Link>
        </div>
      ))}
    </GridRow>
  );
};

const SearchLayout = ({ mode, toggleSidebar, isSidebarOpen }) => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [type, setType] = useState("");
  const [cat, setCat] = useState("");
  const [bootcampMode, setBootcampMode] = useState("");
  const [searchParams, setSeachParams] = useSearchParams();

  const { data, loading, error } = useFetch("/course-categories");

  const handleUrlChange = (update, val, field) => {
    update(val);
    const queryParams = Object.fromEntries([...searchParams]);
    if (queryParams[field]) {
      // update
      queryParams[field] = val;
      setSeachParams(queryParams);
    } else {
      // new
      setSeachParams({ ...queryParams, [field]: [val] });
    }
  };

  useEffect(() => {
    //select category
    if (searchParams.get("cat")) {
      setCat(searchParams.get("cat"));
    }
    //select pricing
    if (searchParams.get("paid")) {
      setType(searchParams.get("paid"));
    }
    //select mode
    if (searchParams.get("mode")) {
      setBootcampMode(searchParams.get("mode"));
    }
  }, []);

  return (
    <div>
      <SrcContainer>
        <SrcLayout mode={mode}>
          <div className={`sidebar ${isSidebarOpen && "show"}`}>
            <Typography variant="h5" weight="bold">
              Filter By:
            </Typography>

            <FeatherIcon icon="x" className="close" onClick={toggleSidebar} />

            <div style={{ marginTop: 30 }}>
              {loading ? (
                <>
                  <Typography
                    variant="h6"
                    weight="semi-bold"
                    style={{ margin: 0 }}
                  >
                    School
                  </Typography>

                  <CardSkeleton style={{ height: "10px", marginTop: "10px" }} />
                  <CardSkeleton style={{ height: "10px", marginTop: "10px" }} />
                  <CardSkeleton style={{ height: "10px", marginTop: "10px" }} />
                  <CardSkeleton style={{ height: "10px", marginTop: "10px" }} />
                </>
              ) : error ? (
                "Oops!"
              ) : (
                <FormRadio
                  title="School"
                  value={cat}
                  mode={mode}
                  onChange={(e) =>
                    handleUrlChange(setCat, e.target.value, "cat")
                  }
                  list={data.data.map((cat) => ({
                    label: cat.name,
                    value: cat._id,
                  }))}
                />
              )}
            </div>

            {
              //show pricing for courses only
              (!searchParams.get("type") ||
                searchParams.get("type") === "courses") && (
                <div style={{ marginTop: 30 }}>
                  <FormRadio
                    title="Pricing"
                    value={type}
                    mode={mode}
                    // onChange={(e) => setType(e.target.value)}
                    onChange={(e) =>
                      handleUrlChange(setType, e.target.value, "paid")
                    }
                    list={[
                      { label: "Free", value: false },
                      { label: "Subscribe Only", value: true },
                    ]}
                  />
                </div>
              )
            }

            {
              //show mode for bootcamps only
              searchParams.get("type") &&
                searchParams.get("type") === "bootcamps" && (
                  <div style={{ marginTop: 30 }}>
                    <FormRadio
                      title="Learning Mode"
                      value={bootcampMode}
                      mode={mode}
                      // onChange={(e) => setType(e.target.value)}
                      onChange={(e) =>
                        handleUrlChange(setBootcampMode, e.target.value, "mode")
                      }
                      list={[
                        { label: "Online", value: "online" },
                        { label: "Physical", value: "physical" },
                      ]}
                    />
                  </div>
                )
            }
          </div>
          <div className="main">
            {/* <div
              className="filter"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <FeatherIcon icon="filter" size={18} /> Filter Result
            </div> */}
            <CourseTabs
              mode={mode}
              active={
                searchParams.get("type") &&
                searchParams.get("type") === "courses"
                  ? 0
                  : searchParams.get("type") === "careerpath"
                  ? 1
                  : searchParams.get("type") === "bootcamps"
                  ? 2
                  : 0
              }
              firstCom={<CoursesCom />}
              secondCom={<CareerCom />}
              thirdCom={<BootcampCom />}
            />
          </div>
        </SrcLayout>
      </SrcContainer>
    </div>
  );
};

export default SearchLayout;
