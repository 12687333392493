import styled from "styled-components";
import { Dark, Grey } from "../../../atoms/Colors";

export const WTWrapper = styled.section`
  display: flex;
  flex: 1;
  column-gap: 40px;
  padding: 70px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
export const WTCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 20px;
  row-gap: 50px;
  background: ${(props) => (props.mode === "dark" ? Dark.bgCard : "#fff")};
  color: ${(props) => (props.mode === "dark" ? "#fff" : "#000")};
  width: 100%;
  /* content block */
  box-shadow: ${(props) =>
    props.mode === "dark"
      ? `0px 2px 10px ${Grey[900]}`
      : "0px 2px 36px #e8e6e6 "};
  border-radius: 20px;
  h6 {
    color: ${(props) => (props.mode === "dark" ? Dark[`heading`] : Grey[900])};
  }
  p {
    color: ${(props) => (props.mode === "dark" ? Dark[`text`] : Grey[500])};
  }
`;
export const WTText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
