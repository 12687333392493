import SubNotification from "./SubNotification";
import { singleRecord } from "../../../api/api";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const GenSubAlert = () => {
  const auth = useSelector((state) => state.auth);
  const [subStatus, setSubStatus] = useState("");

  const getSub = async () => {
    const res = await singleRecord(auth.token, `/sub`);
    setSubStatus(res.sub_status);
  };

  useEffect(() => {
    auth && getSub();
  }, []);

  return (
    <div>
      {subStatus === "free" ? (
        <SubNotification
          desc="As a welcome gift, You get 50% off our first month subscription."
          variant="general"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GenSubAlert;
