import { Container } from "../../../../../helpers/GlobalStyle";
import {
  CourseIncludes,
  InstructorProfile,
  LessonsWrapper,
  LessonList,
  Row,
  Schedule,
} from "./UserCourseStyle";
import Button from "../../../../atoms/Button/Button";
import { ButtonGroup } from "../../../../atoms/Button/ButtonStyle";
import Breadcrumbs from "../../../../molecules/breadcrumbs/Breadcrumbs";
import ReactPlayer from "react-player";
import Badge from "../../../../atoms/Badge/Badge";
import { Extra } from "../../../../atoms/Colors";
import Typography from "../../../../atoms/Typography/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfinity,
  faTv,
  faAward,
  faPlayCircle,
  faCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../../../molecules/progress/ProgressBar";
import { SectionWrapper } from "../../../../../helpers/GlobalStyle";
import CourseGrid from "../../../courses/courses/CourseGrid";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardSkeleton from "../../../../organisms/skeleton/CardSkeleton";
import { useState, useRef } from "react";
import {
  getCourseDuration,
  getCalCourseDuration,
  getCalProgressDuration,
} from "../../../../../helpers/getDuration";
import useUserCourseData from "./useUserCourseData";
import { create, update } from "../../../../../api/api";
import Modal from "../../../../molecules/modal/Modal";
import { CircularProgress } from "@mui/material";
import { TextArea } from "../../../../atoms/inputs/Input";
// import CourseReviewForm from "../../../../organisms/forms/users/courses/reviews/CourseReviewForm";
import ReviewStars from "../../../../molecules/review-stars/ReviewStars";
import Icons from "../../../../../assets/icons";
import useToast from "../../../../../customhooks/useToast";
import { singleRecord } from "../../../../../api/api";
import SubscriptionNotification from "../../../../molecules/notifications/SubscriptionNotification";
import TestimonyPopup from "../../../../organisms/testimonials/TestimonyPopup";

const Loader = () => {
  return (
    <div>
      <Container>
        <Row>
          <div>
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 250 }}
            />
            <CardSkeleton height="400px" style={{ marginBottom: 20 }} />
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 50 }}
            />
            <CardSkeleton
              height="20px"
              style={{ marginBottom: 20, width: "80%" }}
            />
            <CardSkeleton height="8px" style={{ marginBottom: 10 }} />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "90%" }}
            />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "80%" }}
            />
          </div>
          <div>
            <CardSkeleton height="15px" style={{ marginBottom: 20 }} />

            <CardSkeleton
              height="5px"
              style={{ marginBottom: 5, width: "100px" }}
            />

            <CardSkeleton height="5px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
          </div>
        </Row>
      </Container>
    </div>
  );
};

const UserCourse = () => {
  const mode = useSelector((state) => state.mode);
  const [subStatus, setSubStatus] = useState("");
  const [activeLesson, setActiveLesson] = useState(0);
  const [lastSeen, setLastSeen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [isLoadingCompletion, setIsLoadingCompletion] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [star, setStar] = useState(0);
  const [review, setReview] = useState("");
  const [reviewErr, setReviewErr] = useState("");
  const [isLoadingReview, setIsLoadingReview] = useState(false);
  const { url } = useParams();
  const { data, isLoading, error } = useUserCourseData(url);
  const { showToast } = useToast();
  let navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);

  const onChangeBitrate = (event) => {
    const internalPlayer = playerRef.current?.getInternalPlayer("hls");
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      // internalPlayer.currentLevel = event.target.value;
      playerRef.current.getInternalPlayer("hls").currentLevel =
        event.target.value;
    }
  };

  const saveProgress = async (mins) => {
    await create(`progress/${data._id}`, auth.token, {
      lesson_id: data.lessons[activeLesson]._id,
      progress: mins,
      status: "in progress",
    });
  };

  const onPlaying = (played) => {
    const mins = (played.playedSeconds / 60).toFixed(2);
    saveProgress(mins);
  };

  const handleReady = (timeToStart = 0) => {
    let start;
    //start video from begining if user has previously completed lesson
    if (timeToStart >= data.lessons[activeLesson].duration) {
      start = 0;
    } else {
      start = timeToStart;
    }
    if (!isReady) {
      playerRef.current.seekTo(parseFloat(start * 60));
      setIsReady(true);
    }
  };

  const progressPercentage = (courseLessons, progressLessons) => {
    const courseDuration = getCalCourseDuration(courseLessons);
    const progressDuration = getCalProgressDuration(progressLessons);
    let res = (progressDuration / courseDuration) * 100;
    if (res > 100) res = 100;
    return res.toFixed(0);
  };

  const handleComplete = async (lessons, lessons_status) => {
    //check if all lessons are complete
    const found = lessons.some(
      (lesson) =>
        lesson.status === "in progress" ||
        lesson.status === "not started" ||
        lesson.status === undefined
    );

    if (!found && lessons_status !== "completed") {
      // all course is completed
      setIsLoadingCompletion(true);
      const res = await update(auth.token, `/progress/courses/${data._id}`, {});
      if (res.data.status === "success") {
        showToast(
          "success",
          "Congratulations! You have completed your course, you can download your certificate now"
        );
        //ativate download button
        data.lessons_status = "completed";

        //open review modal if user is yet to write a review
        data.course_reviewed
          ? navigate(`/profile/courses/${data._id}/certificate`)
          : setOpenReview(true);
      }
      if (res.data.code === 101) {
        showToast(
          "info",
          "Ensure you have completed all your lessons before you can access your certificate"
        );
      }
      setIsLoadingCompletion(false);
    }
  };

  const handleEnded = async () => {
    //1) update progress at the database
    const res = await create(`progress/${data._id}`, auth.token, {
      lesson_id: data.lessons[activeLesson]._id,
      progress: data.lessons[activeLesson].duration,
      status: "completed",
    });
    //2) update client state for the lessons
    data.lessons[activeLesson].status = "completed";
    data.lessons[activeLesson].progress = data.lessons[activeLesson].duration;
    //3) update client percentage
    const percent = progressPercentage(data.lessons, data.lessons);
    setProgressPercent(percent);

    //4) play next vidoe if there is next
    const next = activeLesson + 1;
    if (data.lessons[next]) {
      setActiveLesson(next);
      setIsReady(false); //set to false to allow player jump/skip to last watch history
    }

    //5) update course as completed if all progress lesson status is completed.
    handleComplete(data.lessons, data.lessons_status);
  };

  const handleSubmitReview = async () => {
    // e.preventDefault();
    setIsLoadingReview(true);
    if (star < 1) {
      showToast("error", "You haven't selected a star yet");
    } else if (review === "") {
      setReviewErr("Please say something nice before submitting your review");
    } else {
      const res = await update(auth.token, `courses/${data._id}/reviews`, {
        star: star,
        comments: review,
      });
      if (res.status === 204) {
        showToast("success", "Review submitted successfully");
        // update state
        data.course_reviewed = true;
        setOpenReview(false);
      } else {
        //alert error
        showToast(
          "info",
          "We encountered an error submitting your review, please try again."
        );
      }
    }
    setIsLoadingReview(false);
  };

  const getSub = async () => {
    const res = await singleRecord(auth.token, `/sub`);
    setSubStatus(res.sub_status);
  };

  if (isLoading) {
    getSub();
    return <Loader />;
  }

  if (subStatus !== "active" && subStatus !== "" && data.paid) {
    return (
      <Container style={{ marginTop: "20px" }}>
        <TestimonyPopup />
        <SubscriptionNotification
          status={subStatus}
          url="/subscription"
          desc="You don't have an active Platform Subscription. Subscribe to have access to all courses and bootcamps."
        />
      </Container>
    );
  }

  if (error) {
    return (
      <div style={{ margin: "50px 0px" }}>
        <Typography variant="p2" align="center">
          {error.data.statusCode === 404
            ? "Invalid url, enure you did not alter the url and try again"
            : error.data.statusCode === 403
            ? "You can only take course you have enrolled for"
            : "something went wrong"}
        </Typography>
        <center>
          <Link to="/profile/courses">
            <Button label="Go Back To Your Courses" align="center" />
          </Link>
        </center>
      </div>
    );
  }

  if (!isLoading & !lastSeen) {
    setActiveLesson(data.lastSeenLessonIndex);
    setProgressPercent(data.percent ? data.percent : 0);
    setLastSeen(true);
  }

  return (
    <div id="no-right-click">
      <Container>
        <Row>
          <div>
            <Breadcrumbs
              label={data && data.title}
              preLabel="Courses"
              preLabelUrl="/profile/courses"
            />
            <div
              style={{
                borderRadius: "20px",
                overflow: "hidden",
                height: "auto",
                background: "#000",
                marginBottom: "20px",
              }}
            >
              <ReactPlayer
                ref={playerRef}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                width="100%"
                height="auto"
                playing={true}
                url={data.lessons.length > 0 && data.lessons[activeLesson].url}
                controls
                onReady={() => handleReady(data.lessons[activeLesson].progress)}
                onProgress={(played) => onPlaying(played)}
                progressInterval={60000}
                // progressInterval={5000}
                onEnded={handleEnded}
              />
            </div>
            <div style={{ padding: "20px 0px" }}>
              Quality:{" "}
              <select onChange={onChangeBitrate}>
                <option value={null}>auto</option>
                {playerRef.current
                  ?.getInternalPlayer("hls")
                  ?.levels.map((level, id) => {
                    return (
                      <option key={id} value={id}>
                        {level.height}
                      </option>
                    );
                  })}
              </select>
            </div>
            <Badge style={{ background: Extra.yellow, fontWeight: "bold" }}>
              Courses
            </Badge>
            <Typography
              variant="h3"
              weight="bold"
              style={{ textTransform: "capitalize" }}
            >
              {data.lessons.length > 0 && data.lessons[activeLesson].title}
            </Typography>
            <Typography variant="p2">
              {data.lessons.length > 0 &&
                data.lessons[activeLesson].description}
            </Typography>
            <CourseIncludes>
              <Typography variant="h5" weight="bold">
                This course includes
              </Typography>

              <div className="wrapper">
                <div>
                  <FontAwesomeIcon icon={faTv} />
                  <Typography variant="p2" weight="semi-bold">
                    {getCourseDuration(data.lessons)} on-demand video
                  </Typography>
                </div>

                <div>
                  <FontAwesomeIcon icon={faInfinity} />
                  <Typography variant="p2" weight="semi-bold">
                    Full lifetime access
                  </Typography>
                </div>

                <div>
                  <FontAwesomeIcon icon={faAward} />
                  <Typography variant="p2" weight="semi-bold">
                    Certificate of completion
                  </Typography>
                </div>
              </div>
            </CourseIncludes>
          </div>
          {/* sidebar */}
          <div>
            <ButtonGroup style={{ gap: "8px" }}>
              {data.lessons_status === "completed" &&
              data.course_reviewed &&
              subStatus === "active" ? (
                <Link to={`/profile/courses/${data._id}/certificate`}>
                  <Button label="Download Certificate" size="md" color="grey" />
                </Link>
              ) : (
                <Button
                  label="Download Certificate"
                  size="md"
                  color="grey"
                  // disabled={true}
                  onClick={() => {
                    showToast(
                      "info",
                      "You will have to have an active subscription, complete the course & write a review first."
                    );
                  }}
                />
              )}

              <Button
                label="Leave Review"
                size="md"
                onClick={() => setOpenReview(!openReview)}
              />
            </ButtonGroup>

            <Typography variant="p2" weight="bold">
              Your Progress - {`${progressPercent}%`}
            </Typography>
            <ProgressBar progress={`${progressPercent}%`} />

            <LessonsWrapper>
              {data.lessons.map((lesson, i) => (
                <LessonList
                  mode={mode}
                  key={i}
                  className={i === activeLesson && "active"}
                  onClick={() => {
                    setIsReady(false);
                    setActiveLesson(i);
                  }}
                >
                  <div className="details">
                    <FontAwesomeIcon icon={faPlayCircle} />
                    <div className="title">
                      <Typography variant="p1">{lesson.title}</Typography>
                      <Typography variant="small1" weight="semi-bold">
                        {`${lesson.duration}`.replace(".", ":")} mins
                      </Typography>
                    </div>
                  </div>
                  {lesson.status === "in progress" && (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="progress pending"
                    />
                  )}

                  {lesson.status === "completed" && (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="progress success"
                    />
                  )}
                </LessonList>
              ))}
            </LessonsWrapper>
          </div>
        </Row>

        <Schedule mode={mode}>
          <InstructorProfile bg={data.instructorID.avatar.url}>
            <div className="profile-img"></div>
            <div className="details">
              <Typography
                variant="h6"
                weight="bold"
                style={{ textTransform: "capitalize" }}
              >
                {data.instructorID.name}
              </Typography>
              <Typography variant="p2">Instructor</Typography>
            </div>
          </InstructorProfile>
          <Typography
            variant="h3"
            weight="bold"
            style={{ textTransform: "capitalize" }}
          >
            Schedule an appointment
          </Typography>

          <Typography variant="p2">
            Anytime you feel confused or have questions, feel free to schedule a
            1:1 online session with your instructor or a mentor.
          </Typography>
          {subStatus === "active" ? (
            <a href="https://calendly.com/nerdyeye/30min" target="_blank">
              <Button label="Schedule Now" variant="outline" />
            </a>
          ) : (
            <Button
              label="Schedule Now"
              variant="outline"
              onClick={() => {
                showToast(
                  "info",
                  "You will have to have an active subscription to schedule appointment."
                );
              }}
            />
          )}
        </Schedule>
      </Container>

      <SectionWrapper>
        <CourseGrid
          title="Related Courses"
          desc="Get access to project based courses and learn by doing"
          url="courses/random-courses?type=courses&limit=4"
        />
      </SectionWrapper>

      {/* modal for completing course */}
      {isLoadingCompletion && (
        <Modal
          open={true}
          content={
            <div>
              <Typography variant="h6" weight="bold" align="center">
                <CircularProgress color="inherit" size={14} /> Please Wait...
              </Typography>
              <Typography variant="p2" align="center">
                We are checking if you have completed your course
              </Typography>
            </div>
          }
        />
      )}

      {/* modal for review */}
      <Modal
        open={openReview}
        content={
          <>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                float: "right",
              }}
              onClick={() => setOpenReview(!openReview)}
            />
            {/* <CourseReviewForm /> */}
            <div>
              <center>
                <Icons.Avatar style={{ width: "300px" }} />
              </center>
              <Typography variant="h6" weight="bold" align="center">
                Leave a Review
              </Typography>
              <Typography variant="p2" align="center">
                How would you rate your overall learning experience?
              </Typography>
              <ReviewStars
                star={star}
                changeOne={() => setStar(1)}
                changeTwo={() => setStar(2)}
                changeThree={() => setStar(3)}
                changeFour={() => setStar(4)}
                changeFive={() => setStar(5)}
              />
              <TextArea
                placeholder="Say something nice here..."
                state={reviewErr ? "error" : ""}
                desc={reviewErr}
                onChange={(e) => {
                  setReview(e.target.value);
                  setReviewErr("");
                }}
              >
                {review}
              </TextArea>
              <Button
                label="Submit Review"
                isLoading={isLoadingReview}
                onClick={handleSubmitReview}
                block
              />
            </div>
          </>
        }
        onClose={() => setOpenReview(!openReview)}
      />
    </div>
  );
};

export default UserCourse;
