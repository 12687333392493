import styled from "styled-components";
import { Grey, Dark } from "../../atoms/Colors";

export const AccordionWrapper = styled.div`
  .MuiAccordion-root {
    box-shadow: none;
    background: none;
    /* background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bg)}; */
  }
  .MuiSvgIcon-fontSizeMedium {
    color: "#fff" !important;
    fill: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  }

  .title,
  svg {
    color: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  }
`;
