import { ReactComponent as HeroLine } from "./heroline.svg";
import { ReactComponent as Hire } from "./hire.svg";
import { ReactComponent as Discussion } from "./discussion.svg";
import { ReactComponent as EveryUser } from "./every-user.svg";
import { ReactComponent as Support } from "./support.svg";
import { ReactComponent as Jobs } from "./jobs.svg";
import { ReactComponent as Mentorship } from "./mentorship.svg";
import { ReactComponent as Partner } from "./partner.svg";
import { ReactComponent as Resume } from "./resume.svg";
import { ReactComponent as Group } from "./group.svg";
import { ReactComponent as SubArrow } from "./sub-arrow.svg";

export default {
  HeroLine,
  Discussion,
  EveryUser,
  Hire,
  Support,
  Jobs,
  Mentorship,
  Partner,
  Resume,
  Group,
  SubArrow,
};
