import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Container, SectionWrapper } from "../../../../../helpers/GlobalStyle";
import Typography from "../../../../atoms/Typography/Typography";
import { VTCard, VTWrapper } from "./VideoTestimonialsStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import tosin from "./../../../../../assets/images/video-testimonials/Oluwatosin.png";
import tofunmi from "./../../../../../assets/images/video-testimonials/tofunmi.png";
import dotun from "./../../../../../assets/images/video-testimonials/dotun.png";
import loretta from "./../../../../../assets/images/video-testimonials/loretta.png";

const Testimonial = ({ bg, vidoe, name, role }) => {
  const [playMovie, setPlayMovie] = useState(false);

  //get youtube id from url
  var rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const youTubeID = vidoe.match(rx);

  return (
    <>
      <div>
        <VTCard bg={bg}>
          <div className="overlay" onClick={() => setPlayMovie(true)}>
            <div className="play">
              <FontAwesomeIcon icon={faPlay} />
            </div>
          </div>
        </VTCard>
        <Typography
          variant="p2"
          weight="bold"
          style={{ margin: 0, textTransform: "capitalize" }}
        >
          {name}
        </Typography>
        <Typography variant="small2" style={{ margin: 0 }}>
          {role}
        </Typography>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay={true}
        isOpen={playMovie}
        videoId={youTubeID && youTubeID[1]}
        onClose={() => setPlayMovie(false)}
      />
    </>
  );
};
const VideoTestimonials = () => {
  const data = [
    {
      bg: tosin,
      name: "oluwatosin",
      role: "UI/UX Designer",
      video: "https://www.youtube.com/watch?v=lk3gkYciMBs&t=3s",
    },
    {
      bg: loretta,
      name: "loretta",
      role: "Web Developer",
      video: "https://www.youtube.com/watch?v=xfvA7PNtPJY",
    },
    {
      bg: dotun,
      name: "dotun",
      role: "Web Developer",
      video: "https://www.youtube.com/watch?v=4XW-o2xIVB8",
    },

    {
      bg: tofunmi,
      name: "tofunmi",
      role: "UI/UX Designer",
      video: "https://www.youtube.com/watch?v=noBtQc_CLJc",
    },
  ];
  return (
    <div>
      <SectionWrapper>
        <Container>
          <Typography variant="h3" weight="bold">
            Meet our students & hear what they are saying about us
          </Typography>
          <Typography variant="p2">
            We are super excited to have trained people whose life have been
            changed through our courses and bootcamps. Here are few of the
            stories
          </Typography>

          <VTWrapper>
            {data.map((testimonial, i) => (
              <Testimonial
                bg={testimonial.bg}
                vidoe={testimonial.video}
                name={testimonial.name}
                role={testimonial.role}
              />
            ))}
          </VTWrapper>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default VideoTestimonials;
