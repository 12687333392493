import styled from "styled-components";
import { Grey, Dark, Extra, Primary } from "../../../atoms/Colors";

export const ProfileHeader = styled.div`
  margin-bottom: 56px;
  border-bottom: 2px solid
    ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
`;

export const Wrapper = styled.div`
  margin: auto;
  max-width: 911px;
`;

export const ProfileImg = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
  border: 7px solid ${(props) => (props.mode === "light" ? "#fff" : Grey[700])};
  background: #ccc;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0px 37px 90px 6px rgba(87, 87, 87, 0.12);
`;

export const AccordionWrapper = styled.div`
  .MuiAccordion-root {
    box-shadow: none;
    background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bg)};
  }
  .MuiSvgIcon-fontSizeMedium {
    color: "#fff" !important;
    fill: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  }

  .title,
  svg {
    color: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
  }
`;

export const PortWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const PortIcon = styled.div`
  width: 36px;
  height: 36px;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[600])};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Grey[100])};
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 88px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
  padding: 20px 40px;
  &.active {
    border-bottom: 2px solid ${Primary[500]};
    color: ${Primary[500]};
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const EditIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bgCard)};
  right: -2px;
  bottom: 15px;
  border: 5px solid ${(props) => (props.mode === "light" ? "#fff" : Grey[700])};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Primary[500]};
`;
