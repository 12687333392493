import styled from "styled-components";
import { Dark, Grey, Primary } from "../../atoms/Colors";

export const TableWrapper = styled.div`
  .hmYcRk {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .rdt_TableRow,
  .rdt_Table > div,
  .rdt_TableHeadRow,
  .rdt_Pagination {
    background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bgCard)};
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    border-top-color: ${(props) =>
      props.mode === "light" ? Grey[100] : Grey[900]};
    border-bottom-color: ${(props) =>
      props.mode === "light" ? Grey[100] : Grey[900]};
  }
  nav {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .src {
    height: 40px;
    border: 0px;
    float: right;
    margin-bottom: 20px;
    padding: 0px 14px;
    border-radius: 10px;
    color: ${(props) => (props.mode === "light" ? Grey[500] : Dark.text)};
    background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bg)};
    &:focus {
      outline: 0px;
    }
  }
`;

export const TableActionStyle = styled.div`
  display: flex;
  width: 40px;
  justify-content: space-between;
  cursor: pointer;
  a {
    color: ${Grey[600]};
  }
  > svg:hover,
  a:hover {
    color: ${Primary[500]};
  }
`;
