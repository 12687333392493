export const light = () => {
  return {
    type: "light",
  };
};

export const dark = () => {
  return {
    type: "dark",
  };
};
