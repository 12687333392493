import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authReducer";
import modeReducer from "./modeReducer";
import forgotpwdReducer from "./forgotpwdReducer";
import toastReducer from "./toastReducer";
import dataPersistReducer from "./dataPersistReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "mode", "dataPersist"], //each reducer would go in here
};

const rootReducers = combineReducers({
  auth: authReducer,
  mode: modeReducer,
  fPwd: forgotpwdReducer,
  toast: toastReducer,
  dataPersist: dataPersistReducer,
});

export default persistReducer(persistConfig, rootReducers);
