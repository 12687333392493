import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ProfileWrapper,
  Profile,
  MenuDropdown,
  MenuItemWrapper,
} from "./UserMenuStyle";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../actions/auth";
import axios from "./../../../axios/axios";

const UserMenu = () => {
  const [menuShow, setMenuShow] = useState(false);
  const [user, setUser] = useState(null);
  const menuRef = useRef(null);
  const profileRef = useRef();
  const arrowRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const mode = useSelector((state) => state.mode);
  let navigate = useNavigate();

  const handleOutsideClick = (event) => {
    if (
      menuRef.current.contains(event.target) ||
      profileRef.current.contains(event.target) ||
      arrowRef.current.contains(event.target)
    ) {
      // console.log("");
    } else {
      setMenuShow(false);
    }
  };

  const getUser = async () => {
    const res = await axios.get(`/users/${auth.user._id}`);
    setUser(res.data.data);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick, true);
    getUser();
  }, []);

  return (
    <>
      <ProfileWrapper>
        <Profile
          className="active"
          onClick={() => setMenuShow(!menuShow)}
          ref={profileRef}
          style={{
            backgroundImage:
              user !== null && user.avatar !== undefined
                ? `url(${user.avatar.url})`
                : "",
          }}
        />
        <span ref={arrowRef}>
          <FeatherIcon
            icon={`${menuShow ? "chevron-up" : "chevron-down"}`}
            onClick={() => setMenuShow(!menuShow)}
          />
        </span>

        <MenuDropdown
          style={{ display: `${menuShow ? "block" : "none"}` }}
          ref={menuRef}
          mode={mode}
        >
          <Link to="/profile">
            <MenuItemWrapper mode={mode}>
              <FeatherIcon icon="user" size="20" />
              profile
            </MenuItemWrapper>
          </Link>

          <Link to="/profile/courses">
            <MenuItemWrapper mode={mode}>
              <FeatherIcon icon="book-open" size="20" />
              My Learning
            </MenuItemWrapper>
          </Link>

          {/* <Link to="/profile/jobs">
            <MenuItemWrapper mode={mode}>
              <FeatherIcon icon="briefcase" size="20" />
              Jobs
            </MenuItemWrapper>
          </Link> */}

          <Link to="/profile/billing">
            <MenuItemWrapper mode={mode}>
              <FeatherIcon icon="credit-card" size="20" />
              billing
            </MenuItemWrapper>
          </Link>

          {/* <Link to="/settings">
            <MenuItemWrapper mode={mode}>
              <FeatherIcon icon="settings" size="20" />
              settings
            </MenuItemWrapper>
          </Link>

          <MenuItemWrapper mode={mode}>
            <FeatherIcon icon="moon" size="20" />
            dark mode
          </MenuItemWrapper> */}

          <MenuItemWrapper onClick={handleLogout} mode={mode}>
            <FeatherIcon icon="log-out" size="20" />
            log out
          </MenuItemWrapper>
        </MenuDropdown>
      </ProfileWrapper>
    </>
  );
};

export default UserMenu;
