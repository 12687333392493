import styled from "styled-components";
import { Error, Extra } from "../../../atoms/Colors";

export const NextHireWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${Extra[`purple`]};
  border-radius: 60px;
  /* padding: 100px 234px; */
  padding: 100px 0px;
  .hire-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Extra[`yellow`]};
    font-weight: bold;
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    :hover {
      background: ${Error[700]};
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;
