import { useDispatch } from "react-redux";
import { displayToast } from "../actions/toast";

const useToast = () => {
  let dispatch = useDispatch();

  const showToast = (type, msg) => {
    dispatch(displayToast({ show: true, type, msg }));

    setInterval(() => {
      dispatch(displayToast({ show: false, type: "", msg: "" }));
    }, 5000);
  };

  return { showToast };
};

export default useToast;
