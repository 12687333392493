import styled from "styled-components";
import { Success, Error } from "../../../atoms/Colors";

export const SubDetails = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const SubBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: ${(props) => (props.sub === "active" ? Success[500] : Error[500])};
  }
`;

export const SubBadge = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: ${(props) =>
    props.sub === "active" ? "none" : `1px solid ${Error[500]}`};
  background: ${(props) => (props.sub === "active" ? Success[500] : "none")};
  color: ${(props) => (props.sub === "active" ? "#fff" : Error[500])};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const DownloadBtn = styled.div`
  cursor: pointer;
`;

export const InvoiceWrapper = styled.div`
  padding: 50px;
  width: 700px;
  height: 930px;
  .row {
    display: flex;
    /* justify-content: space-between; */
    gap: 80px;
    box-sizing: border-box;
    background: #fff;
    > div {
      flex: 1;
    }
  }
  tr {
  }
  td {
    padding: 10px 30px;
    border-bottom: 1px solid #ccc;
  }
  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    > div {
      display: flex;
      gap: 50px;
    }
  }
`;

export const InvoicePrev = styled.div`
  height: 10px;
  overflow: hidden;
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  p {
    margin: 0px;
  }
`;
