import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 40px;
  margin-top: 56px;
  @media (max-width: 768px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;

// export const
