import { SectionWrapper } from "../../../helpers/GlobalStyle";
import Typography from "../../atoms/Typography/Typography";
import { TestCardWrapper, TestCard } from "./TestimonialStyle";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useRef } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Button from "../../atoms/Button/Button";
import { ButtonGroup } from "../../atoms/Button/ButtonStyle";
import avatar from "../../../assets/images/avatars/3d.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Testimonials = ({ title, desc, data }) => {
  const owl = useRef();

  const getStar = (star) => {
    var indents = [];
    for (var i = 0; i < star; i++) {
      indents.push(<FontAwesomeIcon icon={faStar} />);
    }
    return indents;
  };

  const settings = {
    responsive: {
      0: {
        items: 1.4,
      },
    },
  };
  return (
    <SectionWrapper>
      {/* <Container> */}
      <Typography variant="h3" align="center" weight="bold">
        {title}
      </Typography>
      <Typography variant="p2" align="center">
        {desc}
      </Typography>
      <TestCardWrapper>
        <OwlCarousel
          className="owl-theme"
          nav={false}
          dots={false}
          center={true}
          items={3}
          loop
          margin={20}
          responsive={settings.responsive}
          ref={owl}
        >
          {data &&
            data.map((item, i) => (
              <TestCard key={i}>
                <div>
                  <div
                    className="profile"
                    style={{
                      background: `url(${
                        item.userId.avatar ? item.userId.avatar.url : avatar
                      })`,
                    }}
                  ></div>
                </div>
                <div className="main">
                  <Typography variant="p1">{item.comments}</Typography>
                  <Typography
                    variant="h6"
                    weight="bold"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.userId.firstName} {item.userId.lastName}
                  </Typography>
                  <div style={{ color: "gold" }}>{getStar(item.star)}</div>
                  {/* <Typography variant="p2">Creative Director, Andela</Typography> */}
                </div>
              </TestCard>
            ))}
        </OwlCarousel>
      </TestCardWrapper>

      <center>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 16,
            margin: 20,
          }}
        >
          <Button
            variant="text"
            label={<FeatherIcon icon="arrow-left" />}
            onClick={() => owl.current.prev()}
            style={{ padding: "0px" }}
          />
          <Button
            variant="text"
            label={<FeatherIcon icon="arrow-right" />}
            onClick={() => owl.current.next()}
            style={{ padding: "0px" }}
          />
        </div>
      </center>
      {/* </Container> */}
    </SectionWrapper>
  );
};

export default Testimonials;
