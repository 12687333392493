import { Container } from "../../../../../helpers/GlobalStyle";
import { Row } from "./UserCourseStyle";
import { SectionWrapper } from "../../../../../helpers/GlobalStyle";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardSkeleton from "../../../../organisms/skeleton/CardSkeleton";
import { useState, useEffect } from "react";
import { allData } from "../../../../../api/api";
//
import Career from "../../../courses/career/Career";
import MainLearningArea from "./temp/MainLearningArea";
import { singleRecord } from "../../../../../api/api";
import SubscriptionNotification from "../../../../molecules/notifications/SubscriptionNotification";
import TestimonyPopup from "../../../../organisms/testimonials/TestimonyPopup";

const Loader = () => {
  return (
    <div>
      <Container>
        <Row>
          <div>
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 250 }}
            />
            <CardSkeleton height="400px" style={{ marginBottom: 20 }} />
            <CardSkeleton
              height="10px"
              style={{ marginBottom: 20, width: 50 }}
            />
            <CardSkeleton
              height="20px"
              style={{ marginBottom: 20, width: "80%" }}
            />
            <CardSkeleton height="8px" style={{ marginBottom: 10 }} />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "90%" }}
            />
            <CardSkeleton
              height="8px"
              style={{ marginBottom: 10, width: "80%" }}
            />
          </div>
          <div>
            <CardSkeleton height="15px" style={{ marginBottom: 20 }} />

            <CardSkeleton
              height="5px"
              style={{ marginBottom: 5, width: "100px" }}
            />

            <CardSkeleton height="5px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
            <CardSkeleton height="80px" style={{ marginBottom: 20 }} />
          </div>
        </Row>
      </Container>
    </div>
  );
};

const UserPath = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { url } = useParams();
  const [data, setData] = useState();
  const [subStatus, setSubStatus] = useState("");

  let navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const getSub = async () => {
    const res = await singleRecord(auth.token, `/sub`);
    setSubStatus(res.sub_status);
  };

  const getData = async () => {
    const res = await allData(auth.token, `enrollment/career-paths/${url}`);
    if (res.status === "success") {
      setData(res.data);
      getSub();
    } else {
      navigate("/courses/search?type=career-paths");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (subStatus !== "active" && subStatus !== "") {
    return (
      <Container style={{ marginTop: "20px" }}>
        <TestimonyPopup />
        <SubscriptionNotification
          status={subStatus}
          url="/subscription"
          desc="You don't have an active Platform Subscription. Subscribe to have access to all courses and bootcamps."
        />
      </Container>
    );
  }

  return (
    <div>
      {/* main lessons area */}
      <MainLearningArea
        courses={data.career_path_id.course_Ids}
        type="career-paths"
        genData={data}
      />

      <SectionWrapper>
        <Career
          title="Related Career Paths"
          desc="Other students took the following career paths after"
          url={`career-paths/related-career-paths/${data.career_path_id._id}?limit=3`}
        />
      </SectionWrapper>
    </div>
  );
};

export default UserPath;
