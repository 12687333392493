import { Container } from "@mui/system";
import Typography from "../../../../atoms/Typography/Typography";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  ContentList,
  ContentListWrapper,
  DetailWrapper,
  InfoList,
  CourseList,
  Thumpnail,
  ThumpContent,
  BootcampDetails,
} from "./DetailsStyle";
import Button from "../../../../atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { useIsOverflow } from "../../../../../customhooks/useIsOverflow";
import { getCourseDuration } from "../../../../../helpers/getDuration";
import ModalVideo from "react-modal-video";
import moment from "moment/moment";

const CourseLessons = ({ lessons, mode }) => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  return (
    <>
      <Typography variant="h4" weight="bold" style={{ marginTop: "20px" }}>
        Course Content
      </Typography>
      <ContentListWrapper ref={ref} className={showMore && "show"} mode={mode}>
        {lessons.map((val, i) => (
          <ContentList key={i} mode={mode}>
            <div className="details">
              <FontAwesomeIcon icon={faPlayCircle} />
              <Typography variant="p2">{val.title}</Typography>
            </div>
            <span>{`${val.duration}`.replace(".", ":")}mins</span>
          </ContentList>
        ))}
        <div className="fade"></div>
      </ContentListWrapper>

      {isOverflow && (
        <center>
          <Button
            // label="See all lessons"
            label={showMore ? "Show less" : "See all lessons"}
            variant="outline"
            style={{ marginTop: "20px" }}
            onClick={() => setShowMore(!showMore)}
          />
        </center>
      )}
    </>
  );
};

const CareerPathCourses = ({ courses, mode }) => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const [playMovie, setPlayMovie] = useState(false);
  const [youTubeID, setYouTubeID] = useState("");

  const handlePlayTrailer = (url) => {
    //get youtube id from url
    var rx =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    setYouTubeID(url.match(rx));
    setPlayMovie(true);
  };

  return (
    <>
      <Typography variant="h4" weight="bold" style={{ margin: "20px 0px" }}>
        What you will learn
      </Typography>
      <ContentListWrapper ref={ref} className={showMore && "show"} mode={mode}>
        {courses.map((item, i) => (
          <CourseList key={i}>
            <Thumpnail
              bg={item.avatar.url}
              onClick={() => handlePlayTrailer(item.trailer_url)}
            >
              <div className="overlay">
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </Thumpnail>
            <ThumpContent>
              <Typography
                variant="h6"
                weight="bold"
                style={{ textTransform: "capitalize" }}
              >
                {item.title}
              </Typography>
              <Typography variant="p2">
                {item.description.substr(0, 150)}
              </Typography>
              <div className="stats">
                <InfoList>
                  <FeatherIcon icon="layers" size={20} />
                  <Typography variant="p2">
                    {item.lessons.length} Lessons
                  </Typography>
                </InfoList>
                <InfoList>
                  <FeatherIcon icon="clock" size={20} />
                  <Typography variant="p2">
                    {getCourseDuration(item.lessons)}
                  </Typography>
                </InfoList>
              </div>
            </ThumpContent>
          </CourseList>
        ))}
        <div className="fade"></div>
      </ContentListWrapper>

      {isOverflow && (
        <center>
          <Button
            // label="See all lessons"
            label={showMore ? "Show less" : "See all courses"}
            variant="outline"
            style={{ marginTop: "20px" }}
            onClick={() => setShowMore(!showMore)}
          />
        </center>
      )}

      {/* trailer modal */}
      <ModalVideo
        channel="youtube"
        autoplay={true}
        isOpen={playMovie}
        videoId={youTubeID && youTubeID[1]}
        onClose={() => setPlayMovie(false)}
      />
    </>
  );
};

const Overview = ({ title, desc, content, lessons, batches, type, mode }) => {
  return (
    <DetailWrapper>
      <Container>
        <Typography
          variant="h3"
          weight="bold"
          style={{ textTransform: "capitalize" }}
        >
          {title} overview
        </Typography>
        <Typography variant="p2">{desc}</Typography>

        {type === "bootcamp" ? (
          <>
            <BootcampDetails>
              <InfoList>
                <Typography variant="p2">
                  <strong>Start Date:</strong>{" "}
                  {batches &&
                    moment(batches.start_date).format("Do MMMM, YYYY")}
                </Typography>
              </InfoList>

              <InfoList>
                <Typography
                  variant="p2"
                  style={{ textTransform: "capitalize" }}
                >
                  <strong>Location:</strong>{" "}
                  {batches && batches.details.location}
                </Typography>
              </InfoList>

              {/* <InfoList>
                <FeatherIcon icon="layers" size={20} />
                <Typography variant="p2">{content}</Typography>
              </InfoList> */}
            </BootcampDetails>
            <Typography variant="h4" weight="bold">
              Schedule
            </Typography>
            <BootcampDetails>
              <InfoList>
                <Typography variant="p2">
                  <strong>Time Frame:</strong> {batches.details.timeframe}
                </Typography>
              </InfoList>
              <InfoList>
                <Typography
                  variant="p2"
                  style={{ textTransform: "capitalize" }}
                >
                  <strong>Intervals:</strong> {batches.details.intervals}
                </Typography>
              </InfoList>

              <InfoList>
                <Typography
                  variant="p2"
                  style={{ textTransform: "capitalize" }}
                >
                  <strong>Time:</strong> {batches.details.time}
                </Typography>
              </InfoList>
            </BootcampDetails>
          </>
        ) : (
          <InfoList>
            <FeatherIcon icon="layers" size={20} />
            <Typography variant="p2">{content}</Typography>
          </InfoList>
        )}

        {/* {type === "courses" ? (
          <CourseLessons lessons={lessons} mode={mode} />
        ) : (
          <CareerPathCourses mode={mode} courses={lessons} />
        )} */}
      </Container>
    </DetailWrapper>
  );
};

export default Overview;
