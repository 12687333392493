import styled from "styled-components";
import { Grey, Dark } from "../../../../atoms/Colors";

export const DetailWrapper = styled.div`
  padding: 100px 0px;
`;

export const InfoList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Grey[500]};
`;

export const BootcampDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 44px;
  color: ${Grey[500]};
  margin-bottom: 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
`;

export const ContentListWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow: hidden;
  margin-top: 20px;
  position: relative;
  .fade {
    position: absolute;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      ${(props) => (props.mode === "light" ? "#fff" : Dark.bg)} 80%
    );
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    cursor: pointer;
  }
  &.show {
    max-height: none;
  }
`;

export const ContentList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid
    ${(props) => (props.mode === "light" ? Grey[100] : Grey[600])};
  .details {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
  }
  :last-child {
    border: 0px;
  }
`;

export const CourseList = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border: 1px solid ${Grey[100]};
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    border: 0px;
    padding: 0px;
  }
`;

export const Thumpnail = styled.div`
  position: relative;
  width: 160px;
  height: 127px;
  background: #ccc;
  border-radius: 20px;
  overflow: hidden;
  color: #fff;
  background-image: url(${(props) => props.bg});
  /* background-size: auto 100%; */
  background-size: cover;
  transition: 0.5s;
  background-position: "center";
  cursor: pointer;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  &:hover {
    /* background-size: auto 250%; */
    transform: scale(1.1);
    transition: 0.5s;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 220px;
  }
`;

export const ThumpContent = styled.div`
  width: 100%;
  h6,
  p {
    margin: 8px 0px;
  }
  .stats {
    display: flex;
    gap: 12px;
    color: ${Grey[700]};
  }
`;
