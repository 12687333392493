import styled from "styled-components";

export const CareerGrid = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  > div {
    flex: 1;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  margin-top: 52px;
  @media (max-width: 768px) {
    overflow-x: scroll;
    flex-wrap: nowrap;
    gap: 20px;
    > div {
      min-width: 82%;
    }
  }
`;
