import { Container } from "../../../../helpers/GlobalStyle";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Typography from "./../../../atoms/Typography/Typography";
import Button from "../../../atoms/Button/Button";
import {
  Wrapper,
  BillingWrapper,
  BillingCard,
  PaymentCard,
  BillingCardBody,
  SubItemWrapper,
  ItemList,
} from "./SubscriptionStyle";
import Switch from "./../../../atoms/Switch/Switch";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import PageModal from "../../../molecules/modal/Modal";
import { singleRecord, allData, create } from "../../../../api/api";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import SubNotification from "../../../molecules/notifications/SubNotification";
import Icons from "../../../../assets/images/icons";
import {
  CheckboxInput,
  CustomSelect,
  DiscountInput,
} from "../../../atoms/inputs/Input";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import useToast from "../../../../customhooks/useToast";
import { CircularProgress } from "@mui/material";
import axios from "../../../../axios/axios";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";
import moment from "moment/moment";

const total = (platform, bootcamp, discount = 0) => {
  return Number(platform) + Number(bootcamp) - Number(discount);
};

const platformTotal = (platform, cycle, discount = 0) => {
  const result = Number(platform) * cycle - Number(discount);
  return result < 0 ? 0 : result;
};

const discountedAmt = (platform, bootcamp, percent) => {
  const sum = platform + bootcamp;
  const amt = (percent / 100) * sum;
  const result = sum - amt;
  return result < 0 ? 0 : result;
};

const discountedOnly = (platform, bootcamp, percent) => {
  const sum = platform + bootcamp;
  return (percent / 100) * sum;
};

const handleSaved = (amt, cycle) => {
  let save = 0;
  if (cycle === 1) {
    save = (0 / 100) * amt * cycle;
  }
  if (cycle === 3) {
    save = (2.5 / 100) * amt * cycle;
  }
  if (cycle === 6) {
    save = (5 / 100) * amt * cycle;
  }
  if (cycle === 12) {
    save = (10 / 100) * amt * cycle;
  }
  return save;
};

const MainContent = () => {
  const navigate = useNavigate();
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);
  const [amt, setAmt] = useState(0);
  const [bootcampAmt, setBootcampAmt] = useState(0);
  const [saved, setSaved] = useState(0);
  const [bootcampSaved, setBootcampSaved] = useState(0);
  const [subStatus, setSubStatus] = useState("");
  const [bootcamps, setBootcamps] = useState([]);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenPlatformBenefits, setIsOpenPlatformBenefits] = useState(false);
  const [isOpenBootcampBenefits, setIsOpenBootcampBenefits] = useState(false);
  const [cycle, setCycle] = useState(1);
  const [currency, setCurrency] = useState("USD");
  const [searchParams, setSeachParams] = useSearchParams();
  const isNewUser = searchParams.get("new");
  const [bootcampId, setBootcampId] = useState(searchParams.get("bid"));
  //discount code states
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeData, setDiscountCodeData] = useState(null);
  const [discountCodeState, setDiscountState] = useState("");
  const [discountCodeDesc, setDiscountDesc] = useState("");
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [addPlatformSub, setAddPlatformSub] = useState(true);
  const [hours, setHours] = useState(0);

  //payment
  const [isVerifying, setIsVerifying] = useState(false);
  const { showToast } = useToast();

  const getSubStatus = async () => {
    const res = await singleRecord(auth.token, `/sub`);
    setSubStatus(res.sub_status);
  };

  const handlePlatformAmt = () => {
    currency === "NGN"
      ? setAmt(
          process.env.REACT_APP_USD_MONTHLY * process.env.REACT_APP_FX_RATE
        )
      : setAmt(Number(process.env.REACT_APP_USD_MONTHLY) + 5);
  };

  const handleCurrencySwitch = () => {
    let amt = 0;
    let bAmt = 0;
    if (currency === "NGN") {
      amt = Number(process.env.REACT_APP_USD_MONTHLY) + 5;
      if (selectedBootcamp) {
        bAmt = selectedBootcamp.amount + 2.5;
      }
    } else {
      amt = process.env.REACT_APP_USD_MONTHLY * process.env.REACT_APP_FX_RATE;

      if (selectedBootcamp) {
        bAmt = selectedBootcamp.amount * process.env.REACT_APP_FX_RATE;
      }
    }
    setAmt(amt);
    const saves = handleSaved(amt, cycle);
    setSaved(saves);
    //bootcamp
    if (selectedBootcamp) {
      setBootcampAmt(bAmt);
      const bootcampSaves = handleSaved(bAmt, cycle);
      setBootcampSaved(bootcampSaves);
    }

    currency === "NGN" ? setCurrency("USD") : setCurrency("NGN");
  };

  const handleAutoBootcampSelect = (id, bootcamps) => {
    const selection = bootcamps.filter((bootcamp) => bootcamp.value === id);
    setSelectedBootcamp(selection[0]);
    // set
    let bAmt = 0;
    if (currency === "NGN") {
      bAmt = selection[0].amount * process.env.REACT_APP_FX_RATE;
    } else {
      bAmt = selection[0].amount + 2.5;
    }

    const bootcampSaves = handleSaved(bAmt, cycle);
    setBootcampSaved(bootcampSaves);
    setBootcampAmt(bAmt);

    setBootcampId(id);
  };

  const handleBootcampChange = (e) => {
    const id = e.target.value;
    handleAutoBootcampSelect(id, bootcamps);
  };

  const handleCycleChange = (e) => {
    let cycle = e.target.value;
    //platform
    const saves = handleSaved(amt, cycle);
    setSaved(saves);
    //bootcamp
    const bootcampSaves = handleSaved(bootcampAmt, cycle);
    setBootcampSaved(bootcampSaves);

    setCycle(cycle);
  };

  const handleApplyCode = async () => {
    if (discountCodeData) {
      //clear
      setDiscountState("");
      setDiscountCode("");
      setDiscountCodeData(null);
    } else {
      setIsApplyingDiscount(true);
      try {
        const payload = {
          code: discountCode,
        };
        const res = await create(`/discount-codes/apply`, auth.token, payload);

        if (res.status === "success") {
          setDiscountState("success");
          setDiscountCodeData(res.data);
          setDiscountDesc("");
        } else {
          setDiscountState("error");
          setDiscountDesc(res.message);
        }
      } catch (e) {
        console.log(e);
      }
      setIsApplyingDiscount(false);
    }
  };

  const getBootcamps = async () => {
    const res = await allData(auth.token, `/bootcamps`);
    const data = [];
    res.data.map((val) =>
      data.push({
        label: `${val.title} ($${val.amount + 2.5})`,
        value: val._id,
        amount: val.amount,
        url: val.slug,
      })
    );
    setBootcamps(data);
    searchParams.get("bid") &&
      handleAutoBootcampSelect(searchParams.get("bid"), data);
    setIsLoading(false);
  };

  const getHours = () => {
    let now = moment();
    let timeCreated = moment(auth.user.createdAt);

    let duration = moment.duration(now.diff(timeCreated));
    let hours = Math.floor(duration.asHours());

    setHours(hours);
  };

  const webhookPayload = {
    userID: auth.user._id,
    bootcampID: bootcampId,
    action:
      bootcampId && addPlatformSub
        ? "both"
        : bootcampId && !addPlatformSub
        ? "bootcamp"
        : "pro",
    amount:
      discountCodeData || (hours < 5 && cycle === 1)
        ? discountedAmt(
            addPlatformSub ? platformTotal(amt, cycle, saved) : 0,
            platformTotal(bootcampAmt, cycle, bootcampSaved),
            discountCodeData
              ? discountCodeData.discountPercent
              : hours < 5 && 50
          )
        : total(
            addPlatformSub ? platformTotal(amt, cycle, saved) : 0,
            platformTotal(bootcampAmt, cycle, bootcampSaved)
          ),
    platformAmount: amt * cycle,
    bootcampAmount: bootcampAmt * cycle,
    duration:
      cycle === 1
        ? "monthly"
        : cycle === 3
        ? "3months"
        : cycle === 6
        ? "6months"
        : "annually",
    mop: "flutterwave",
    plan: bootcampId ? "bootcamp" : "pro",
    status: "successful",
    isDiscount: discountCodeData ? true : false,
    discountCodeID: discountCodeData ? discountCodeData._id : null,
    discountAmount:
      discountCodeData || (hours < 5 && cycle === 1)
        ? discountedOnly(
            platformTotal(amt, cycle, saved),
            platformTotal(bootcampAmt, cycle, bootcampSaved),
            discountCodeData
              ? discountCodeData.discountPercent
              : hours < 5 && 50
          )
        : null,
  };

  const config = {
    public_key: process.env.REACT_APP_FLW_PUBLIC,
    tx_ref: Date.now(),
    amount:
      discountCodeData || (hours < 5 && cycle === 1)
        ? discountedAmt(
            addPlatformSub ? platformTotal(amt, cycle, saved) : 0,
            platformTotal(bootcampAmt, cycle, bootcampSaved),
            discountCodeData
              ? discountCodeData.discountPercent
              : hours < 5 && 50
          )
        : total(
            addPlatformSub ? platformTotal(amt, cycle, saved) : 0,
            platformTotal(bootcampAmt, cycle, bootcampSaved)
          ),
    currency: currency,
    // payment_options: "card",
    payment_options: "card, banktransfer",
    customer: {
      email: auth.user.email,
      phone_number: `+${auth.user.phone.code}${auth.user.phone.number}`,
      name: auth.user.firstName,
    },
    meta: { counsumer_id: auth.user._id, ...webhookPayload },
    customizations: {
      title: "Nerdy Eye",
      description: "Gain full access",
      logo: "https://www.nerdyeye.com/static/media/logo.68dee097f3dde8062f40.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    getSubStatus();
    handlePlatformAmt();
    getBootcamps();
    getHours();
  }, []);

  const registerPayment = async (res) => {
    setIsVerifying(true);

    const payload = {
      ...webhookPayload,
      amount: res.charged_amount,
      currency: res.currency,
      transaction_id: `${res.transaction_id}`,
      ref: res.flw_ref,
    };

    try {
      await axios.post(
        `/sub/new-sub${
          bootcampId && addPlatformSub
            ? "?action=both"
            : bootcampId && !addPlatformSub
            ? "?action=bootcamp-only"
            : ""
        }`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      // if it is bootcamp redirect to bootcamp success page
      bootcampId
        ? navigate(`/profile/bootcamps/${selectedBootcamp.url}?new=true`)
        : navigate("/profile");
    } catch (err) {
      console.log(err);
      showToast(
        "error",
        "We encountered an error while verifying your payment. Try again but if you are sure the payment went through, contact our support."
      );
    }
    setIsVerifying(false);
  };

  if (isVerifying) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <center>
          <CircularProgress color="inherit" size={28} />
        </center>
        <Typography variant="h3" align="center" weight="bold">
          Please wait!
        </Typography>
        <Typography variant="p3" weight="semi-bold" align="center">
          Verifying your payment, you will be redirected automatically.
        </Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          <center>
            <CardSkeleton height="20px" width="200px" variant="text" />
            <CardSkeleton height="40px" width="280px" variant="text" />
            <CardSkeleton height="20px" width="320px" variant="text" />
          </center>
          <BillingWrapper>
            <div>
              <CardSkeleton height="700px" />
            </div>
            <div>
              <CardSkeleton height="700px" />
            </div>
          </BillingWrapper>
        </Container>
      </Wrapper>
    );
  }

  // if (subStatus === "active") {
  //   navigate("/profile");
  // }

  return (
    <>
      <TestimonyPopup />
      <SubNotification desc="As a welcome gift, you get 50% off our first month subscription. Subscribe now before time elapses." />
      <Wrapper>
        <Container>
          <Typography variant="p3" weight="semi-bold" align="center">
            {/* Simple pricing for everyone */}
            Naira(NGN){" "}
            <Switch
              checked={currency === "NGN" ? false : true}
              onClick={handleCurrencySwitch}
            />{" "}
            Dollars(USD)
          </Typography>

          <Typography
            variant="h3"
            align="center"
            weight="bold"
            style={{ textTransform: "capitalize" }}
          >
            simple pricing for everyone
          </Typography>
          <Typography variant="p3" weight="semi-bold" align="center">
            Learn, get mentored, build portfolio & get a job with a community of
            likeminds
          </Typography>

          <BillingWrapper>
            <div>
              <BillingCard mode={mode}>
                <div>
                  <Typography variant="h6" weight="bold">
                    Platform Subscription
                  </Typography>
                  <Typography variant="p3">
                    Access to everything, paid courses, clear roadmaps, partners
                    & more
                  </Typography>

                  {subStatus === "active" && (
                    <CheckboxInput
                      label="Add Subscription"
                      checked={addPlatformSub}
                      onClick={() => setAddPlatformSub(!addPlatformSub)}
                    />
                  )}

                  <Button
                    variant="text"
                    style={{ padding: 0 }}
                    label="See feature & benefits"
                    onClick={() => setIsOpenPlatformBenefits(true)}
                  />
                </div>

                <Typography variant="h3" weight="bold">
                  {currency === "NGN" ? <>&#x20a6;</> : "$"}
                  {Number(amt).toLocaleString()}
                  <span className="duration">/ month</span>
                </Typography>
              </BillingCard>

              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  margin: "20px 0px",
                }}
              >
                <Typography variant="p3" weight="semi-bold">
                  <FontAwesomeIcon icon={faPlus} /> Select a Bootcamp
                </Typography>
                <Icons.SubArrow style={{ marginTop: 24 }} />
              </div>

              <BillingCard mode={mode}>
                <div>
                  <Typography variant="h6" weight="bold">
                    Bootcamp Subscription
                  </Typography>
                  <CustomSelect
                    list={bootcamps}
                    value={bootcampId}
                    onChange={(e) => handleBootcampChange(e)}
                  />
                  <Typography variant="p3">
                    Bootcamp comes with 1-on-1 mentorship, support groups &
                    more.
                  </Typography>
                  <Button
                    variant="text"
                    style={{ padding: 0 }}
                    label="See feature & benefits"
                    onClick={() => setIsOpenBootcampBenefits(true)}
                  />
                </div>

                <Typography variant="h3" weight="bold">
                  {currency === "NGN" ? <>&#x20a6;</> : "$"}
                  {Number(bootcampAmt).toLocaleString()}
                  <span className="duration">/ month</span>
                </Typography>
              </BillingCard>
            </div>

            <div>
              <PaymentCard mode={mode}>
                <div>
                  <CustomSelect
                    label="Billing Cycle"
                    list={[
                      { label: "1 Month (0% saved)", value: 1 },
                      { label: "3 Months (2.5% saved)", value: 3 },
                      { label: "6 Months (5% saved)", value: 6 },
                      { label: "1 Year (10% saved)", value: 12 },
                    ]}
                    value={cycle}
                    onChange={(e) => handleCycleChange(e)}
                    // desc="Increase billing cycle to reduce fee"
                  />
                  <Typography variant="small1" style={{ marginTop: -10 }}>
                    Increase billing cycle to reduce fee
                  </Typography>
                </div>

                <div>
                  <DiscountInput
                    mode={mode}
                    value={discountCode}
                    state={
                      hours < 5 && cycle === 1 ? "firstUser" : discountCodeState
                    }
                    // state={"success"}
                    saved={
                      discountCodeData
                        ? discountCodeData.discountPercent
                        : hours < 5 && cycle === 1 && 50
                    }
                    desc={discountCodeDesc}
                    isLoading={isApplyingDiscount}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    onSubmit={handleApplyCode}
                  />
                </div>

                <div>
                  <ItemList>
                    <div>
                      <Typography
                        variant="p3"
                        weight="semi-bold"
                        style={{ margin: 0 }}
                      >
                        {addPlatformSub ? (
                          "Platform Subscription"
                        ) : (
                          <s>Platform Subscription</s>
                        )}
                      </Typography>
                    </div>

                    <Typography variant="p3" weight="bold">
                      {currency === "NGN" ? <>&#x20a6;</> : "$"}
                      {addPlatformSub
                        ? platformTotal(amt, cycle, saved).toLocaleString()
                        : 0}
                    </Typography>
                  </ItemList>

                  <ItemList>
                    <Typography variant="p3" weight="semi-bold">
                      Bootcamp Subscription
                    </Typography>
                    <Typography variant="p3" weight="bold">
                      {currency === "NGN" ? <>&#x20a6;</> : "$"}
                      {platformTotal(
                        bootcampAmt,
                        cycle,
                        bootcampSaved
                      ).toLocaleString()}
                    </Typography>
                  </ItemList>

                  {(discountCodeData || (hours < 5 && cycle === 1)) && (
                    <ItemList>
                      <Typography variant="p3" weight="semi-bold">
                        Discount
                      </Typography>
                      <Typography variant="p3" weight="bold">
                        -{currency === "NGN" ? <>&#x20a6;</> : "$"}
                        {discountedOnly(
                          addPlatformSub ? platformTotal(amt, cycle, saved) : 0,
                          platformTotal(bootcampAmt, cycle, bootcampSaved),
                          discountCodeData
                            ? discountCodeData.discountPercent
                            : hours < 5 && 50
                        ).toLocaleString()}
                      </Typography>
                    </ItemList>
                  )}

                  <ItemList className="total">
                    <Typography variant="p2" weight="bold">
                      Total
                    </Typography>
                    <Typography variant="p2" weight="bold">
                      {currency === "NGN" ? <>&#x20a6;</> : "$"}
                      {discountCodeData || (hours < 5 && cycle === 1)
                        ? discountedAmt(
                            addPlatformSub
                              ? platformTotal(amt, cycle, saved)
                              : 0,
                            platformTotal(bootcampAmt, cycle, bootcampSaved),
                            discountCodeData
                              ? discountCodeData.discountPercent
                              : hours < 5 && 50
                          ).toLocaleString()
                        : total(
                            addPlatformSub
                              ? platformTotal(amt, cycle, saved)
                              : 0,
                            platformTotal(bootcampAmt, cycle, bootcampSaved)
                          ).toLocaleString()}
                    </Typography>
                  </ItemList>
                </div>

                <Button
                  label="Pay Now"
                  onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                        registerPayment(response);
                        closePaymentModal(); // this will close the modal programmatically
                      },
                      onClose: () => {},
                    });
                  }}
                  block
                />
                <Typography variant="p3" weight="semi-bold" align="center">
                  Need help?{" "}
                  <a
                    href="https://wa.me/2348127244042"
                    target="_blank"
                    style={{ color: "green" }}
                  >
                    Chat us on WhatsApp
                  </a>
                </Typography>
              </PaymentCard>
              {isNewUser && (
                <Link to="/profile">
                  <Button
                    label="Skip For Now"
                    color="grey"
                    block
                    variant="text"
                  />
                </Link>
              )}
            </div>
          </BillingWrapper>
        </Container>
      </Wrapper>
      {/* <PageModal
        isOpen={openModal}
        content={<PaymentPopUp isNigeria={currency === "NGN" ? true : false} />}
        onClose={() => setOpenModal(false)}
      /> */}
      <PageModal
        isOpen={isOpenPlatformBenefits}
        maxWidth="sm"
        content={
          <>
            <ItemList>
              <Typography variant="h6" weight="bold">
                Platform Benefits
              </Typography>
              <FeatherIcon
                icon="x"
                onClick={() => setIsOpenPlatformBenefits(false)}
              />
            </ItemList>
            <BillingCardBody mode={mode}>
              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Join our community
                </Typography>
              </SubItemWrapper>
              <Typography
                variant="h6"
                weight="bold"
                style={{ margin: "20px 0px" }}
              >
                Learning
              </Typography>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  All courses
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Clear career roadmap
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Career paths
                </Typography>
              </SubItemWrapper>
              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Certificate of Completion
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Online Bootcamps
                </Typography>
              </SubItemWrapper>

              <Typography
                variant="h6"
                weight="bold"
                style={{ margin: "20px 0px" }}
              >
                Portfolio Building
              </Typography>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Project recommendation
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Portfolio Resources
                </Typography>
              </SubItemWrapper>

              <Typography
                variant="h6"
                weight="bold"
                style={{ margin: "20px 0px" }}
              >
                Landing A Job
              </Typography>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Resume drafting resources & templates
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Platform job recommendation
                </Typography>
              </SubItemWrapper>
            </BillingCardBody>
          </>
        }
        onClose={() => setIsOpenPlatformBenefits(false)}
      />

      <PageModal
        isOpen={isOpenBootcampBenefits}
        maxWidth="sm"
        content={
          <>
            <ItemList>
              <Typography variant="h6" weight="bold">
                Bootcamp Benefits
              </Typography>
              <FeatherIcon
                icon="x"
                onClick={() => setIsOpenBootcampBenefits(false)}
              />
            </ItemList>
            <BillingCardBody mode={mode}>
              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Everything in Courses & Career Path
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Private Support Group
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  1 on 1 Mentorship
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Accountability Partner
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Weekly Live Classes
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Scheduled Timeline (Not Self Paced)
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Personalized Portfolio Review
                </Typography>
              </SubItemWrapper>

              <SubItemWrapper mode={mode}>
                <FontAwesomeIcon icon={faCircleCheck} className="check" />
                <Typography variant="p3" weight="semi-bold">
                  Personalised Job Support
                </Typography>
              </SubItemWrapper>
            </BillingCardBody>
          </>
        }
        onClose={() => setIsOpenBootcampBenefits(false)}
      />
    </>
  );
};

const Subscription = () => {
  return <MainContent />;
};

export default Subscription;
