import { useState } from "react";
import { ReviewStarsWrapper } from "./ReviewStarsStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const ReviewStars = ({
  star,
  changeOne,
  changeTwo,
  changeThree,
  changeFour,
  changeFive,
  ...props
}) => {
  const mode = useSelector((state) => state.mode);

  return (
    <div>
      <ReviewStarsWrapper {...props} mode={mode}>
        {/* add active class to button element */}
        <button className={star === 5 && "active"}>
          <FontAwesomeIcon icon={faStar} onClick={changeFive} />
        </button>
        <button className={star === 4 && "active"}>
          <FontAwesomeIcon icon={faStar} onClick={changeFour} />
        </button>
        <button className={star === 3 && "active"}>
          <FontAwesomeIcon icon={faStar} onClick={changeThree} />
        </button>
        <button className={star === 2 && "active"}>
          <FontAwesomeIcon icon={faStar} onClick={changeTwo} />
        </button>
        <button className={star === 1 && "active"}>
          <FontAwesomeIcon icon={faStar} onClick={changeOne} />
        </button>
      </ReviewStarsWrapper>
    </div>
  );
};

export default ReviewStars;
