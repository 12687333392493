import styled from "styled-components";
import { Grey } from "../../../atoms/Colors";

export const TPipelineWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 70px 0;
  column-gap: 30px;
  > div {
    flex: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
export const Pipeline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 20px;
  padding: 0px;
  .pipeline-btn {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 220px;

    @media (max-width: 768px) {
      margin: auto;
      max-width: 205px;
    }
  }
`;

export const TermCard = styled.div`
  display: flex;
  background: ${(props) => (props.mode === "dark" ? Grey[800] : Grey[50])};
  padding: 15px;
  column-gap: 30px;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
`;

export const TermCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.mode === "dark" ? Grey[700] : Grey[100])};
  color: ${(props) => (props.mode === "dark" ? "#fff" : Grey[900])};
  border-radius: 50px;
  width: 50px;
  height: 50px;
`;

export const PipelineDp = styled.div`
  background: ${Grey[500]};
  border: 5px solid #000000;
  filter: drop-shadow(-20px -20px 0px #000000);
  border-radius: 30px;
  width: 100%;
  height: 550px;
  background: #ccc;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;
