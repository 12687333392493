import styled from "styled-components";
import { Error, Grey, Primary } from "../../atoms/Colors";

export const FileField = styled.div`
  width: 100%;
  height: 228px;
  border: ${(props) =>
    props.state === "error"
      ? `1px dashed ${Error[500]}`
      : `1px dashed ${Grey[300]}`};
  border-radius: 25px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[200])};
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 44px 20px;
  gap: 12px;
  cursor: pointer;
  p {
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[200])};
    margin: 0px;
  }
  p:nth-child(5) {
    color: ${Primary[500]};
  }
`;

export const FileName = styled.div`
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
