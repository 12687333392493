import {
  Wrapper,
  Sidebar,
  MainContent,
  MainWrapper,
  LogoWrapper,
} from "./AuthStructureStyle";
import logo from "./../../../assets/images/logo.png";
import logo2 from "./../../../assets/images/logo2.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AuthStructure = (props) => {
  const mode = useSelector((state) => state.mode);
  return (
    <Wrapper>
      <Sidebar bg={props.bg} />
      <MainContent>
        <MainWrapper>
          <Link to="/">
            <LogoWrapper>
              <img src={mode === "light" ? logo : logo2} alt="Nerdy Eye Logo" />
            </LogoWrapper>
          </Link>

          {props.main}
        </MainWrapper>
      </MainContent>
    </Wrapper>
  );
};

export default AuthStructure;
