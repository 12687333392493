import React from "react";
import { Container } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import arrow from "../../../../assets/images/arrow2.gif";
import ReactPlayer from "react-player";
import Button from "../../../atoms/Button/Button";
import { Link } from "react-router-dom";
import { Extra } from "../../../atoms/Colors";

const SalesPage = () => {
  return (
    <div style={{ padding: "100px 0px" }}>
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" weight="bold" align="center">
            Ensure you watch the video below
          </Typography>
          <img src={arrow} width="70" />
        </div>

        {/* <ReactPlayer
          width="100%"
          height="560px"
          url="https://www.youtube.com/watch?v=osKmhoZ_2-o"
        /> */}

        <iframe
          width="100%"
          height="560"
          src="https://www.youtube.com/embed/osKmhoZ_2-o?si=e60rySilggc26wQX"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <Typography
          variant="h6"
          weight="bold"
          align="center"
          style={{ marginTop: "40px" }}
        >
          Nerdy Eye vs other platforms
        </Typography>

        <Typography variant="p1" align="center">
          We are changing the way people learn online by providing support &
          community-first learning approach.You will have a mentor and can ask
          questions while you learn
        </Typography>

        <table border={1} style={{ width: "100%", border: "1px solid grey" }}>
          <tr>
            <th width="50%" style={{ padding: "20px" }}>
              <Typography variant="h6" weight="bold" align="center">
                Nerdy Eye
              </Typography>
            </th>
            <th width="50%" style={{ padding: "20px" }}>
              <Typography variant="h6" weight="bold" align="center">
                Others (YouTube, Udemy, Coursera etc.)
              </Typography>
            </th>
          </tr>

          <tr style={{ textAlign: "center" }}>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                Join Private Support Group
              </Typography>
            </td>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                No Support Group to join as you learn
              </Typography>
            </td>
          </tr>

          <tr style={{ textAlign: "center" }}>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                1 on 1 Mentorship
              </Typography>
            </td>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                No mentor & guidiance
              </Typography>
            </td>
          </tr>

          <tr style={{ textAlign: "center" }}>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                Paired up with a learning partner
              </Typography>
            </td>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                You will be alone & can't meet other students
              </Typography>
            </td>
          </tr>

          <tr style={{ textAlign: "center" }}>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                Weekly Live Classes
              </Typography>
            </td>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                You will be left to only pre-recorded videos
              </Typography>
            </td>
          </tr>

          <tr style={{ textAlign: "center" }}>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                Schedule Clarity call with instructor
              </Typography>
            </td>
            <td style={{ padding: "20px" }}>
              <Typography variant="p2" align="center">
                Can't reach out to instructor
              </Typography>
            </td>
          </tr>
        </table>

        <Typography
          variant="h6"
          weight="bold"
          align="center"
          style={{ marginTop: "40px" }}
        >
          All these just for &#x20A6;15,000 ($17.5) monthly
        </Typography>

        <Typography variant="p1" align="center">
          Don't forget you have{" "}
          <span style={{ color: Extra.yellow }}>
            <b>50% OFF</b>
          </span>{" "}
          within the next 5hrs. Click on the button below to subscribe to any of
          the upcoming/ongoing bootcamps
        </Typography>

        <Link to="/subscription?new=true">
          <Button label="Proceed To Subscribe Now" block />
        </Link>
      </Container>
    </div>
  );
};

export default SalesPage;
