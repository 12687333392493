import { Container } from "../../../../../../helpers/GlobalStyle";
import {
  CourseIncludes,
  InstructorProfile,
  LessonsWrapper,
  LessonList,
  Row,
  Schedule,
} from "./../UserCourseStyle";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { AccordionWrapper } from "../../../../../organisms/accordion/AccordionStyle";
//
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
//
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfinity,
  faTv,
  faAward,
  faPlayCircle,
  faCircleCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Grey, Dark, Extra, Warning } from "../../../../../atoms/Colors";
import Typography from "../../../../../atoms/Typography/Typography";
import Badge from "../../../../../atoms/Badge/Badge";
import Button from "../../../../../atoms/Button/Button";
import { useEffect, useRef } from "react";
import { allData, create, update } from "../../../../../../api/api";
import ProgressBar from "../../../../../molecules/progress/ProgressBar";
import {
  progressPercentage,
  getLessonStatus,
  getLessonProgress,
  getLastLesson,
  getLastCourseIndex,
  pathProgress,
} from "../../../../../../helpers/PlayLibrary";
import { getCourseDuration } from "../../../../../../helpers/getDuration";
import Breadcrumbs from "../../../../../molecules/breadcrumbs/Breadcrumbs";
import ReactPlayer from "react-player";
import useToast from "../../../../../../customhooks/useToast";
import BootcampCertAndReview from "./BootcampCertAndReview";
import Modal from "../../../../../molecules/modal/Modal";
import Icons from "../../../../../../assets/icons";
import ReviewStars from "../../../../../molecules/review-stars/ReviewStars";
import { TextArea } from "../../../../../atoms/inputs/Input";
import UserCourseLoader from "../../../../../molecules/loaders/UserCourseLoader";
import moment from "moment/moment";

const MainLearningArea = ({ courses, type, genData, groupLink, regBatch }) => {
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [activeCourse, setActiveCourse] = useState(0);
  const [activeLesson, setActiveLesson] = useState(0);
  const [expanded, setExpanded] = useState(0);
  const [progressPercentPath, setProgressPercentPath] = useState(0);
  //player
  const [isLoadingCompletion, setIsLoadingCompletion] = useState(false);
  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);
  const { showToast } = useToast();
  //review
  const [openCourseReview, setOpenCourseReview] = useState(false);
  const [reviewCourseId, setReviewCourseId] = useState(null);
  const [star, setStar] = useState(0);
  const [review, setReview] = useState("");
  const [reviewErr, setReviewErr] = useState("");
  const [isLoadingReview, setIsLoadingReview] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getProgress = async () => {
    const promises = courses.map(async (course) => {
      const result = await allData(auth.token, `progress/${course._id}`); //lesson progress data
      if (result.status === "success") {
        //check progress data is not null

        if (result.data) {
          for (let i = 0; i < course.lessons.length; i++) {
            //   get lesson watch status
            course.lessons[i].status = getLessonStatus(
              result.data.lessons,
              course.lessons[i]._id
            );
            //   get lesson watch progress
            course.lessons[i].progress = getLessonProgress(
              result.data.lessons,
              course.lessons[i]._id
            );
          }
          // add lessons status
          course.lessons_status = result.data.status;
          course.course_reviewed = result.data.reviewed;

          // add progress percent
          course.percent = progressPercentage(
            course.lessons,
            result.data.lessons
          );
        } else {
          for (let i = 0; i < course.lessons.length; i++) {
            //   get lesson watch progress - no watch history
            course.lessons[i].progress = 0;
          }
        }
      }
      return course;
    });
    // wait until all promises resolve
    const results = await Promise.all(promises);

    setData(results);
    //progress
    // pathProgress(results);
    let lastCourse = getLastCourseIndex(results);
    let lastLesson = getLastLesson(results[lastCourse].lessons);
    setActiveCourse(lastCourse);
    setExpanded(lastCourse);
    setActiveLesson(lastLesson);
    //total progress
    setProgressPercentPath(pathProgress(results));

    setIsLoading(false);
  };

  const handleReady = (timeToStart = 0) => {
    let start;
    //start video from begining if user has previously completed lesson
    if (timeToStart >= data[activeCourse].lessons[activeLesson].duration) {
      start = 0;
    } else {
      start = timeToStart;
    }
    if (!isReady) {
      playerRef.current.seekTo(parseFloat(start * 60));
      setIsReady(true);
    }
  };

  const onChangeBitrate = (event) => {
    const internalPlayer = playerRef.current?.getInternalPlayer("hls");
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      // internalPlayer.currentLevel = event.target.value;
      playerRef.current.getInternalPlayer("hls").currentLevel =
        event.target.value;
    }
  };

  const saveProgress = async (mins) => {
    try {
      await create(`progress/${data[activeCourse]._id}`, auth.token, {
        lesson_id: data[activeCourse].lessons[activeLesson]._id,
        progress: mins,
        status: "in progress",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onPlaying = (played) => {
    const mins = (played.playedSeconds / 60).toFixed(2);
    saveProgress(mins);
  };

  const handleEnded = async () => {
    //1) update progress at the database
    const res = await create(`progress/${data[activeCourse]._id}`, auth.token, {
      lesson_id: data[activeCourse].lessons[activeLesson]._id,
      progress: data[activeCourse].lessons[activeLesson].duration,
      status: "completed",
    });
    //2) update client state for the lessons
    data[activeCourse].lessons[activeLesson].status = "completed";
    data[activeCourse].lessons[activeLesson].progress =
      data[activeCourse].lessons[activeLesson].duration;
    //3) update client percentage
    const percent = progressPercentage(
      data[activeCourse].lessons,
      data[activeCourse].lessons
    );
    data[activeCourse].percent = percent;
    // course progress
    setProgressPercentPath(pathProgress(data));

    //4) play next vidoe if there is next
    const next = activeLesson + 1;
    if (data[activeCourse].lessons[next]) {
      setActiveLesson(next);
      setIsReady(false); //set to false to allow player jump/skip to last watch history
    } else {
      //no next lesson, check next course
      const nextCourse = activeCourse + 1;
      if (data[nextCourse]) {
        setActiveCourse(nextCourse);
        setActiveLesson(0);
        setExpanded(nextCourse); //open accordion
      } else {
        //last lesson of last course
      }
    }

    //5) update course as completed if all progress lesson status is completed.
    handleComplete(
      data[activeCourse].lessons,
      data[activeCourse].lessons_status
    );
  };

  const handleComplete = async (lessons, lessons_status) => {
    //check if all lessons are complete
    const found = lessons.some(
      (lesson) =>
        lesson.status === "in progress" ||
        lesson.status === "not started" ||
        lesson.status === undefined
    );

    if (!found && lessons_status !== "completed") {
      // all course is completed
      setIsLoadingCompletion(true);
      const res = await update(
        auth.token,
        `/progress/courses/${data[activeCourse]._id}`,
        {}
      );
      if (res.data.status === "success") {
        showToast(
          "success",
          "Congratulations! Course completed, please leave a review"
        );
        data[activeCourse].lessons_status = "completed";
      }
      if (res.data.code === 101) {
        showToast("info", "Ensure you have completed all your lessons.");
      }
      setIsLoadingCompletion(false);
    }
  };

  const handleSubmitCourseReview = async () => {
    // e.preventDefault();
    setIsLoadingReview(true);
    if (star < 1) {
      showToast("error", "You haven't selected a star yet");
    } else if (review === "") {
      setReviewErr("Please say something nice before submitting your review");
    } else {
      const res = await update(
        auth.token,
        `courses/${reviewCourseId.id}/reviews`,
        {
          star: star,
          comments: review,
        }
      );
      if (res.status === 204) {
        showToast("success", "Review submitted successfully");
        // update state
        // data.course_reviewed = true;
        setOpenCourseReview(false);
        data[reviewCourseId.index].course_reviewed = true;
        setStar(0);
        setReview("");
      } else {
        //alert error
        showToast(
          "info",
          "We encountered an error submitting your review, please try again."
        );
      }
    }
    setIsLoadingReview(false);
  };

  useEffect(() => {
    getProgress();
  }, []);

  if (isLoading) {
    return <UserCourseLoader />;
  }

  return (
    <div>
      <Container>
        <Row>
          {/* main player */}
          <div>
            <Breadcrumbs
              label={
                type === "bootcamps"
                  ? data && genData.bootcamp_id.title
                  : genData.career_path_id.title
              }
              preLabel={type}
              preLabelUrl={`/profile/courses?type=${type}`}
            />

            <div
              style={{
                borderRadius: "20px",
                overflow: "hidden",
                height: "auto",
                background: "#000",
                marginBottom: "20px",
              }}
            >
              <ReactPlayer
                ref={playerRef}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                width="100%"
                height="auto"
                playing={true}
                url={data[activeCourse].lessons[activeLesson].url}
                controls
                onReady={() =>
                  handleReady(data[activeCourse].lessons[activeLesson].progress)
                }
                // onProgress={(played) => onPlaying(played)}
                // progressInterval={60000}
                progressInterval={5000}
                onEnded={handleEnded}
              />
            </div>

            <div style={{ padding: "20px 0px" }}>
              Quality:{" "}
              <select onChange={onChangeBitrate}>
                <option value={null}>auto</option>
                {playerRef.current
                  ?.getInternalPlayer("hls")
                  ?.levels.map((level, id) => {
                    return (
                      <option key={id} value={id}>
                        {level.height}
                      </option>
                    );
                  })}
              </select>
            </div>

            <Badge style={{ background: Extra.yellow, fontWeight: "bold" }}>
              {type}
            </Badge>

            <Typography
              variant="h3"
              weight="bold"
              style={{ textTransform: "capitalize", marginTop: 12 }}
            >
              {data[activeCourse].lessons[activeLesson].title}
            </Typography>

            <Typography variant="p2">
              {data[activeCourse].lessons[activeLesson].description}
            </Typography>

            <CourseIncludes>
              <Typography variant="h5" weight="bold">
                This course includes
              </Typography>

              <div className="wrapper">
                <div>
                  <FontAwesomeIcon icon={faTv} />
                  <Typography variant="p2" weight="semi-bold">
                    {getCourseDuration(data[activeCourse].lessons)} on-demand
                    video
                  </Typography>
                </div>

                <div>
                  <FontAwesomeIcon icon={faInfinity} />
                  <Typography variant="p2" weight="semi-bold">
                    Full lifetime access
                  </Typography>
                </div>

                <div>
                  <FontAwesomeIcon icon={faAward} />
                  <Typography variant="p2" weight="semi-bold">
                    Certificate of completion
                  </Typography>
                </div>
              </div>
            </CourseIncludes>

            <Schedule mode={mode}>
              <InstructorProfile
                bg={data[activeCourse].instructorID.avatar.url}
              >
                <div className="profile-img"></div>
                <div className="details">
                  <Typography
                    variant="h6"
                    weight="bold"
                    style={{ textTransform: "capitalize" }}
                  >
                    {data[activeCourse].instructorID.name}
                  </Typography>
                  <Typography variant="p2">Instructor</Typography>
                </div>
              </InstructorProfile>
              <Typography
                variant="h3"
                weight="bold"
                style={{ textTransform: "capitalize" }}
              >
                Schedule an appointment
              </Typography>

              <Typography variant="p2">
                Anytime you feel confused or have questions, feel free to
                schedule a 1:1 online session with your instructor or a mentor.
              </Typography>
              <a href="https://calendly.com/nerdyeye/30min" target="_blank">
                <Button label="Schedule Now" variant="outline" />
              </a>
            </Schedule>
          </div>
          {/* sidebar */}
          <div>
            <BootcampCertAndReview
              data={data}
              genData={genData}
              type={type}
              bootcamp={type === "bootcamps" ? genData.bootcamp_id : ""}
            />
            <a href={groupLink} target="_blank">
              <Button
                label="Join Support Group"
                size="md"
                block
                style={{ background: Extra.purple, marginTop: 20 }}
              />
            </a>
            {/* {type === "bootcamps" ? (
              <>
                <BootcampCertAndReview
                  data={data}
                  genData={genData}
                  bootcamp={genData.bootcamp_id}
                />
              </>
            ) : (
              ""
            )} */}
            <Typography
              variant="h4"
              weight="bold"
              style={{ marginTop: "20px" }}
            >
              {type === "bootcamps"
                ? "Bootcamp Lessons"
                : "Career Path Lessons"}
            </Typography>
            <Typography variant="p2" weight="bold">
              Your Progress - {`${progressPercentPath}%`}
            </Typography>
            <ProgressBar progress={`${progressPercentPath}%`} />
            <AccordionWrapper mode={mode}>
              {data.map((course, key) => (
                <Accordion
                  disableGutters
                  key={key}
                  expanded={expanded === key}
                  onChange={handleChange(key)}
                  sx={{
                    borderBottom: `1px solid ${
                      mode === "light" ? Grey[50] : Grey[600]
                    }`,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === key ? (
                        <FeatherIcon icon="minus" size="16" />
                      ) : (
                        <FeatherIcon icon="plus" size="16" />
                      )
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography
                      variant="p2"
                      className="title"
                      weight="bold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {course.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      color: mode === "light" ? Grey[500] : Dark.text,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <span>{course.lessons.length} Lessons</span>
                      <Badge>
                        {course.percent ? course.percent : 0}% completed
                      </Badge>
                    </div>

                    <LessonsWrapper style={{ marginTop: "0px" }}>
                      {course.lessons.map((lesson, i) => (
                        <LessonList
                          mode={mode}
                          key={i}
                          className={
                            key === activeCourse &&
                            i === activeLesson &&
                            "active"
                          }
                          onClick={() => {
                            setIsReady(false);
                            setActiveCourse(key);
                            setActiveLesson(i);
                          }}
                        >
                          <div className="details">
                            <FontAwesomeIcon icon={faPlayCircle} />
                            <div className="title">
                              <Typography variant="p1">
                                {lesson.title}
                              </Typography>
                              <Typography variant="small1" weight="semi-bold">
                                {`${lesson.duration}`.replace(".", ":")} mins
                              </Typography>
                            </div>
                          </div>
                          {lesson.status === "in progress" && (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="progress pending"
                            />
                          )}

                          {lesson.status === "completed" && (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="progress success"
                            />
                          )}
                        </LessonList>
                      ))}
                      {course.course_reviewed ? (
                        ""
                      ) : (
                        <Button
                          label="review this course"
                          size="md"
                          block={true}
                          onClick={() => {
                            setReviewCourseId({ id: course._id, index: key });
                            setOpenCourseReview(true);
                          }}
                        />
                      )}
                    </LessonsWrapper>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionWrapper>

            {/* batch details for bootcamps */}
            {type === "bootcamps" && (
              <div
                style={{
                  border: `2px solid ${Extra.yellow}`,
                  padding: "0px 20px",
                  borderRadius: 20,
                  margin: "32px 0px",
                }}
              >
                <Typography
                  variant="h4"
                  weight="bold"
                  style={{ marginTop: "20px" }}
                >
                  Batch Details
                </Typography>
                <div>
                  <Typography variant="p2">
                    {regBatch.details.description}
                  </Typography>
                  <Typography variant="p2">
                    <strong>Batch: </strong> {regBatch.name}
                  </Typography>
                  <Typography variant="p2">
                    <strong>Start Date: </strong>{" "}
                    {moment(regBatch.start_date).format("MMM Do, YYYY")}
                  </Typography>
                  <Typography variant="p2">
                    <strong>End Date : </strong>
                    {moment(regBatch.end_date).format("MMM Do, YYYY")}
                  </Typography>
                  <Typography variant="p2">
                    <strong>Estimated Duration: </strong>{" "}
                    {regBatch.details.timeframe}
                  </Typography>
                  <Typography variant="p2">
                    <strong>Support Group: </strong>
                    <a href={regBatch.details.community_link} target="_blank">
                      {regBatch.details.community_link}
                    </a>
                  </Typography>

                  <Typography variant="p2">
                    <strong>Meeting Intervals: </strong>{" "}
                    {regBatch.details.intervals}
                  </Typography>

                  <Typography variant="p2">
                    <strong>Time: </strong> {regBatch.details.time}
                  </Typography>
                  <Typography variant="p2">
                    <strong>Location: </strong> {regBatch.details.location}
                  </Typography>

                  <Typography variant="p2">
                    <strong>Live Class Meeting Link: </strong>
                    <a href={regBatch.details.meeting_link} target="_blank">
                      {regBatch.details.meeting_link}
                    </a>
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </Row>
      </Container>

      {/* modal for path couse review */}
      <Modal
        open={openCourseReview}
        content={
          <>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                float: "right",
              }}
              onClick={() => setOpenCourseReview(false)}
            />
            <div>
              <center>
                <Icons.Avatar style={{ width: "300px" }} />
              </center>
              <Typography variant="h6" weight="bold" align="center">
                Review Course
              </Typography>
              <Typography variant="p2" align="center">
                How would you rate your overall learning experience in this
                course?
              </Typography>
              <ReviewStars
                star={star}
                changeOne={() => setStar(1)}
                changeTwo={() => setStar(2)}
                changeThree={() => setStar(3)}
                changeFour={() => setStar(4)}
                changeFive={() => setStar(5)}
              />
              <TextArea
                placeholder="Say something nice here..."
                state={reviewErr ? "error" : ""}
                desc={reviewErr}
                onChange={(e) => {
                  setReview(e.target.value);
                  setReviewErr("");
                }}
              >
                {review}
              </TextArea>
              <Button
                label="Submit Review"
                isLoading={isLoadingReview}
                onClick={handleSubmitCourseReview}
                block
              />
            </div>
          </>
        }
        onClose={() => setOpenCourseReview(false)}
      />
    </div>
  );
};

export default MainLearningArea;
