import Typography from "../../../atoms/Typography/Typography";
import { Extra, Success } from "../../../atoms/Colors";
// import { Slide } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  SectionWrapper,
  Row,
} from "../../../../helpers/GlobalStyle";
import { ImgHolder } from "./InstructorsStyle";
import img from "./../../../../assets/images/creator.jpg";
import Button from "../../../atoms/Button/Button";
import { Slide } from "react-awesome-reveal";

const list = [
  "Sell courses or cohort based programs",
  "Receive payment globally",
  "Dedicated support group for each course",
  "Attract prospective students with free learning community ",
  "Protect course videos from downloads",
  "Beautiful course sales page",
];

const Creators = () => {
  return (
    <SectionWrapper style={{ paddingTop: 0 }}>
      <Container>
        <Row style={{ alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            <ImgHolder bg={img} />
          </div>
          <Slide direction="right">
            <div>
              <Typography variant="p2" style={{ color: Extra.purple }}>
                For Creators
              </Typography>
              <Typography variant="h3" style={{ textTransform: "capitalize" }}>
                best place to host & sell online courses & bootcamps for{" "}
                <span style={{ color: Extra.yellow }}>creators</span>
              </Typography>
              <Typography variant="p2">
                Start a Learning Trybe, create courses with community support &
                get paid.
              </Typography>
              {list.map((val, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color={Success[500]} />
                  <Typography variant="p2" style={{ margin: 10 }}>
                    {val}
                  </Typography>
                </div>
              ))}

              <a href="https://forms.gle/1t3SNagtDwXprxrT6" target="_blank">
                <Button
                  label="Get Started For Free"
                  style={{ marginTop: 20 }}
                />
              </a>
            </div>
          </Slide>
        </Row>
      </Container>
    </SectionWrapper>
  );
};

export default Creators;
