import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useSelector } from "react-redux";
import { Dark, Grey } from "../../atoms/Colors";

export default function PageModal({
  isOpen,
  content,
  boxstyle,
  maxWidth = "md",
  ...props
}) {
  const mode = useSelector((state) => state.mode);
  return (
    <div>
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth={maxWidth}
        fullWidth={true}
        {...props}
      >
        <DialogContent
          dividers="body"
          sx={{
            padding: 4,
            background: mode === "light" ? "#fff" : Dark.bgCard,
            color: mode === "light" ? Grey[500] : Dark.text,
          }}
          style={boxstyle}
        >
          {content}
        </DialogContent>
      </Dialog>
    </div>
  );
}
