import styled from "styled-components";
import { Grey, Primary } from "../../atoms/Colors";

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  border-left: 5px solid ${Primary[500]};
  padding-left: 10px;
  color: ${Grey[600]};
  margin-top: 10px;
  margin-bottom: 30px;
  text-transform: capitalize;
  a {
    color: ${Grey[300]};
  }
`;
