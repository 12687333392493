import { Wrapper } from "./BadgeStyle";

const Badge = ({ variant, children, ...props }) => {
  return (
    <Wrapper variant={variant} {...props}>
      {children}
    </Wrapper>
  );
};

export default Badge;
