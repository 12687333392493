const toastReducer = (state = false, action) => {
  switch (action.type) {
    case "displayToast":
      return action.payload;
    default:
      return {
        show: false,
        type: "",
        msg: "",
      };
  }
};

export default toastReducer;
