import styled from "styled-components";
import { Dark, Grey, Primary, Success } from "../../../../atoms/Colors";
import bg from "./../../../../../assets/images/cert_bg.jpg";

export const CertWrapper = styled.div`
  padding: 30px;
  width: 842px;
  height: 530px;
  box-sizing: border-box;
  color: #000;

  .cert {
    height: 86%;
    width: 90%;
    border: 2px solid ${Primary[500]};
    padding: 30px;
    border-radius: 5px;
    background-image: url(${bg});
    /* background: #f5f5f5; */
    background-size: cover;
  }
  .logo {
    margin-bottom: 40px;
  }
  .name {
    font-family: "Tangerine", cursive;
    font-size: 52px;
    text-transform: capitalize;
  }
  .desc {
    max-width: 480px;
  }
  .course {
    text-transform: capitalize;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    text-transform: capitalize;
    font-size: 12px;
  }
  .instructor {
    text-align: right;
  }
  .sub-footer {
    font-size: 6px;
    text-align: center;
    color: #575757;
    margin-top: 20px;
  }
`;

export const CertPrev = styled.div`
  height: 10px;
  overflow: hidden;
`;

export const Congratualtion = styled.div`
  padding: 32px 0px;
  box-sizing: border-box;
  background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bgCard)};
  margin-bottom: 32px;

  h4 {
    color: ${Success[500]};
  }
`;
