import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const Sidebar = styled.div`
  background: #f5f5f5;
  padding: 56px;
  height: 100%;
  position: fixed;
  width: 30%;
  left: 0px;
  box-sizing: border-box;
  background-image: ${(props) => `url(${props.bg})`};
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MainContent = styled.div`
  padding: 56px;
  position: absolute;
  width: 70%;
  right: 0px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const Back = styled.div`
  position: absolute;
  top: 56px;
  left: 56px;
  cursor: pointer;
  @media (max-width: 480px) {
    top: 20px;
    left: 20px;
  }
`;

export const Skip = styled.div`
  position: absolute;
  top: 56px;
  right: 56px;
  cursor: pointer;
  @media (max-width: 480px) {
    top: 20px;
    left: 20px;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  margin: auto;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 70px;
  }
`;
