import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { Helmet } from "react-helmet";
import LearningTab from "./LearningTabs";

const UserCourses = () => {
  return (
    <>
      <Helmet>
        <title> My Courses - Nerdy Eye</title>
      </Helmet>
      <SectionWrapper>
        <Container>
          <Typography variant="h2" weight="bold">
            What you're learning ✨
          </Typography>
          <Typography variant="p1">
            Access your Courses, Career Path & Bootcamps here. Don't forget, we
            are rooting for you 😁
          </Typography>

          <LearningTab />
        </Container>
      </SectionWrapper>
    </>
  );
};

export default UserCourses;
