import Typography from "./../../../atoms/Typography/Typography";
import {
  Browse,
  Container,
  GridColumn,
  TextRow,
} from "../../../../helpers/GlobalStyle";
import CourseCard from "../../../molecules/cards/CourseCard";
import { CourseGridWrapper, CourseGridholder } from "./CourseGridStyle";
import { Link } from "react-router-dom";

import { Fade, Slide } from "react-awesome-reveal";

import useFetch from "../../../../api/useFetch";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";

const CourseGrid = ({ url, title, desc }) => {
  const { data, loading, error } = useFetch(url);
  if (error) {
    return <div>something went wrong</div>;
  }

  return (
    <CourseGridWrapper>
      <Container>
        <TextRow style={{ marginBottom: 28 }}>
          <Slide direction="up">
            <GridColumn>
              <Typography variant="h3" weight="bold">
                {title}
              </Typography>
              <Typography variant="p2" weight="semiBold">
                {desc}
              </Typography>
            </GridColumn>
          </Slide>

          <Link to="search?q=&type=courses">Browse all</Link>
          {/* <Browse>
              <li>Browse all</li>
            </Browse> */}
        </TextRow>

        <Fade>
          {loading ? (
            <CourseGridholder>
              <CardSkeleton mode="light" height="380px" />
              <CardSkeleton mode="light" height="380px" />
              <CardSkeleton mode="light" height="380px" />
              <CardSkeleton mode="light" height="380px" />
            </CourseGridholder>
          ) : (
            <CourseGridholder>
              {data.data.map((course, i) => (
                <div key={i}>
                  <Link to={`/courses/${course.slug}`}>
                    <CourseCard
                      bgImg={course.avatar && course.avatar.url}
                      title={course.title}
                      // intructor={course.intructor}
                      desc={course.description}
                    />
                  </Link>
                </div>
              ))}
            </CourseGridholder>
          )}
          {/* {
            loading ? <CourseGridholder >
            <CardSkeleton mode="light" />
            <CardSkeleton mode="light" />
            <CardSkeleton mode="light" />
            <CardSkeleton mode="light" />
            <CourseGridholder />:<CourseGridholder>sxss</CourseGridholder>
          } */}
        </Fade>
      </Container>
    </CourseGridWrapper>
  );
};

export default CourseGrid;
