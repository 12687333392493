import React from "react";
import { useSelector } from "react-redux";
import { Container } from "../../../../../helpers/GlobalStyle";
import { Grey, Dark } from "../../../../atoms/Colors";
import Typography from "../../../../atoms/Typography/Typography";
import { GridWrapper } from "./WhyLearnWithUsStyle";
import { WTCard, WTText } from "../../../workforce/steps/WorkforceStepsStyle";
import Icons from "../../../../../assets/images/icons";

const WhyLearnWithUs = () => {
  const mode = useSelector((state) => state.mode);

  return (
    <div
      div
      style={{
        background: mode === "light" ? Grey[50] : Dark.bgCard,
        marginTop: "100px",
        padding: "112px 0px",
      }}
    >
      <Container>
        <Typography variant="h3" weight="bold">
          Why learn with us ✨
        </Typography>
        <Typography variant="p2">
          Our programs are structured to help you learn, get mentored, build
          portfolio and be on a fast track to a high paying tech job
        </Typography>

        <GridWrapper>
          <WTCard mode={mode}>
            <Icons.Support width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Learning Support
              </Typography>
              <Typography variant="p2">
                Unlike most platforms where you can't ask question while
                learning online, with us you can ask question immeduately via
                live chat or 30 minutes clarity call you can schedule.
              </Typography>
            </WTText>
          </WTCard>
          <WTCard mode={mode}>
            <Icons.Jobs width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Portfolio & Job Support
              </Typography>
              <Typography variant="p2">
                You will be supported and guided to build awesome portfolio to
                showcase your abilities and you will also be assisted while job
                hunting.
              </Typography>
            </WTText>
          </WTCard>
          <WTCard mode={mode}>
            <Icons.Mentorship width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Mentorship
              </Typography>
              <Typography variant="p2">
                With our Live Bootcamps, you will be assigned a mentor you can
                schedule a 30 minutes call with or reach out to via live chat.
              </Typography>
            </WTText>
          </WTCard>
          <WTCard mode={mode}>
            <Icons.Group width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Support Group
              </Typography>
              <Typography variant="p2">
                Once you enroll for our Live Bootcamp, you will be able join a
                private support group to get instant support, connect and
                network with other students.
              </Typography>
            </WTText>
          </WTCard>
          <WTCard mode={mode}>
            <Icons.Partner width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Accountability Partner
              </Typography>
              <Typography variant="p2">
                With our Live Bootcamps, you will be merge with another student
                that you will be each other's Accountability Partners to learn &
                grow together.
              </Typography>
            </WTText>
          </WTCard>
          <WTCard mode={mode}>
            <Icons.Resume width="74" height="74" />
            <WTText>
              <Typography variant="h6" weight="bold">
                Resume Drafting
              </Typography>
              <Typography variant="p2">
                You will receive sample resume according to the course or
                bootcamp you enrolled for so you can draft profesional resume
                that will get you job interviews.
              </Typography>
            </WTText>
          </WTCard>
        </GridWrapper>
      </Container>
    </div>
  );
};

export default WhyLearnWithUs;
