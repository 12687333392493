import { Container } from "../../helpers/GlobalStyle";

const ErrorApiPage = ({ error }) => {
  return (
    <div style={{ padding: "20px 0px" }}>
      <Container>Oops! seems like there is an error</Container>
    </div>
  );
};

export default ErrorApiPage;
