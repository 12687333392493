import { useState, useEffect, useRef } from "react";
import { Container } from "../../../../helpers/GlobalStyle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "../../../atoms/Typography/Typography";
import {
  ProfileHeader,
  Wrapper,
  ProfileImg,
  AccordionWrapper,
  PortWrapper,
  PortIcon,
  LinksWrapper,
  ItemWrapper,
  EditIcon,
} from "./ProfileStyle";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import BasicForm from "../../../organisms/forms/profile/BasicForm";
import InterestEdit from "../../../organisms/forms/profile/InterestEdit";
import WorkForm from "../../../organisms/forms/profile/WorkForm";
import PwdForm from "../../../organisms/forms/profile/PwdForm";
import { Grey } from "../../../atoms/Colors";
import axios from "./../../../../axios/axios";
import dp from "./../../../../assets/images/default-avatar-cta.png";
import useToast from "../../../../customhooks/useToast";
import Roadmap from "./roadmap/Roadmap";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";

const ProfileAccordions = ({ user }) => {
  const mode = useSelector((state) => state.mode);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <AccordionWrapper mode={mode}>
        <Accordion
          disableGutters
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            borderBottom: `1px solid ${
              mode === "light" ? Grey[50] : Grey[600]
            }`,
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel1" ? (
                <FeatherIcon icon="minus" size="16" />
              ) : (
                <FeatherIcon icon="plus" size="16" />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="p2" className="title" weight="bold">
              Basic Info
            </Typography>
          </AccordionSummary>
          <AccordionDetails
          // sx={{ borderBottom: "1px solid #dddddd", borderRadius: "4px" }}
          >
            <BasicForm user={user} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            borderBottom: `1px solid ${
              mode === "light" ? Grey[50] : Grey[600]
            }`,
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel2" ? (
                <FeatherIcon icon="minus" size="16" />
              ) : (
                <FeatherIcon icon="plus" size="16" />
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography variant="p2" className="title" weight="bold">
              Goal & Interest
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InterestEdit user={user} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            borderBottom: `1px solid ${
              mode === "light" ? Grey[50] : Grey[600]
            }`,
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel3" ? (
                <FeatherIcon icon="minus" size="16" />
              ) : (
                <FeatherIcon icon="plus" size="16" />
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography variant="p2" className="title" weight="bold">
              Work Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WorkForm user={user} />
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={
              expanded === "panel4" ? (
                <FeatherIcon icon="minus" size="16" />
              ) : (
                <FeatherIcon icon="plus" size="16" />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="p2" className="title" weight="bold">
              Change Password
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PwdForm user={user} />
          </AccordionDetails>
        </Accordion>
      </AccordionWrapper>
    </>
  );
};

const Profile = () => {
  const [user, setUser] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState("roadmap");
  const { showToast } = useToast();

  const mode = useSelector((state) => state.mode);

  const [isUploading, setIsUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState("");

  const filePicekerRef = useRef(null);

  const handleProfileUpload = async (img) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("avatar", img);
      await axios.patch("/users/upload-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      showToast("success", "Image uploaded");
    } catch (err) {
      console.log(err.response);
      showToast(
        "error",
        "Oops! encountered error while uploading, please try again"
      );
    }
    setIsUploading(false);
  };

  function previewFile(e) {
    // Reading New File (open file Picker Box)
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      setImagePreview(readerEvent.target.result);
    };
    // setimg(e.target.files[0]);
    handleProfileUpload(e.target.files[0]);
  }

  const getUser = async () => {
    setIsLoading(true);
    const res = await axios.get(`/users/${auth.user._id}`);
    setUser(res.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading) {
    return (
      <Container>
        <center>
          <CardSkeleton
            width="150px"
            height="150px"
            style={{ borderRadius: "50%" }}
          />
          <CardSkeleton
            width="300px"
            height="28px"
            style={{ marginTop: "20px" }}
          />
          <CardSkeleton
            width="100px"
            height="10px"
            style={{ marginTop: "20px", marginBottom: "40px" }}
          />
        </center>
      </Container>
    );
  }

  return (
    <>
      <ProfileHeader mode={mode}>
        <Wrapper>
          <input
            ref={filePicekerRef}
            accept="image/*, video/*"
            onChange={previewFile}
            type="file"
            hidden
          />
          <ProfileImg
            mode={mode}
            onClick={() => filePicekerRef.current.click()}
            style={{
              backgroundImage: imagePreview
                ? `url(${imagePreview})`
                : user !== null && user.avatar !== undefined
                ? `url(${user.avatar.url})`
                : `url(${dp})`,
            }}
          >
            <EditIcon mode={mode}>
              <FeatherIcon icon="edit" size={16} />
            </EditIcon>
          </ProfileImg>

          <Typography variant="small1" align="center">
            {isUploading && "Uploading image..."}
          </Typography>

          <Typography
            variant="h3"
            weight="bold"
            align="center"
            style={{ textTransform: "capitalize" }}
          >
            {user.firstName} {user.otherNames} {user.lastName}
          </Typography>
          <Typography variant="p2" weight="semi-bold" align="center">
            @{user.username}
          </Typography>

          {/* <PortWrapper>
            <PortIcon mode={mode}>
              <FeatherIcon icon="briefcase" size="18" />
            </PortIcon>
            <Typography variant="p2" weight="semi-bold">
              {user.profession}
            </Typography>
          </PortWrapper> */}

          <LinksWrapper>
            <ItemWrapper
              mode={mode}
              onClick={() => setPage("roadmap")}
              className={page === "roadmap" && "active"}
            >
              <span>Career Roadmap</span>
            </ItemWrapper>

            <ItemWrapper
              mode={mode}
              onClick={() => setPage("profile")}
              className={page === "profile" && "active"}
            >
              <span>Edit Profile</span>
            </ItemWrapper>
          </LinksWrapper>
        </Wrapper>
      </ProfileHeader>
      <Container>
        <Wrapper style={{ marginBottom: 56 }}>
          {page === "roadmap" && <Roadmap />}
          {page === "profile" && <ProfileAccordions user={user} />}
        </Wrapper>
      </Container>
    </>
  );
};

export default Profile;
