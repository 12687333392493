import { useState } from "react";
import {
  FormWrapper,
  BtnWrapper,
  FormGroup,
  PrevResume,
} from "./ProfileFormStyle";
import { Input, CustomSelect } from "./../../../atoms/inputs/Input";
import { Label } from "../../../atoms/inputs/InputStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../atoms/Button/Button";
import FileDropZone from "../../../molecules/filedropzone/FileDropZone";
import { useDropzone } from "react-dropzone";
import { TagsInput } from "react-tag-input-component";
import { useSelector } from "react-redux";
import Typography from "../../../atoms/Typography/Typography";
import Toast from "../../../molecules/toast/Toast";
import axios from "./../../../../axios/axios";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const WorkForm = ({ user }) => {
  const [apiErr, setApiErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResumeUploading, setIsResumeUploading] = useState(false);
  const [resumeUrl, setResumeUrl] = useState(
    user.resume === undefined ? null : user.resume.url
  );
  const [tags, setTags] = useState(user.skills);
  const [file, setFile] = useState([]);
  const [filename, setFileName] = useState("");
  const mode = useSelector((state) => state.mode);
  const auth = useSelector((state) => state.auth);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      application: [".pdf"],
    },
    onDropAccepted: async (files) => {
      setFile(files[0]);
      //upload resume
      setIsResumeUploading(true);
      if (files[0].size > 2000000) {
        handleNotify("Please select file that is not more than 2MB", "error");
      } else {
        try {
          const formData = new FormData();
          formData.append("resume", files[0]);
          const res = await axios.patch("/users/upload-resume", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.token}`,
            },
          });
          setResumeUrl(res.data.data);
          handleNotify("Resume uploaded successfully", "success");
        } catch (err) {
          handleNotify(
            "Encountered an error, please try again or change file",
            "error"
          );
        }
      }
      setIsResumeUploading(false);
      setFileName(files[0].name);
    },
  });

  const formik = useFormik({
    initialValues: {
      profession: user.profession,
      experience: user.experience,
      portfolio: user.portfolio,
    },
    validationSchema: Yup.object({
      profession: Yup.string().required("Required"),
      experience: Yup.string(),
      portfolio: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    }),
    onSubmit: async (values) => {
      //form submittion
      // console.log(file);
      setIsLoading(true);
      try {
        const body = { ...values, skills: tags };
        const res = await axios.patch("/users", body, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        handleNotify("changes saved successfully", "success");
      } catch (error) {
        handleNotify(error.response.data.message, "error");
      }
      setIsLoading(false);
    },
  });

  return (
    <>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <div>
            <Input
              label="Profession"
              type="text"
              name="profession"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.profession}
              state={
                formik.touched.profession && formik.errors.profession && "error"
              }
              desc={
                formik.touched.profession &&
                formik.errors.profession &&
                formik.errors.profession
              }
            />
          </div>

          <div>
            <CustomSelect
              label="Experience Level"
              list={["junior", "mid", "senior"]}
              value={formik.values.experience}
              onChange={formik.handleChange}
              name="experience"
              onBlur={formik.handleBlur}
              state={
                formik.touched.experience && formik.errors.experience && "error"
              }
              desc={
                formik.touched.experience &&
                formik.errors.experience &&
                formik.errors.experience
              }
            />
          </div>
        </FormWrapper>

        <FormGroup mode={mode}>
          <Label mode={mode}>Skills</Label>
          <TagsInput
            value={tags}
            onChange={setTags}
            name="fruits"
            placeHolder="add skills"
            separators={["Enter", ","]}
            style={{ marginTop: 20, background: "red" }}
            classNames="skill-tags"
          />

          <Typography variant="p3">
            Press enter or comma to add skills
          </Typography>
        </FormGroup>

        <FormGroup>
          <Input
            label="Portfolio Link"
            type="text"
            name="portfolio"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.portfolio}
            state={
              formik.touched.portfolio && formik.errors.portfolio && "error"
            }
            desc={
              formik.touched.portfolio &&
              formik.errors.portfolio &&
              formik.errors.portfolio
            }
          />
        </FormGroup>

        <FormGroup>
          <Label mode={mode}>Attach Documents (Resume/CV)</Label>
          {resumeUrl !== null && (
            <PrevResume mode={mode}>
              <FeatherIcon icon="file" size={16} />
              <a href={resumeUrl} target="_blank">
                <strong>View your resume</strong>.
              </a>
              Update by uploading a new one below 👇
            </PrevResume>
          )}
          {isResumeUploading && (
            <Typography variant="p3">Uploadiing your resume...</Typography>
          )}
          <FileDropZone
            // label="Attach Documents (Resume/CV)"
            desc="Accepted file types: pdf only"
            filename={filename}
            state=""
            {...getRootProps({ className: "create-post-img-upload" })}
          >
            <input {...getInputProps()} />
          </FileDropZone>
        </FormGroup>

        <BtnWrapper style={{ paddingRight: "16px" }}>
          <Button
            label="Save Changes"
            type="submit"
            isLoading={isLoading}
            style={{ margin: "20px 0px" }}
          />
        </BtnWrapper>
      </form>
    </>
  );
};

export default WorkForm;
