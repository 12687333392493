import ComingSoon from "../coming-soon/ComingSoon";
import { Helmet } from "react-helmet";

const Communities = () => {
  <Helmet>
    <title> Tech Comminities - Nerdy Eye</title>
    <meta
      name="description"
      content="Find and Join Tech Communities in minutes. Create, manage and grow your communities"
    />
  </Helmet>;
  return <ComingSoon />;
};

export default Communities;
