import CourseHero from "../course/hero/CourseHero";
import Overview from "../course/details/Overview";
import Gain from "../course/gain/Gain";
import PathInstructors from "../instructor/PathInstructors";
import { Grey, Dark } from "../../../atoms/Colors";
import Testimonials from "../../../organisms/testimonials/Testimonials";
import Faq from "../course/faq/Faq";
import Career from "../career/Career";
import { useSelector } from "react-redux";
import useFetch from "../../../../api/useFetch";
import { useParams } from "react-router-dom";
import CardSkeleton from "../../../organisms/skeleton/CardSkeleton";
import { Container } from "../../../../helpers/GlobalStyle";
import ErrorApiPage from "../../../pages/ErrorApiPage";
import { getCareerPathDuration } from "../../../../helpers/getDuration";
import { useState } from "react";
import { Helmet } from "react-helmet";
import TestimonyPopup from "../../../organisms/testimonials/TestimonyPopup";

const CareerPaths = () => {
  const mode = useSelector((state) => state.mode);
  let { url } = useParams();
  const { data, loading, error } = useFetch(`career-paths/${url}`);
  const [isInstructorReady, setIsInstructorReady] = useState(false);
  const [instructorData, setInstructorData] = useState(null);

  if (loading) {
    return (
      <div>
        <CardSkeleton
          style={{ width: "100%", borderRadius: "0px", height: "500px" }}
          mode={mode}
        />
        <Container>
          <CardSkeleton
            variant="text"
            style={{
              width: "200px",
              borderRadius: "10px",
              marginTop: "56px",
              height: "50px",
            }}
            mode={mode}
          />

          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
            }}
            mode={mode}
          />
          <CardSkeleton
            style={{
              borderRadius: "10px",
              height: "14px",
              marginTop: "8px",
              marginBottom: "56px",
            }}
            mode={mode}
          />
        </Container>
      </div>
    );
  }

  if (error) {
    return <ErrorApiPage error={error} />;
  }

  if (data && !isInstructorReady) {
    let prepData = [];
    data.data.course_Ids.map((course) =>
      prepData.push({
        name: course.instructorID.name,
        profession: course.instructorID.profession,
        avatar: course.instructorID.avatar.url,
        bio: course.instructorID.bio,
      })
    );
    setInstructorData(prepData);
    setIsInstructorReady(true);
  }

  return (
    <div>
      <TestimonyPopup />
      <Helmet>
        <title> {data.data.title} Career Paths - Nerdy Eye</title>
        <meta
          name="description"
          content={`Transition into tech by taking series of courses under ${data.data.title} career path. Get access to project based courses and learn by doing`}
        />
        <meta name="image" content={data.data.avatar && data.data.avatar.url} />
      </Helmet>
      <CourseHero
        title={data.data.title}
        content={`${data.data.course_Ids.length} Courses`}
        badge="yellow"
        badgeText="Career Path"
        time={getCareerPathDuration(data.data.course_Ids)}
        certificate={true}
        bg={data.data.avatar && data.data.avatar.url}
        trailer={data.data.trailer_url}
        id={data.data._id}
        type="career-paths"
        slug={data.data.slug}
      />
      <Overview
        title="career path"
        desc={data.data.description}
        content={`${data.data.course_Ids.length} Courses`}
        type="career"
        lessons={data.data.course_Ids ? data.data.course_Ids : []}
      />

      <Gain desc={data.data.why_desc} list={data.data.why_list} />

      <div
        style={{
          background: mode === "light" ? Grey[50] : Dark.bgCard,
          marginTop: "100px",
        }}
      >
        <PathInstructors
          subHeading="INSTRUCTORS"
          title="Learn with the best"
          align="center"
          data={instructorData}
        />
      </div>

      <Testimonials
        title="Reviews from learners"
        desc="What other students are saying about this course"
        data={data.data.reviews ? data.data.reviews : []}
      />

      <Faq mode={mode} />
      <Career
        title="Related Career"
        desc="Learn from industry experts with years of experience and track record of success"
        url={`career-paths/related-career-paths/${data.data._id}?limit=4`}
      />
    </div>
  );
};

export default CareerPaths;
