import { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Wrapper } from "./NotificationStyle";
import AlertTitle from "@mui/material/AlertTitle";

const Notification = ({ title, desc, showClose, ...props }) => {
  const [open, setOpen] = useState(true);

  return (
    <Wrapper>
      <Collapse in={open}>
        <Alert
          action={
            showClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          {...props}
        >
          <AlertTitle>{title}</AlertTitle>
          {desc}
        </Alert>
      </Collapse>
    </Wrapper>
  );
};

export default Notification;
