//course here duration calculation
export const getCourseDuration = (lessons) => {
  let sum = 0;
  lessons.map((item) => {
    sum += item.duration;
  });
  let hr = sum / 60;
  if (hr < 1) {
    //ensure minutes rounds up by 60 seconds
    let seconds = sum - Math.floor(sum);
    let min = Math.floor(seconds / 0.6);
    let total = sum + min - min * 0.6;
    return `${total.toFixed(2).replace(".", ":")} minutes`;
  } else {
    let min = hr - Math.floor(hr);
    let nhr = Math.floor(min / 0.6);
    let total = hr + nhr - nhr * 0.6;
    return `${total.toFixed(2).replace(".", ":")} hours`;
    // return `${hr.toFixed(2).replace(".", ":")} hours`;
  }
};

//career patg here duration calculation
export const getCareerPathDuration = (courses) => {
  let cSum = 0;
  courses.map((course) => {
    let sum = 0;
    course.lessons.map((item) => {
      sum += item.duration;
    });
    cSum += sum;
  });

  let hr = cSum / 60;

  if (hr < 1) {
    //ensure minutes rounds up by 60 seconds
    let seconds = cSum - Math.floor(cSum);
    let min = Math.floor(seconds / 0.6);
    let total = cSum + min - min * 0.6;
    return `${total.toFixed(2).replace(".", ":")} minutes`;
  } else {
    return `${hr.toFixed(2).replace(".", ":")} hours`;
  }
};

//user course calculation
export const getCalCourseDuration = (lessons) => {
  let sum = 0;
  lessons.map((item) => {
    sum += item.duration;
  });

  return sum;
};

//user course watch progress duration
export const getCalProgressDuration = (lessons) => {
  let sum = 0;
  lessons.map((item) => {
    sum += item.progress;
  });

  return sum;
};
