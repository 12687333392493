import styled from "styled-components";
import { Dark, Grey, Success, Warning } from "../../../../atoms/Colors";

export const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  gap: 32px;
  > div:nth-child(1) {
    flex: 2;
  }
  > div {
    flex: 1;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CourseIncludes = styled.div`
  margin-top: 32px;

  .wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    @media (max-width: 768px) {
      grid-template-columns: auto;
    }
  }
`;

export const LessonsWrapper = styled.div`
  margin-top: 20px;
  height: auto;
  max-height: 650px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LessonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  text-transform: capitalize;
  padding: 20px;
  /* margin: 12px 0px; */
  border-radius: 20px;
  cursor: pointer;
  .details {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .title {
    p {
      margin: 0px;
    }
    span {
      font-weight: 600;
    }
  }

  svg {
    font-size: 20px;
  }

  .progress {
    color: ${Grey[100]};
    font-size: 14px;
  }

  .pending {
    color: ${Warning[300]};
  }

  .success {
    color: ${Success[500]};
  }

  &:hover {
    background: ${(props) => (props.mode === "light" ? Grey[50] : Grey[700])};
  }
  &.active {
    background: ${(props) =>
      props.mode === "light" ? Warning[50] : Grey[700]};
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    p {
      color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    }
  }
`;

export const Schedule = styled.div`
  padding: 32px 0px;
  border-top: 1px solid
    ${(props) => (props.mode === "light" ? Grey[100] : Dark.bgCard)};
  margin-top: 56px;
`;

export const InstructorProfile = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 20px;
  .profile-img {
    width: 60px;
    height: 60px;
    background: #ccc;
    border-radius: 50%;
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: top;
  }
  .details {
    p {
      margin: 0px;
    }
  }
`;

// export const
